import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer";

interface Renderer {
    render: (options: {
        count: number;
        position: google.maps.LatLng;
    }) => google.maps.Marker;
}

const addClusters = (map: google.maps.Map, markers: google.maps.Marker[]) => {
    const svg = window.btoa(`
                    <svg width="70" height="60" viewBox="0 0 70 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="69" height="59" rx="23.5" fill="white"/>
                    <rect x="0.5" y="0.5" width="69" height="59" rx="23.5" stroke="#B2C6CE"/>
                    </svg>`);

    const renderer: Renderer = {
        render: ({ count, position }) =>
            new google.maps.Marker({
                label: {
                    text: String(count),
                    color: "##10213A",
                    fontSize: "14px",
                    fontWeight: "400",
                },
                icon: {
                    url: `data:image/svg+xml;base64,${svg}`,
                },
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            }),
    };

    const algorithm = new GridAlgorithm({
        gridSize: 60,
    });

    const clusters = new MarkerClusterer({
        map,
        markers,
        algorithm,
        renderer,
    });

    return clusters;
};

export { addClusters };
