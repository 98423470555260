import { Calendar } from "fullcalendar";
import { formatFlights } from "./process";
import { getHiddenFieldFrom } from "../Search/getter";
import { Destination, Flight } from "../../utils/types";
import { ui } from "../../utils/ui";

/**
 * Affichage du calendrier des vols
 * ********************************
 */
export const showCalendar = (calendar: Calendar) => {
    const from = getHiddenFieldFrom() ?? new Date();
    ui?.viewMode?.calendar?.click();
    calendar.updateSize();
    calendar.gotoDate(from);
};

/**
 * Réinitialisation du calendrier des vols
 * ********************************
 */
export const resetCalendar = (calendar: Calendar) => {
    const sources = calendar.getEventSources();
    sources.map((source) => source.remove());
};

/**
 * Mise à jour du calendrier des vols
 * ********************************
 */
export const updateCalendar = (calendar: Calendar, flights: Flight[], destinations: Destination[]) => {
    const events = formatFlights(flights, destinations);
    calendar.addEventSource(events);
};

/**
 * Ouverture du panneau des vols
 * ********************************
 */
export const showFlightsPanel = () => ui?.flightsPanel?.element && (ui.flightsPanel.element.hidden = false);

/**
 * Fermeture du panneau des vols
 * ********************************
 */
export const hideFlightsPanel = () => ui?.flightsPanel?.element && (ui.flightsPanel.element.hidden = true);
