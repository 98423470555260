import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { google } from "google-maps";
import { renderListCard } from "../List/render";
import { Airline, Destination, Position } from "../../utils/types";

const addMarkers = (
    google: google,
    map: google.maps.Map,
    infoWindow: google.maps.InfoWindow,
    airlines: Airline[],
    destinations: Destination[]
) => {
    const bounds = new google.maps.LatLngBounds();
    const markers: google.maps.Marker[] = [];

    Object.values(destinations).map((destination: Destination) => {
        if (!destination.name || !destination.airlines || !destination.airports) return;

        const card = renderListCard(destination, airlines);
        const marker = createMarker(google, map, bounds, destination);

        if (!card || !marker) return;

        marker.addListener("click", () => {
            infoWindow.setContent(card);
            infoWindow.setPosition(marker.getPosition() as google.maps.LatLng);
            infoWindow.open(map, marker);
        });

        markers.push(marker);
    });

    return markers;
};

const createMarker = (
    google: google,
    map: google.maps.Map,
    bounds: google.maps.LatLngBounds,
    destination: Destination
): MarkerWithLabel | undefined => {
    const name = destination?.name;
    const lat = destination?.coordinates?.lat;
    const lng = destination?.coordinates?.lng;

    if (!name || !lng || !lat) return;

    const position: Position = { lat: lat, lng: lng };

    bounds.extend(position);

    const marker = new MarkerWithLabel({
        map: map,
        position: position,
        clickable: true,
        title: name,
        icon: " ",
        labelContent: `<div>${name}</div>`,
        labelAnchor: new google.maps.Point(0, 0),
        labelClass: "destination-label",
    });

    return marker;
};

export { addMarkers };
