import inwhishlist from "./inwishlist";

export default function loginPanel() {

    const parentTargetNode = document.documentElement;
    const config = { childList: true, subtree: true };

    function triggerLoginActions() {
        const allLoginTriggers = document.querySelectorAll('.open-login');
        const panelLoginElement = document.querySelector('.login-panel') as HTMLElement;
        const addtofav = document.querySelector('[name="addtofav"]') as HTMLInputElement;
        const addtoalerts = document.querySelector('[name="addtoalerts"]') as HTMLInputElement;
        const followflight = document.querySelector('[name="followflight"]') as HTMLInputElement;
        const buttonCreateAccount = document.querySelector('[data-createpath]') as HTMLLinkElement;


        allLoginTriggers.forEach((elem:Element) => {
            const trigger = elem as HTMLElement;
            trigger.addEventListener('click', ()=> {
                let createAccountUrl = `${buttonCreateAccount.dataset?.createpath}`;

                if (!addtofav && !addtoalerts && !followflight) {
                    return;
                }
                if (trigger.classList.contains('add-to-wishlist')) {
                    // Set this value.
                    if (trigger.dataset.nodeid && addtofav && addtoalerts) {
                        addtofav.value = `${trigger.dataset.nodeid}`;
                        addtoalerts.value = '';
                        followflight.value = '';
                        buttonCreateAccount.href = `${createAccountUrl}?addtofav=${trigger.dataset.nodeid}`;
                    }
                }
                if (trigger.classList.contains('add-to-promo')) {
                    // Set this value.
                    if (trigger.dataset.nodeid && addtofav && addtoalerts) {
                        addtoalerts.value = `${trigger.dataset.nodeid}`;
                        addtofav.value = '';
                        followflight.value = '';
                        buttonCreateAccount.href = `${createAccountUrl}?addtoalerts=${trigger.dataset.nodeid}`;
                    }
                }
                if (trigger.classList.contains('card-flight__action')) {
                    // Set this value.
                    if (trigger.dataset.dayflightId && addtofav && addtoalerts) {
                        addtoalerts.value = '';
                        addtofav.value = '';
                        followflight.value = `${trigger.dataset.dayflightId}`;
                        buttonCreateAccount.href = `${createAccountUrl}?followflight=${trigger.dataset.dayflightId}`;
                    }
                }

                panelLoginElement.classList.add('active');
                const close = panelLoginElement.querySelector('.close-panel') as HTMLElement;
                close.addEventListener('click', () => {
                    panelLoginElement.classList.remove('active');
                });
                const underlay = panelLoginElement.querySelector('.underlay') as HTMLElement;
                underlay.addEventListener('click', () => {
                    panelLoginElement.classList.remove('active');
                });
            });
        })
    }
    triggerLoginActions();


    // Fonction de rappel pour quand les mutations sont observées
    const callback = (mutationsList: MutationRecord[]) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(() => {
                    triggerLoginActions();
                });
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(parentTargetNode, config);

}
