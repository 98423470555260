import dayflightsEngineInteraction from "./dayflightsEngineInteraction";
import autocomplete, {AutocompleteItem} from "autocompleter";

export default function dayflightsSearch() {
    const url_ajax = '/' + window.drupalSettings.path.pathPrefix + 'ajax/dayflights/search-autocomplete';
    let searchInput = document.querySelector(`#df-flight`) as HTMLInputElement;

    // Autocomplete destination.
    function autoCompleteFunction(elem: HTMLInputElement) {
        type MyItem = AutocompleteItem;
        const empty = elem.getAttribute('data-empty') ?? '';
        autocomplete<MyItem>({
            input: elem,
            emptyMsg: empty,
            minLength: 3,
            fetch: (text: string, update: (items: any[]) => void) => {
                text = text.toLowerCase();
                let data = {
                    search: text,
                };
                fetch(url_ajax, {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(function (json) {
                        update(json);
                    })
                    .catch(error => console.log(error.message)) // logs any error from the promise
            },
            onSelect: async (item: any) => {
                searchInput.removeAttribute('data-search-type');
                searchInput.removeAttribute('data-search-value');
                searchInput.removeAttribute('data-search-value');
                searchInput.value = item.label;
                if (item.type == 'destination') {
                    searchInput.setAttribute('data-search-type', item.type);
                    searchInput.setAttribute('data-search-value', item.iata_airport_code);
                }
                if (item.type == 'airline_company') {
                    searchInput.setAttribute('data-search-type', item.type);
                    searchInput.setAttribute('data-search-value', item.iata_company);
                }
                if (item.type == 'flight_number') {
                    searchInput.setAttribute('data-search-type', item.type);
                    searchInput.setAttribute('data-search-value', item.label);
                }

            },
            render: function (item: any): HTMLDivElement | undefined {
                const itemElement = document.createElement("div");
                itemElement.classList.add('data');
                itemElement.textContent = item.label;
                return itemElement;
            },
            renderGroup: function (item: any): HTMLDivElement | undefined {
                const itemElement = document.createElement("div");
                itemElement.classList.add('lists-group__item-title');
                itemElement.innerHTML = item;
                return itemElement;
            },
            preventSubmit: true,
        });

    }
    autoCompleteFunction(searchInput);

}
