import { ui } from "../../utils/ui.js";

const checkSearchFields = () => {
    const submit = ui?.search?.actions?.submit;
    const airports = ui?.search?.fields?.destinations.value.toString();
    const from = ui?.search?.fields?.hiddenFrom.value;
    const to = ui?.search?.fields?.hiddenTo.value;
    const checked = [airports, from, to].filter((value) => !!value);
    checked.length > 0 ? (submit.disabled = false) : (submit.disabled = true);
};

const resetSearchFields = () => ui?.search?.form?.reset();

const showAutocomplete = () => ui?.autoCompleteCities?.setAttribute("style", "display: block;");
const hideAutocomplete = () => ui?.autoCompleteCities?.removeAttribute("style");
const showTakeoffPanel = () => ui?.takeoffPanel?.setAttribute("style", "display: block;");
const hidePanelFrom = () => ui?.takeoffPanel?.removeAttribute("style");
const showLandingPanel = () => ui?.landingPanel?.setAttribute("style", "display: block;");
const hidePanelTo = () => ui?.landingPanel?.removeAttribute("style");

export {
    checkSearchFields,
    resetSearchFields,
    showAutocomplete,
    hideAutocomplete,
    showTakeoffPanel,
    hidePanelFrom,
    showLandingPanel,
    hidePanelTo,
};
