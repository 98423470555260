export default async function followFlightAction() {

    const url_follow_flight = '/' + window.drupalSettings.path.pathPrefix + 'ajax/account/follow-flight';

    const followButtons = document.querySelectorAll(`.card-flight__action`);
    followButtons.forEach((el: Element) => {
        const trigger = el as HTMLElement;
        trigger.addEventListener('click', () => {
            followFlight(el);
        },false);
    });

    async function followFlight(elem: any) {

        // Call ajax.
        let data = {
            dayflight_id: elem.getAttribute("data-dayflight-id"),
        };

        fetch(url_follow_flight, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(function() {
            location.replace(document.URL);
        }).catch(function(error) {
            console.log(error);
        });
    }

    // Add to followed flights from url.
    const followedFlightsAction = document.querySelector('section.followed-flights') as HTMLElement;
    if (followedFlightsAction) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        if (url.searchParams.has('followflight')) {
            const onlyUrl = window.location.href.replace(window.location.search,'');
            // Call ajax.
            let data = {
                dayflight_id: url.searchParams.get('followflight')
            };
            window.history.pushState('', '', onlyUrl);
            fetch(url_follow_flight, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            })
                .then(function(response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                }).then(function() {
                location.replace(document.URL);
            }).catch(function(error) {
                console.log(error);
            });
        }
    }

}
