export default function dayflightsEngineInteraction() {
    const listData = document.querySelectorAll('.dropdown .data');
    const dropdownToggles = document.querySelectorAll(".dropdown-toggle");

    // Show dropdowns
    function openDropdown(event: MouseEvent) {
        const elem = event.target as HTMLElement;
        const parent = elem?.parentNode as HTMLElement;
        parent?.classList.add("dropdown-shown");
    }

    function resetDropdowns(){
        const element = document.querySelector(".dayflights-engine__item.dropdown-shown") as HTMLElement;
        element?.classList.remove("dropdown-shown");
    }

    function documentHandler(){
        resetDropdowns();
        document.removeEventListener('click', documentHandler , false); //remove document click listener
    }

    if (dropdownToggles.length > 0) {
        dropdownToggles.forEach( (el: Element) => {
            const trigger = el as HTMLElement;
            trigger.addEventListener('click',function(event: MouseEvent) {
                const elem = event.target as HTMLElement;
                const parent = elem?.parentNode as HTMLElement;
                var isActive = parent.classList.contains("dropdown-shown");
                resetDropdowns();
                if (isActive) {
                    return;
                }
                event.stopPropagation();
                event.preventDefault();
                openDropdown(event);
                document.addEventListener('click', documentHandler, false); //add document click listener to close dropdown on outside click
            }, false);
        })
    }

    // Get value of clicked element
    listData.forEach((el: Element) => {
        const trigger = el as HTMLElement;
        trigger.addEventListener('click',(e: MouseEvent)=>{
                const elem = e.target as HTMLElement;
                const textContent = elem?.textContent;
                const closest = trigger.closest('.dayflights-engine__item') as HTMLElement;
                const input = closest?.querySelector('input[type=text]') as HTMLInputElement;
                if (closest) {
                    if (input && textContent) {
                        input.value = textContent;
                    }
                    const radioInput = document.getElementById(`${trigger.getAttribute("for")}`) as HTMLInputElement;
                    if (radioInput) {
                        if (radioInput.checked) {
                            input.value = "";
                            input.removeAttribute('data-search-value');
                            radioInput.checked = false;
                            e.preventDefault();
                        }else {
                            input.setAttribute('data-search-value', `${radioInput.value}`);
                        }
                    }
                }
            }
        )
    })

}
