import calendar from "./calendar";
import autocomplete, { AutocompleteItem } from "autocompleter";

export default function bookingPanel() {
    const bookingPanel = document.querySelector(".booking-panel") as HTMLElement;
    // @ts-ignore
    const translations = window.booking_panel_translations;
    const fuckingForm = bookingPanel.querySelector("#flight-form");

    // Show/hide booking panel and overlays.
    const bookingPanelBtn = document.querySelector(".booking-trip-btn") as any;
    if (bookingPanelBtn) {
        bookingPanelBtn.addEventListener("click", showBookingPanel);
    }

    document.addEventListener("click", function (event: MouseEvent) {
        let elem = event.target as HTMLElement;
        if (!elem.closest(".destination-calendar ._days li")) return;

        showBookingPanel();
    });

    // Behaviour for booking panel template
    if (
        document.body.classList.contains("page-node-type-booking-page") ||
        document.body.classList.contains("page-node-type-homepage")
    ) {
        document.addEventListener("click", async function (event: MouseEvent) {
            let elem = event.target as HTMLElement;
            if (elem.closest(".form__item input") || elem.closest(".overlay.active")) return;

            closeOverlays();
        });
    }

    document.addEventListener("click", closeBookingPanel);

    const BacKBtns = bookingPanel.querySelectorAll(".back") as NodeListOf<Element>;
    BacKBtns.forEach(function (backBtn: any) {
        backBtn.addEventListener("click", closeOverlays);
    });

    function showBookingPanel() {
        bookingPanel.classList.add("active");
    }

    function closeBookingPanel(event: any) {
        if (event.target.classList.contains("underlay") || event.target.classList.contains("booking-panel__close")) {
            closeOverlays();
            bookingPanel.classList.remove("active");
        }
    }

    function closeOverlays() {
        const Overlays = bookingPanel.querySelectorAll(".overlay") as any;
        Overlays.forEach(function (overlay: any) {
            overlay.classList.remove("active");
        });
    }

    // Tabs management.
    const tabButtons = bookingPanel.querySelectorAll('[role="tab"]') as NodeListOf<Element>;
    const tabPanels = bookingPanel.querySelectorAll('[role="tabpanel"]') as NodeListOf<Element>;

    tabButtons.forEach((button: any) => {
        button.addEventListener("click", handleTabClick);
    });

    function handleTabClick(event: any) {
        tabPanels.forEach(function (panel: any) {
            panel.hidden = true;
        });

        tabButtons.forEach(function (tab: any) {
            tab.setAttribute("aria-selected", String(false));
        });

        event.currentTarget.setAttribute("aria-selected", String(true));
        const id = event.currentTarget.id;

        const tabPanel = bookingPanel.querySelector(`[aria-labelledby="${id}"]`) as any;
        tabPanel.hidden = false;
    }

    // Round trip switcher.
    const roundTripButtons = document.querySelectorAll(".form__trip-type button") as NodeListOf<Element>;
    if (roundTripButtons.length > 0) {
        roundTripButtons.forEach((button: any) => {
            button.addEventListener("click", tripTypeClick);
        });
    }

    function tripTypeClick(event: any) {
        event.preventDefault();

        roundTripButtons.forEach(function (btn: any) {
            btn.classList.remove("active");
        });

        event.currentTarget.classList.add("active");
        const id = event.currentTarget.id;

        const to = bookingPanel.querySelector("#to") as any;
        if (id === "one-way") {
            to.closest(".form__item-input")?.setAttribute("style", "opacity: 0");
            to.value = "";
            to.setAttribute("data-value", "");
        } else {
            to.closest(".form__item-input")?.removeAttribute("style");
        }
    }

    // Cities switch.
    const departureElement = document.querySelector('.form__cities input[name="departure"]') as HTMLInputElement;
    const arrivalElement = document.querySelector('.form__cities input[name="arrival"]') as HTMLInputElement;
    const switcher = document.querySelector(".form__cities .switch") as any;

    if (switcher) {
        switcher.addEventListener("click", switchCities);
    }

    async function switchCities() {
        const departureValue = departureElement?.value;
        const departureIATAId = departureElement.getAttribute("data-iata-id") as string | null;
        const departureIATA = departureElement.getAttribute("data-iata") as string | null;
        const arrivalOldValue = arrivalElement?.value;
        const arrivalIATAId = arrivalElement.getAttribute("data-iata-id") as string | null;
        const arrivalIATA = arrivalElement.getAttribute("data-iata") as string | null;

        departureElement.value = arrivalOldValue;
        departureElement.setAttribute("value", arrivalOldValue);
        if (typeof arrivalIATA === "string") {
            departureElement.setAttribute("data-iata", arrivalIATA);
        }
        if (typeof arrivalIATAId === "string") {
            departureElement.setAttribute("data-iata-id", arrivalIATAId);
        }

        arrivalElement.value = departureValue;
        arrivalElement.setAttribute("value", departureValue);
        if (typeof departureIATA === "string") {
            arrivalElement.setAttribute("data-iata", departureIATA);
        }
        if (typeof departureIATAId === "string") {
            arrivalElement.setAttribute("data-iata-id", departureIATAId);
        }

        if (
            document.body.classList.contains("page-node-type-booking-page") ||
            document.body.classList.contains("page-node-type-homepage")
        ) {
            if (departureIATA === "NTE") {
                departureElement.disabled = false;
                arrivalElement.disabled = true;
            } else {
                departureElement.disabled = true;
                arrivalElement.disabled = false;
            }
        }

        if (departureElement.classList.contains("error")) {
            arrivalElement.classList.add("error");
            departureElement.classList.remove("error");
        }
        if (arrivalElement.classList.contains("error")) {
            departureElement.classList.add("error");
            arrivalElement.classList.remove("error");
        }

        // Reload calendar with new flights.
        let action = "switch";
        await calendar(action, "");
    }

    // From/To inputs.
    const FromToInputs = bookingPanel.querySelectorAll(
        ".form__from-to > .form__item-input > input"
    ) as NodeListOf<Element>;
    const FromToOverlay = bookingPanel.querySelector(".form__from-to .overlay") as any;
    const FromToCalendars = bookingPanel.querySelectorAll(".form__from-to .calendar") as any;
    FromToInputs.forEach((input: any) => {
        input.addEventListener("click", FromToClick);
    });

    const FromToOverlayTabs = bookingPanel.querySelectorAll(".form__from-to .from-to-tabs > div") as any;
    FromToOverlayTabs.forEach((tab: any) => {
        tab.addEventListener("click", FromToDisplayCalendar);
    });

    function FromToClick(event: any) {
        if (
            document.body.classList.contains("page-node-type-booking-page") ||
            document.body.classList.contains("page-node-type-homepage")
        ) {
            closeOverlays();
        }

        FromToDisplayCalendar(event);
        FromToOverlay.classList.add("active");
    }

    async function FromToDisplayCalendar(event: any) {
        const tabTo = document.querySelector(".form__trip-type #one-way") as HTMLElement;
        const tabToCalendar = document.querySelector(".from-to-tabs .tabs-to") as HTMLElement;

        if (tabTo.classList.contains("active")) {
            tabToCalendar.style.display = "none";
        } else {
            tabToCalendar.style.display = "block";
        }

        FromToCalendars.forEach((calendar: any) => {
            calendar.classList.remove("active");
        });

        const FromToCalendarTabs = bookingPanel.querySelectorAll(".from-to-tabs > div") as any;
        FromToCalendarTabs.forEach((calendar: any) => {
            calendar.classList.remove("active");
        });

        if (event.target.getAttribute("data-id") == "from") {
            const FromToCalendarFrom = bookingPanel.querySelector(".form__from-to .from-to__calendar-from") as any;
            FromToCalendarFrom.classList.add("active");

            const FromToCalendarTab = bookingPanel.querySelector(".from-to-tabs .tabs-from") as any;
            FromToCalendarTab.classList.add("active");

            // Update calendar.
            await calendar("switch", "from");
        }

        if (event.target.getAttribute("data-id") == "to") {
            const FromToCalendarTo = bookingPanel.querySelector(".form__from-to .from-to__calendar-to") as any;
            FromToCalendarTo.classList.add("active");

            const FromToCalendarTab = bookingPanel.querySelector(".from-to-tabs .tabs-to") as any;
            FromToCalendarTab.classList.add("active");

            // Update calendar.
            await calendar("switch", "to");
        }
    }

    // Calendar : click in a date in from calendar.
    document.addEventListener("click", async function (event: MouseEvent) {
        let elem = event.target as HTMLElement;
        if (!elem.closest("._days li.hasVol")) return;

        const DatesFromCalendars = bookingPanel.querySelector(".from-to__calendar-from.active") as HTMLElement;
        if (DatesFromCalendars && DatesFromCalendars.contains(elem)) {
            FromToCalendars.forEach((calendar: any) => {
                calendar.classList.remove("active");
            });

            const FromToCalendarTabs = bookingPanel.querySelectorAll(".from-to-tabs > div") as any;
            FromToCalendarTabs.forEach((calendar: any) => {
                calendar.classList.remove("active");
            });

            const FromToCalendarTo = bookingPanel.querySelector(".form__from-to .from-to__calendar-to") as any;
            FromToCalendarTo.classList.add("active");

            const FromToCalendarTab = bookingPanel.querySelector(".from-to-tabs .tabs-to") as any;
            FromToCalendarTab.classList.add("active");

            const tabTo = document.querySelector(".form__trip-type #one-way") as HTMLElement;

            if (tabTo.classList.contains("active")) {
                closeOverlays();
            } else {
                // Update calendar.
                await calendar("switch", "to");
            }
        }

        // Close calendar after select to arrival date.
        const DatesToCalendars = bookingPanel.querySelector(".from-to__calendar-to.active") as HTMLElement;
        if (DatesToCalendars && DatesToCalendars.contains(elem)) {
            closeOverlays();
        }
    });

    // Valide dates.
    function validateDates() {
        let check = true;

        const formFromTo = document.querySelector(".form__from-to") as any;
        formFromTo.classList.remove("hasError");

        FromToInputs.forEach(function (input: any) {
            input.classList.remove("error");
        });

        const formTripType = document.querySelectorAll(".form__trip-type button") as any;
        formTripType.forEach(function (type: any) {
            if (type.id == "round-trip" && type.classList.contains("active")) {
                FromToInputs.forEach(function (input: any) {
                    if (!input.value) {
                        input.classList.add("error");
                        check = false;
                    }
                });
            } else if (type.id == "one-way" && type.classList.contains("active")) {
                const inputFrom = document.querySelector(".form__from-to input#from") as any;
                if (!inputFrom.value) {
                    inputFrom.classList.add("error");
                    check = false;
                }
            }
        });

        if (!check) {
            formFromTo.classList.add("hasError");
        }

        return check;
    }

    // Travellers overlay.
    const travellersInput = bookingPanel.querySelector(".form__traveller > .traveller-total-wrapper") as HTMLElement;
    const travellersOverlay = bookingPanel.querySelector(".form__traveller .overlay") as any;
    if (travellersInput) {
        travellersInput.addEventListener("click", travellersInputClick);
    }

    function travellersInputClick() {
        if (
            document.body.classList.contains("page-node-type-booking-page") ||
            document.body.classList.contains("page-node-type-homepage")
        ) {
            closeOverlays();
        }

        travellersOverlay.classList.add("active");
    }

    // Save button.
    const travellersButton = bookingPanel.querySelector(".form__traveller .form__submit button") as HTMLElement;
    if (travellersButton) {
        travellersButton.addEventListener("click", closeOverlays);
    }

    // Checking travellers.
    const travellerInputs = document.querySelectorAll(".form__traveller .overlay input") as NodeListOf<Element>;
    if (travellerInputs.length > 0) {
        travellerInputs.forEach((input: any) => {
            input.addEventListener("input", validateTravellers);
        });
    }

    // Validate travellers.
    function validateTravellers() {
        let check = true;

        const btnSubmit = document.querySelector('#flight-form button[type="submit"]') as HTMLInputElement;

        const travellersWrapper = document.querySelector(".traveller-total-wrapper") as HTMLInputElement;
        travellersWrapper.classList.remove("hasError");

        let inputTravellers = bookingPanel.querySelector('[name="travellers"]') as HTMLInputElement;
        inputTravellers.classList.remove("error");

        let inputTravellersDetails = bookingPanel.querySelector('[name="travellers-details"]') as HTMLInputElement;

        // Remove error message.
        const errorMessage = document.querySelector(".form__traveller .overlay .error-message") as HTMLInputElement;
        errorMessage.textContent = "";

        // Remove style error.
        travellerInputs.forEach(function (input: any) {
            input.classList.remove("error");
        });

        const adults = bookingPanel.querySelector(".adults") as HTMLInputElement;
        const adultsValue = adults?.value;

        const children = bookingPanel.querySelector(".children") as HTMLInputElement;
        const childrenValue = children?.value;

        const babies = bookingPanel.querySelector(".babies") as HTMLInputElement;
        const babiesValue = babies?.value;

        const total = (Number(adultsValue) + Number(childrenValue) + Number(babiesValue)) as number;

        inputTravellersDetails.classList.remove("error");

        if (total > 9) {
            // 9 travellers max.
            errorMessage.textContent = translations.max_travalers;
            travellerInputs.forEach(function (input: any) {
                input.classList.add("error");
                btnSubmit.setAttribute("disabled", "disabled");
            });
            check = false;
        } else if (adultsValue < babiesValue) {
            // Minimum 1 adult for 1 baby.
            errorMessage.textContent = translations.infant_adult_allowed;
            adults.classList.add("error");
            babies.classList.add("error");
            btnSubmit.setAttribute("disabled", "disabled");
            check = false;
        } else if (total > 0) {
            if (inputTravellers && inputTravellersDetails) {
                inputTravellers.setAttribute("value", String(total));
                btnSubmit.removeAttribute("disabled");

                let adultsDetails = Number(adultsValue) >= 1 ? adultsValue : 0;
                if (Number(adultsValue) > 1) {
                    adultsDetails = adultsDetails + " " + translations.adults + ", ";
                } else {
                    adultsDetails = adultsDetails + " " + translations.adult + ", ";
                }

                let childrenDetails = Number(childrenValue) >= 1 ? childrenValue : 0;
                if (Number(childrenValue) > 1) {
                    childrenDetails = childrenDetails + " " + translations.children + ", ";
                } else {
                    childrenDetails = childrenDetails + " " + translations.child + ", ";
                }

                let babiesDetails = Number(babiesValue) >= 1 ? babiesValue : 0;
                if (Number(babiesValue) > 1) {
                    babiesDetails = babiesDetails + " " + translations.infants;
                } else {
                    babiesDetails = babiesDetails + " " + translations.infant;
                }

                let total_details = adultsDetails + childrenDetails + babiesDetails;
                inputTravellersDetails.setAttribute("value", total_details);
            }
        } else {
            inputTravellersDetails.classList.add("error");
            inputTravellersDetails.setAttribute("value", "");
            travellersWrapper.classList.add("hasError");
            check = false;
        }

        return check;
    }

    // Submit.
    fuckingForm?.addEventListener("submit", function (event) {
        event.preventDefault();

        if (validateForm()) {
            sendForm();
        }
    });

    // Validate form.
    function validateForm() {
        return validateCities() && validateDates() && validateTravellers();
    }

    // Send form.
    function sendForm() {
        let partner_id = `1836687`;

        let form_type = "" as string;
        let roundTripButtons = document.querySelectorAll(".form__trip-type button") as NodeListOf<Element>;
        roundTripButtons.forEach((button: any) => {
            if (button.classList.contains("active")) {
                if (button.id == "round-trip") {
                    form_type = "ROUNDTRIP";
                } else {
                    form_type = "ONEWAY";
                }
            }
        });

        let departureElement = document.querySelector('.form__cities input[name="departure"]') as HTMLInputElement;
        let departure = departureElement.getAttribute("data-iata") as string | null;

        let arrivalElement = document.querySelector('.form__cities input[name="arrival"]') as HTMLInputElement;
        let arrival = arrivalElement.getAttribute("data-iata") as string | null;

        let dateFromElement = document.querySelector(".form__from-to input#from") as HTMLInputElement;
        let dateFrom = dateFromElement.getAttribute("data-value") as string | null;

        let dateToElement = document.querySelector(".form__from-to input#to") as HTMLInputElement;
        let dateTo = dateToElement.getAttribute("data-value") as string | null;

        let adultsElement = bookingPanel.querySelector(".adults") as any;
        let adults = adultsElement?.value ? adultsElement?.value : 0;
        let childrenElement = bookingPanel.querySelector(".children") as any;
        let children = childrenElement?.value ? childrenElement?.value : 0;
        let babiesElement = bookingPanel.querySelector(".babies") as any;
        let babies = babiesElement?.value ? babiesElement?.value : 0;

        // Build params.
        const params = new URLSearchParams({
            form_type: `${form_type}`,
            from: `${departure};${arrival}`,
            to: `${arrival};${departure}`,
            date_out: `${dateFrom};${dateTo}`,
            pax_number: `${adults},${children},${babies}`,
        });

        // Target blank to Optionway.
        window.open(`https://www.optionway.com/rl/${partner_id}?${params.toString()}`);
    }

    // Autocomplete for the destination list in booking engine page.

    function autoComplete(elem: HTMLInputElement) {
        if (elem) {
            elem.disabled = false;
            const all_destinations = window.all_destinations && JSON.parse(window.all_destinations);

            if (!all_destinations) return;

            type MyItem = AutocompleteItem;
            autocomplete<MyItem>({
                input: elem,
                emptyMsg: "Aucune destination",
                minLength: 2,
                fetch: (text: string, update: (items: any[]) => void) => {
                    text = text.toLowerCase();
                    // @ts-ignore
                    let suggestions = all_destinations.filter((n) => n.destination_name.toLowerCase().startsWith(text));
                    update(suggestions);
                },
                onSelect: async (item: any) => {
                    elem.value = item.destination_name;
                    elem.setAttribute("value", item.destination_name);
                    elem.setAttribute("data-iata", item.code_iata);
                    elem.setAttribute("data-iata-id", item.code_iata_id);

                    // Reload calendars.
                    await calendar("switch", "");
                },
                render: function (item: any): HTMLDivElement | undefined {
                    const itemElement = document.createElement("div");
                    itemElement.textContent = item.destination_name;
                    return itemElement;
                },
            });
        }
    }

    if (
        document.body.classList.contains("page-node-type-booking-page") ||
        document.body.classList.contains("page-node-type-homepage")
    ) {
        if (arrivalElement && departureElement) {
            autoComplete(departureElement);
            autoComplete(arrivalElement);
            arrivalElement.value = "";
            arrivalElement.setAttribute("value", "");
            arrivalElement.setAttribute("data-iata", "");
            arrivalElement.setAttribute("data-iata-id", "");
            // Need to set again departureElement ("NTE" on loading page) because autoComplete enable element.
            departureElement.setAttribute("disabled", "disabled");
        }
    }

    // Validate cities.
    function validateCities() {
        let check = true;
        const formCities = document.querySelector(".form__cities") as any;
        formCities.classList.remove("hasError");

        let departureElement = formCities.querySelector('input[name="departure"]') as HTMLInputElement;
        departureElement.classList.remove("error");
        let arrivalElement = document.querySelector('input[name="arrival"]') as HTMLInputElement;
        arrivalElement.classList.remove("error");

        if (!departureElement.value) {
            departureElement.classList.add("error");
            check = false;
        }
        if (!arrivalElement.value) {
            arrivalElement.classList.add("error");
            check = false;
        }
        if (!check) {
            formCities.classList.add("hasError");
        }

        return check;
    }
}
