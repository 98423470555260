import { lang } from './variables';

const checkAllPromoButton = () => {
    const addElemToPromo = document.querySelectorAll('.add-to-promo:not(.open-login)');
    if (addElemToPromo.length > 0) {
        addElemToPromo.forEach((elem: Element) => {
            const btn = elem as HTMLElement;
            if (btn.dataset.nodeid) {
                fetch(`/${lang}/ajax/in-promo`, {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify([btn.dataset.nodeid])
                })
                    .then(response => response.json())
                    .then(function (json) {
                        if (json.inpromo) {
                            btn.classList.add('in-promo');
                        }
                        else {
                            btn.classList.remove('in-promo');
                        }
                    });
            }
        });
    }

}

const addtopromo = (addElemToPromo: HTMLElement) => {
    checkAllPromoButton();
    addElemToPromo.onclick = () => {
        if (!addElemToPromo.classList.contains('in-promo') && addElemToPromo.dataset.nodeid) {
            fetch(`/${lang}/ajax/add-current-to-promo`, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify([addElemToPromo.dataset.nodeid])
            })
                .then(response => response.json())
                .then(function () {
                    window.location.reload();
                });
        }
        if (addElemToPromo.classList.contains('in-promo') && addElemToPromo.dataset.nodeid) {
            fetch(`/${lang}/ajax/remove-current-from-promo`, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify([addElemToPromo.dataset.nodeid])
            })
                .then(response => response.json())
                .then(function () {
                    window.location.reload();
                });
        }
    }
}

export default addtopromo;
