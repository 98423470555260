const ManageDestination = (): void => {
    const triggerAddToFav = document.querySelector('.add-to-wishlist') as HTMLElement;
    const triggerAddToAlerts = document.querySelector('.add-to-promo') as HTMLElement;
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (url.searchParams.has('addtofav')) {
        const onlyUrl = window.location.href.replace(window.location.search,'');
        window.history.pushState('', '', onlyUrl);
        triggerAddToFav.click();
    }

    if (url.searchParams.has('addtoalerts')) {
        const onlyUrl = window.location.href.replace(window.location.search,'');
        window.history.pushState('', '', onlyUrl);
        triggerAddToAlerts.click();
    }

}

export default ManageDestination;
