export default function multiLevelMenu() {
    const headerMenu = document.querySelector('.header__nav') as HTMLElement;
    // Desktop version.
    if (window.matchMedia("(min-width: 992px)").matches) {
        // Nested menu
        function closeOpenNavs() {
            var openDrops = headerMenu?.getElementsByClassName("menu__link--0 has-children");
            for (var i = 0; i < openDrops.length; i++) {
                var openDropdown = openDrops[i];
                if (openDropdown.classList.contains('is-expanded')) {
                    openDropdown.classList.remove('is-expanded');
                    openDropdown.closest('.menu__links')?.classList.remove('has-open-submenu');
                }
            }
        }

        headerMenu?.addEventListener('click', function (event) {
            //if not a dropdown / click outside close open navs
            let elem = event.target as HTMLElement;
            if (!elem.closest('.menu__link--0.has-children')){
                closeOpenNavs();
            }
            //if you click the trigger of an open menu, close it
            else if (elem.closest('.has-children__trigger') && elem.closest('.is-expanded')){
              elem.closest('.menu__link--0.has-children')?.classList.remove('is-expanded');
            }
            //if a drop target, close any open, then open this one
            else if (elem.closest('.has-children')){
                closeOpenNavs();
                elem.closest('.menu__link--0.has-children')?.classList.add('is-expanded');
            }
        }, false);

        // Close calendar
        document.addEventListener('click', function (event) {
            let elem = event.target as HTMLElement;
            let menu = headerMenu?.querySelector('.menu__links') as HTMLElement;
            let menusOpened = Array.from(document.querySelectorAll('.is-expanded'));
            if (menu && elem !== menu && !menu.contains(elem) ) {
                menusOpened.forEach(node => {
                    node.classList.remove('is-expanded');
                });
            }
        }, false);
    } else {
        headerMenu?.addEventListener('click', function (event) {

            let elem = event.target as HTMLElement;
            if (elem.closest('.has-children__trigger--mobile')){
                event.preventDefault();
                elem.closest('.parent')?.classList.add('has-open-submenu');
                elem.closest('.has-children')?.classList.add('is-expanded');
            }

            if (elem.closest('.back')){
                event.preventDefault();
                elem.closest('.has-children.is-expanded')?.classList.remove('is-expanded');
                elem.closest('.parent.has-open-submenu')?.classList.remove('has-open-submenu');
            }
            return false;
        }, false);
    }
}
