import { ui } from "../../utils/ui";

const getFields = () => {
    const hiddenAirports = getHiddenFieldDestination();
    const hiddenFrom = getHiddenFieldFrom();
    const hiddenTo = getHiddenFieldTo();
    return { hiddenAirports, hiddenFrom, hiddenTo };
};

const getFieldCountry = (): string | null =>
    ui?.search?.fields?.country?.value ? ui.search.fields.country.value : null;

const getFieldDestinations = (): string | null =>
    ui?.search?.fields?.destinations?.value ? ui.search.fields.destinations.value : null;

const getFieldFrom = (): string => (ui?.search?.fields?.from?.value ? ui.search.fields.from.value : "anytime");

const getFieldTo = (): string => (ui?.search?.fields?.to?.value ? ui.search.fields.to.value : "anytime");

const getHiddenFieldCountry = (): number | null =>
    ui?.search?.fields?.hiddenCountry?.value ? Number(ui.search.fields.hiddenCountry.value) : null;

const getHiddenFieldDestination = () => ui?.search?.fields?.destinations?.value.toString();

const getHiddenFieldFrom = () => (ui?.search?.fields?.hiddenFrom?.value ? ui.search.fields.hiddenFrom.value : null);

const getHiddenFieldTo = () => (ui?.search?.fields?.hiddenTo?.value ? ui.search.fields.hiddenTo.value : null);

export {
    getFields,
    getFieldCountry,
    getFieldDestinations,
    getFieldFrom,
    getFieldTo,
    getHiddenFieldCountry,
    getHiddenFieldDestination,
    getHiddenFieldFrom,
    getHiddenFieldTo,
};
