import { showLoader, hideLoader } from "../features/Loader/action";
import { ApiResponse, ProflightsDestinations, ProflightsFlights } from "./types";

const apiUrl: string = `${location.protocol}//${location.host}/ajax`;

const getDestinations = async (): Promise<ApiResponse<ProflightsDestinations>> => {
    let data = [];

    try {
        const response = await fetch(`${apiUrl}/proflights/destinations`);

        if (!response.ok) {
            hideLoader();
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        data = await response.json();
    } catch (error) {
        console.error(error);
    }

    return data;
};

const getFlights = async (
    takeoffSchedule: string | null,
    landingSchedule: string | null,
    airports: string | null
): Promise<ApiResponse<ProflightsFlights>> => {
    let url = `${apiUrl}/proflights/flights/${takeoffSchedule}/${landingSchedule}`;
    let data = [];

    showLoader();

    if (airports) {
        url = `${url}/${airports}`;
    }

    try {
        const response = await fetch(url);

        if (!response.ok) {
            hideLoader();
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        data = await response.json();
    } catch (error) {
        console.error(error);
    }

    return data;
};

const getAutocompleteRequest = async (type: string, search: any): Promise<any> => {
    let data = [] as any;

    try {
        const response = await fetch(`/fr/ajax/proflights/autocomplete/${type}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(search),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        data = await response.json();
    } catch (error) {
        console.error(error);
    }
    return data;
};

export { getDestinations, getFlights, getAutocompleteRequest };
