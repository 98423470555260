export default async function userFormValidation(type: string) {

    let form: any;
    if (type === 'registerForm') {
        form = document.getElementById('user-register-form') as HTMLFormElement;
    } else if (type === 'updateProfile') {
        form = document.getElementById('user-edit-account-form') as HTMLFormElement;
    } else if (type === 'resetPasswordRequest') {
        form = document.getElementById('user-pass') as HTMLFormElement;
    } else if (type === 'loginForm') {
        form = document.getElementById('user-login-form') as HTMLFormElement;
    } else {
        form = document.getElementById('user-update-password-form') as HTMLFormElement;
    }

    form?.addEventListener('submit', function (e: SubmitEvent) {
        e.preventDefault();

        const translations = window.userFormTranslations;
        const civilityRadioWrapper = document.getElementById('edit-field-civility--wrapper') as HTMLInputElement;
        const civilityMrRadio = document.getElementById('edit-field-civility-mr') as HTMLInputElement;
        const civilityMrsRadio = document.getElementById('edit-field-civility-ms') as HTMLInputElement;
        const firstnameInput = document.getElementById('edit-field-firstname-0-value') as HTMLInputElement;
        const lastnameInput = document.getElementById('edit-field-lastname-0-value') as HTMLInputElement;
        const countryInput = document.getElementById('edit-field-country') as HTMLSelectElement;
        const postcodeInput = document.getElementById('edit-field-postcode-0-value') as HTMLInputElement;
        const cityInput = document.getElementById('edit-field-city-0-value') as HTMLInputElement;
        const citySelect = document.getElementById('select-city') as HTMLSelectElement;
        const emailInput = document.getElementById('edit-mail') as HTMLInputElement;
        const passwordInput = document.getElementById('edit-pass-pass1') as HTMLInputElement;
        const confirmPasswordInput = document.getElementById('edit-pass-pass2') as HTMLInputElement;
        const flightNotificationInput = document.getElementById('edit-field-flight-notification--wrapper') as HTMLElement;
        const flightNotificationEmailInput = document.getElementById('edit-field-flight-notification-email') as HTMLInputElement;
        const flightNotificationSMSInput = document.getElementById('edit-field-flight-notification-sms') as HTMLInputElement;
        const goodDealNotificationInput = document.getElementById('edit-field-good-deal-notification--wrapper') as HTMLElement;
        const goodDealNotificationEmailInput = document.getElementById('edit-field-good-deal-notification-email') as HTMLInputElement;
        const goodDealNotificationSMSInput = document.getElementById('edit-field-good-deal-notification-sms') as HTMLInputElement;
        const phoneInput = document.getElementById('edit-field-phone-0-value-int-phone') as HTMLInputElement;
        const newsletterInput = document.getElementById('edit-field-newsletter--wrapper') as HTMLElement;
        const newsletterYesInput = document.getElementById('edit-field-newsletter-1') as HTMLInputElement;
        const newsletterNoInput = document.getElementById('edit-field-newsletter-0') as HTMLInputElement;
        const rgpdInput = document.getElementById('edit-field-rgpd-value') as HTMLInputElement;
        const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const inputArray = [civilityRadioWrapper, firstnameInput, lastnameInput, countryInput, postcodeInput, cityInput, emailInput, passwordInput, confirmPasswordInput, flightNotificationInput, goodDealNotificationInput, phoneInput, newsletterInput, rgpdInput];

        inputArray.forEach(item => {
            item?.classList.remove('error');
        });

        let isValid = true;

        const messagesError = document.querySelectorAll('.message-error');
        messagesError.forEach(messageError => {
            messageError.remove();
        })

        class Validator {
            private inputEl: HTMLInputElement;
            private regex: RegExp;

            constructor(inputEl: HTMLInputElement, regex: RegExp) {
                this.inputEl = inputEl;
                this.regex = regex;
            }

            public validate(stringValidation?: string): void {
                const error_messages = {
                    'edit-field-firstname-0-value': translations.firstname_validation,
                    'edit-field-lastname-0-value': translations.lastname_validation,
                    'edit-mail': translations.mail_validation,
                    'edit-name': translations.mail_validation,
                    'edit-field-postcode-0-value': translations.postcode_validation,
                    'edit-field-city-0-value': translations.city_validation,
                    'edit-pass-pass1': translations.password_validation,
                };

                const value = this.inputEl.value.trim();
                if (value === '' || !this.regex.test(value)) {
                    this.inputEl.classList.add('error');
                    isValid = false;

                    let errorSpan = document.createElement('span');
                    errorSpan.classList.add('message-error');
                    this.inputEl.parentElement?.appendChild(errorSpan);
                    errorSpan.innerHTML = stringValidation ?? error_messages[this.inputEl.id as keyof typeof error_messages];
                }
            }
        }

        if (type === 'resetPasswordRequest' || type === 'loginForm') {
            const nameInput = document.getElementById('edit-name') as HTMLInputElement;
            new Validator(nameInput, mailRegex).validate();
        }

        if (type === 'registerForm' ||  type === 'updateProfile') {
            if (!civilityMrRadio.checked && !civilityMrsRadio.checked) {
                isValid = false;
                civilityRadioWrapper.classList.add('error');
                let errorSpan = document.createElement('span');
                errorSpan.classList.add('message-error');
                errorSpan.innerHTML = translations.fill_option;
                civilityRadioWrapper.parentElement?.appendChild(errorSpan);
            }

            new Validator(firstnameInput, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{0,25}$/).validate();
            new Validator(lastnameInput, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{0,30}$/).validate();
            new Validator(emailInput, mailRegex).validate();

            const countryValue = countryInput.value;
            if (countryValue === '') {
                isValid = false;
                countryInput.classList.add('error');
            }

            let selectedOption = countryInput.querySelector(`[value="${countryInput.value}"]`) as HTMLElement;
            let countryName = selectedOption.textContent;
            if (countryName === 'France') {
                new Validator(postcodeInput, /^[0-9]{5}$/).validate(translations.postcode_validation_france);
            }
            else {
                new Validator(postcodeInput, /^[A-Za-z0-9\s]{0,10}$/).validate();
            }

            if (citySelect && citySelect.offsetParent && citySelect.value == '') {
                let errorSpan = document.createElement('span');
                errorSpan.classList.add('message-error');
                errorSpan.innerHTML = translations.choose_city_option;
                citySelect.parentElement?.appendChild(errorSpan);
            }
            new Validator(cityInput, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{0,50}$/).validate();

            if (!flightNotificationEmailInput.checked && !flightNotificationSMSInput.checked) {
                isValid = false;
                flightNotificationInput.classList.add('error');

                let errorSpan = document.createElement('span');
                errorSpan.classList.add('message-error');
                errorSpan.innerHTML = translations.fill_option;
                flightNotificationInput.parentElement?.appendChild(errorSpan);
            }

            if (!goodDealNotificationEmailInput.checked && !goodDealNotificationSMSInput.checked) {
                isValid = false;
                goodDealNotificationInput.classList.add('error');

                let errorSpan = document.createElement('span');
                errorSpan.classList.add('message-error');
                errorSpan.innerHTML = translations.fill_option;
                goodDealNotificationInput.parentElement?.appendChild(errorSpan);
            }

            if ((flightNotificationSMSInput.checked || goodDealNotificationSMSInput.checked) && phoneInput.value == '') {
                isValid = false;
                phoneInput.classList.add('error');
                phoneInput.setAttribute('required', 'required');

                let errorSpan = document.createElement('span');
                errorSpan.classList.add('message-error');
                errorSpan.innerHTML = translations.fill_phone;
                phoneInput.parentElement?.parentElement?.appendChild(errorSpan);
            }

            if (!newsletterYesInput.checked && !newsletterNoInput.checked) {
                isValid = false;
                newsletterInput.classList.add('error');

                let errorSpan = document.createElement('span');
                errorSpan.classList.add('message-error');
                errorSpan.innerHTML = translations.fill_option;
                newsletterInput.parentElement?.appendChild(errorSpan);
            }
        }

        if (type === 'registerForm' && !rgpdInput.checked) {
            isValid = false;
            const rgpdInput = document.getElementById('edit-field-rgpd-value') as HTMLInputElement;
            rgpdInput.classList.add('error');

            let errorSpan = document.createElement('span');
            errorSpan.classList.add('message-error');
            errorSpan.innerHTML = translations.check_box;
            rgpdInput.parentElement?.appendChild(errorSpan);
        }

        if (type === 'registerForm' || type === 'updatePassword') {
            new Validator(passwordInput, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[^\s]).{10,}$/).validate();
            if (passwordInput.value !== confirmPasswordInput.value) {
                isValid = false;
                passwordInput.classList.add('error');
                confirmPasswordInput.classList.add('error');
            }
        }

        if (!isValid) {
            const errors = document.querySelectorAll('.error') as NodeListOf<HTMLElement>;
            const header = document.querySelector('.header') as HTMLElement;
            const scrollTo = errors[0].getBoundingClientRect().top - document.body.getBoundingClientRect().top - header.getBoundingClientRect().height - 25;
            window.scrollTo({top: scrollTo});
        } else {
            form.submit();
        }
    });
}
