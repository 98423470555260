export default function alerts() {
    const container = document.querySelector('.block-alerts__container') as HTMLElement
    if (container) {
        fetchAlerts();
    }
}

function closedAlert(e: any) {
    const closeButton = e.target.closest('button.close') as HTMLElement;
    const alertDiv = closeButton.parentNode as HTMLElement;

    window.localStorage.setItem(`alert-dismissed-${alertDiv.dataset.alertUuid}`, String(Math.round(new Date().getTime() / 1000)));
    alertDiv.classList.toggle('alert-hide');
    alertDiv.classList.toggle('active');

    toggleAlertsDisplayLink();
    slider();
}

function toggleAlertsDisplayLink() {
    const alerts_hidden = document.querySelectorAll('.alert-container.alert-hide') as NodeListOf<HTMLElement>
    const link = document.querySelector('.alerts-display-link') as HTMLElement

    if (alerts_hidden.length > 0) {
        link.classList.remove('hide-alerts-display-link')
    }
    else {
        link.classList.add('hide-alerts-display-link')
    }
}

function initHideAlert() {
    Object.keys(window.localStorage)
        .filter(x => x.startsWith('alert-dismissed'))
        .forEach(x => {
            const uuid = x.substring(16);
            const alert = document.querySelector("[data-alert-uuid='" + uuid + "']") as HTMLElement;
            if (alert) {
                alert.classList.add('alert-hide');
            }
        });
    toggleAlertsDisplayLink();
}

function displayAlert() {
    Object.keys(window.localStorage)
        .filter(x => x.startsWith('alert-dismissed'))
        .forEach(x => {
            localStorage.removeItem(x)
            const uuid = x.substring(16);
            const alert = document.querySelector("[data-alert-uuid='" + uuid + "']") as HTMLElement;
            if (alert) {
                alert.classList.remove('alert-hide');
            }
        });
    toggleAlertsDisplayLink();
}

function slider() {
    const alerts = document.querySelectorAll('.alert-container') as NodeListOf<HTMLElement>
    const alerts_hidden = document.querySelectorAll('.alert-container.alert-hide') as NodeListOf<HTMLElement>
    const paginationContainer = document.querySelector('.block-alerts__container ul') as HTMLElement
    paginationContainer.innerHTML = '';

    // Display pagination / slider, only if we have more than 1 alert.
    if (alerts.length - alerts_hidden.length > 1) {
        paginationContainer.classList.remove('alert-hide');
    }
    else {
        paginationContainer.classList.add('alert-hide');
    }

    // Create pagination item (li tag).
    // If pagination item correspond to hidden alert, we hide pagination item too.
    alerts.forEach((alert) => {
        const paginationItem = document.createElement('li') as HTMLElement
        paginationItem.classList.add('pagination__item')

        if (alert.classList.contains('alert-hide')) {
            paginationItem.classList.add('alert-hide')
        }

        paginationContainer.appendChild(paginationItem)
    })

    const pagination = document.querySelectorAll('.block-alerts__container .pagination li') as NodeListOf<HTMLElement>

    // Remove active class (use to display alert and pagination item)
    pagination.forEach(li => {
        li.classList.remove('active')
    });
    alerts.forEach(alert => {
        alert.classList.remove('active')
    });

    // And active first item of alerts list.
    for (let i = 0; i < alerts.length; i++) {
        if (!alerts[i].classList.contains('alert-hide')) {
            pagination[i].classList.add('active');
            alerts[i].classList.add('active')
            break;
        }
    }

    // Slider operations (play with active class).
    pagination.forEach((el, i) => {
        el.addEventListener('click', () => {
            pagination.forEach(li => {
                li.classList.remove('active')
            })
            pagination[i].classList.add('active')

            alerts.forEach(alert => {
                alert.classList.remove('active')
            })
            alerts[i].classList.add('active')
        })
    })
}

function fetchAlerts() {
    const url_ajax = '/' + window.drupalSettings.path.pathPrefix + 'ajax/alert/list';
    const alertBlock = document.getElementById("block-alerts-block")?.querySelector(".alert-list") as HTMLElement;

    fetch(url_ajax, {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    }).then(function (response) {
        return response.text();
    }).then(function (result) {
        const alerts = JSON.parse(result);
        Object.keys(alerts).reverse().forEach(key => {
            alertBlock.insertAdjacentHTML('beforeend', alerts[key]);
        });

        initHideAlert();
        slider();

        // Display alerts when we click on "There is a hidden alert: show it" link
        const link = document.querySelector('.alerts-display-link') as HTMLElement
        link.addEventListener('click', function() {
            displayAlert();
            slider();
            return false;
        });

        const closeButtons = document.querySelectorAll('.alert .close') as NodeListOf<HTMLElement>
        closeButtons.forEach(closeButton => {
            closeButton.addEventListener('click', e => closedAlert(e));
        })
    })
}
