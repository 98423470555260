import * as svgPanZoomContainer from "svg-pan-zoom-container";

export default function parkingPage() {
    const parkingsPlan = document.querySelectorAll("[data-parking]");
    const tabs_parkings = document.querySelector(".parkings-tabs") as HTMLElement;
    const tabs_subscriptions = document.querySelector(".subscriptions-tabs") as HTMLElement;
    const tabs = document.querySelectorAll(".nav-tabs__link");
    const tabsPane = document.querySelectorAll(".tab__pane");
    let allCards = document.querySelectorAll(".card");

    // Scale map.
    const { pan, zoom, getScale, setScale, resetScale } = svgPanZoomContainer;
    const map = document.querySelector(".parking-tabs__plan-wrapper") as HTMLElement;
    const scale = document.querySelector("#scale") as HTMLInputElement;
    const reset = document.querySelector("#reset") as HTMLButtonElement;
    const increase = document.querySelector("[data-input-stepper-increase]") as HTMLButtonElement;
    const decrease = document.querySelector("[data-input-stepper-decrease]") as HTMLButtonElement;

    if (map) {
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function () {
                scale.value = String(getScale(map) * 100);
            });
        });
        const node = map.firstElementChild as Node;
        observer.observe(node, {
            attributes: true,
            attributeFilter: ["transform"],
        });
        // Scale input.
        scale.addEventListener("change", function () {
            let newScale = parseInt(scale.value) / 100;
            const rect = map.getBoundingClientRect();
            let centerX = rect.left + map.offsetWidth / 2;
            let centerY = rect.top + map.offsetHeight / 2;
            const options = {
                origin: {
                    clientX: centerX,
                    clientY: centerY,
                },
                minScale: 1,
                maxScale: 3,
            };
            setScale(map, newScale, options);
        });
        // Reset scale.
        reset.addEventListener("click", function () {
            resetScale(map);
            return false;
        });
        increase.addEventListener("click", function () {
            let scaleValue = parseInt(scale.value);
            if (scaleValue >= 100 && scaleValue < 300) {
                let count = scaleValue + 50;
                scale.value = String(count);
                scale.dispatchEvent(new Event("change"));
            }
            return false;
        });
        decrease.addEventListener("click", function () {
            let scaleValue = parseInt(scale.value);
            if (scaleValue > 100) {
                let count = scaleValue - 50;
                scale.value = String(count);
                scale.dispatchEvent(new Event("change"));
            }
            return false;
        });
    }

    if (tabs_parkings) {
        const tabs = tabs_parkings.querySelectorAll(".tab__pane");
        tabs.forEach((elem: Element, key: Number) => {
            if (key === 0) {
                elem.classList.add("current");
            }
        });
    }
    if (tabs_subscriptions) {
        const tabs = tabs_subscriptions.querySelectorAll(".tab__pane");
        tabs.forEach((elem: Element, key: Number) => {
            if (key === 0) {
                elem.classList.add("current");
            }
        });
    }

    function setGrey(parkingsPlan: any) {
        parkingsPlan.forEach((unused: any) => {
            unused.setAttribute("style", "fill: #646C78 !important;");
            unused.classList.remove("active");
        });
    }
    function resetGrey(parkingsPlan: any) {
        parkingsPlan.forEach((unused: any) => {
            unused.removeAttribute("style");
            unused.classList.remove("active");
        });
    }
    function cleanCards(allCards: any) {
        allCards.forEach((unused: any) => {
            unused.classList.remove("active");
            const button = unused.querySelector(".card-parking__visualize") as HTMLElement;
            button?.classList.remove("active");
        });
    }

    // Interactions parkings
    if (parkingsPlan.length > 1) {
        parkingsPlan.forEach((parking: any) => {
            parking.addEventListener("click", function (e: MouseEvent) {
                // Current.
                const elem = e.target as HTMLElement;
                // Set all to grey.

                const id = elem.getAttribute("id");
                // Find card parking.
                const card = document.querySelector(`[data-card-id="${id}"]`) as HTMLElement,
                    button = card?.querySelector(".card-parking__visualize") as HTMLElement,
                    tabPane = card?.closest(".tab__pane") as HTMLElement,
                    tab_id = tabPane?.getAttribute("id"),
                    tab = document.querySelector(`[data-tab="${tab_id}"]`);

                allCards.forEach((elemcard: Element) => {
                    elemcard.classList.remove("active");
                    const button = elemcard.querySelector(".card-parking__visualize") as HTMLElement;
                    button?.classList.remove("active");
                });

                if (elem.classList.contains("active")) {
                    cleanCards(allCards);
                    resetGrey(parkingsPlan);
                    elem.classList.remove("active");
                    card?.classList.remove("active");
                    button?.classList.remove("active");
                } else {
                    setGrey(parkingsPlan);
                    if (tabPane && tabs && tabPane && tab) {
                        // Reset panels tabs.
                        tabsPane.forEach((elemtab: Element) => {
                            elemtab.classList.remove("current");
                        });
                        tabs.forEach((elemtab: Element) => {
                            elemtab.classList.remove("current");
                        });
                    }
                    tabPane?.classList.add("current");
                    tab?.classList.add("current");
                    card?.classList.add("active");
                    button?.classList.add("active");

                    elem.removeAttribute("style");
                    elem.classList.add("active");
                }
            });
        });
    }

    if (allCards.length > 1) {
        allCards.forEach((cardParking: any) => {
            const cardEvent = cardParking.querySelector(".card-parking__visualize") as HTMLElement,
                id = cardParking.getAttribute("data-card-id");
            cardEvent?.addEventListener("click", function (e: MouseEvent) {
                let elem = e.target as HTMLElement,
                    targetParking = document.querySelector(`[data-parking]#${id}`) as HTMLElement;

                if (elem.classList.contains("active")) {
                    cleanCards(allCards);
                    resetGrey(parkingsPlan);
                    cardParking.classList.remove("active");
                    elem.classList.remove("active");
                } else {
                    cleanCards(allCards);
                    resetGrey(parkingsPlan);
                    cardParking.classList.add("active");
                    elem.classList.add("active");
                    // On the map.
                    setGrey(parkingsPlan);
                    targetParking.removeAttribute("style");
                    targetParking.classList.add("active");
                    allCards = document.querySelectorAll(".card");
                    // Scroll (mobile and desk).
                    const yOffset = -40;
                    const y = tabs_parkings.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: y, behavior: "smooth" });
                }
            });
        });
    }
}
