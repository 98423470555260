import { Calendar } from "fullcalendar";
import { calendarOptions } from "../Calendar";
import { manageSearchEvents, manageCalendarEvents, manageFilterEvents, manageEvents } from "./process";
import "virtual-select-plugin/dist/virtual-select.min.js";
import "virtual-select-plugin/dist/virtual-select.min.css";
import { autocompleterRequest } from "./autocompleter";
import { initAllDatepickers } from "./datepicker";
import { Destination, Airline, Charterer } from "../../utils/types";
import { showTakeoffPanel, showLandingPanel } from "./action";
import { ui } from "../../utils/ui";

const Search = async (airlines: Airline[], destinations: Destination[], charterers: Charterer[]) => {
    if (!ui?.search?.form || !ui?.calendar) return;

    /**
     * Création du calendrier
     * ********************************
     */
    const calendar = new Calendar(ui.calendar, calendarOptions);
    if (!calendar) return;

    /**
     * Gestion des évènements de la recherche
     * ********************************
     */
    manageSearchEvents(calendar, destinations);

    /**
     * Gestion des évènements du calendrier
     * ********************************
     */
    manageCalendarEvents(calendar, destinations, airlines, charterers);

    /**
     * Gestion des évènements du filtre
     * ********************************
     */
    manageFilterEvents(calendar, destinations, airlines, charterers);

    /**
     * Gestion des multiples autres interactions
     * ********************************
     */
    manageEvents();

    /**
     * Trigger Autocomplete country.
     * ********************************
     */
    autocompleterRequest("country", ui?.search?.fields?.country);

    /**
     * Init panels
     * *******************************
     */
    initAllDatepickers();
};

export { Search };
