const ui = {
    module: document.querySelector(".proflights") as HTMLElement,
    loader: document.querySelector(".proflights .loader") as HTMLElement,
    map: document.querySelector(".proflights .map") as HTMLElement,
    calendar: document.querySelector(".proflights .calendar") as HTMLElement,
    list: document.querySelector(".proflights .list") as HTMLElement,
    counter: document.querySelector(".proflights .counter .value") as HTMLElement,
    viewMode: {
        element: document.querySelector(".proflights .view-mode") as HTMLUListElement,
        options: document.querySelectorAll(".proflights [data-proflights-display-option]") as NodeListOf<HTMLLIElement>,
        list: document.querySelector('.proflights [data-proflights-display-option="list"]') as HTMLLIElement,
        map: document.querySelector('.proflights [data-proflights-display-option="map"]') as HTMLLIElement,
        calendar: document.querySelector('.proflights [data-proflights-display-option="calendar"]') as HTMLLIElement,
    },
    flightsPanel: {
        element: document.querySelector(".proflights .flights-panel") as HTMLElement,
        date: document.querySelector(".proflights .flights-panel .panel__title") as HTMLElement,
        flights: document.querySelector(".proflights .flights-panel .panel__body") as HTMLElement,
        actions: {
            close: document.querySelector(
                '.proflights .flights-panel button[name="close-flights-panel"]'
            ) as HTMLButtonElement,
        },
    },
    search: {
        form: document.querySelector(".proflights .calendar-form") as HTMLFormElement,
        actions: {
            submit: document.querySelector(".proflights .btn--submit") as HTMLButtonElement,
            reset: document.querySelector('.proflights [data-drupal-selector="edit-reset"]') as HTMLButtonElement,
            clearFrom: document.querySelector(".proflights .clear-from") as HTMLElement,
            clearTo: document.querySelector(".proflights .clear-to") as HTMLElement,
        },
        fields: {
            country: document.querySelector('.proflights input[name="country"]') as HTMLInputElement,
            destinations: document.querySelector(".proflights .destination-selector") as
                | HTMLSelectElement
                | HTMLInputElement,
            from: document.querySelector('.proflights input[name="from"]') as HTMLInputElement,
            to: document.querySelector('.proflights input[name="to"]') as HTMLInputElement,
            departureDays: document.querySelector('.proflights select[name="departure-days"]') as HTMLSelectElement,
            returnDays: document.querySelector('.proflights select[name="return-days"]') as HTMLSelectElement,
            hiddenCountry: document.querySelector('.proflights input[name="hidden-country"]') as HTMLInputElement,
            hiddenAirports: document.querySelector('.proflights input[name="hidden-airports"]') as HTMLInputElement,
            hiddenFrom: document.querySelector('.proflights input[name="hidden-from"]') as HTMLInputElement,
            hiddenTo: document.querySelector('.proflights input[name="hidden-to"]') as HTMLInputElement,
        },
    },
    filter: {
        form: document.querySelector(".proflights .filter") as HTMLFormElement,
        actions: {
            open: document.querySelector('.proflights button[name="open-filter"]') as HTMLButtonElement,
            close: document.querySelector('.proflights button[name="close-filter"]') as HTMLButtonElement,
            submit: document.querySelector('.proflights .filter button[name="submit"]') as HTMLButtonElement,
            reset: document.querySelector('.proflights .filter button[name="clear"]') as HTMLButtonElement,
        },
        fields: {
            wrapper: document.querySelector(".proflights .filter .fields"),
            season: Array.from(
                document.querySelectorAll('.proflights .filter input[name="season"]') as NodeListOf<HTMLInputElement>
            ),
            shortStayDestination: document.querySelector(
                '.proflights .filter input[name="short-stay-destination"]'
            ) as HTMLInputElement,
            newDestination: document.querySelector(
                '.proflights .filter input[name="new-destination"]'
            ) as HTMLInputElement,
            airlines: document.querySelector(".proflights .filter .fields .group-airline .options") as HTMLElement,
            charterers: document.querySelector(".proflights .filter .fields .group-charterer .options") as HTMLElement,
            flightType: Array.from(
                document.querySelectorAll(
                    '.proflights .filter input[name="flight-type"]'
                ) as NodeListOf<HTMLInputElement>
            ),
            flightTime: document.querySelector('.proflights .filter input[name="flight-time"]') as HTMLInputElement,
            flightRange: document.querySelector("#range-slider") as HTMLElement,
        },
        flightRangeCounter: document.querySelector(".flight-counter") as HTMLElement,
    },
    allDatePickers: document.querySelectorAll(".js-guidance-datepicker"),
    autoCompleteCities: document.querySelector(".proflights .autocomplete.cities") as HTMLElement,
    takeoffPanel: document.querySelector(".proflights .form-item-from + .panel--when") as HTMLElement,
    landingPanel: document.querySelector(".proflights .form-item-to + .panel--when") as HTMLElement,
};

export { ui };
