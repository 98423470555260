export default function stickyHeader() {
    let scrollpos = window.scrollY;
    const body = document.querySelector("body");

    const add_class_on_scroll = () => body?.classList.add("top-content-is-full-width");
    const remove_class_on_scroll = () => body?.classList.remove("top-content-is-full-width");

    const templateSticky = document.querySelector('.template-sticky') as HTMLElement;
    const topContent = templateSticky?.querySelector('.top-content') as HTMLElement;
    const banner = templateSticky?.querySelector('.banner') as HTMLElement;

    if (templateSticky) {
        window.addEventListener('scroll', function () {
            scrollpos = window.scrollY;

            if (scrollpos > 80) {
                add_class_on_scroll()
            } else {
                remove_class_on_scroll()
            }
        });
    }

    if (banner) {
        window.addEventListener('scroll', function () {
            scrollpos = window.scrollY;

            if (topContent && scrollpos > topContent.getBoundingClientRect().height) {
                banner.style.display = 'block'
            }
            else {
                banner.style.display = 'none'
            }
        });
    }

}
