import autocomplete, { AutocompleteItem } from "autocompleter";
import { getAutocompleteRequest } from "./../../utils/api";
import { ui } from "./../../utils/ui";
import { hideAutocomplete, showAutocomplete } from "./action";
import { getFieldCountry, getHiddenFieldCountry } from "./getter";
import { setFieldDestinations, setHiddenFieldAirports, setHiddenFieldCountry } from "./setter";

const autocompleterRequest = (type: string, elem: HTMLInputElement) => {
    if (!elem) {
        return;
    }
    type MyItem = AutocompleteItem;
    const empty = elem.getAttribute("data-empty") ?? "";
    autocomplete<MyItem>({
        input: elem,
        emptyMsg: empty,
        minLength: 2,
        showOnFocus: false,
        disableAutoSelect: false,
        preventSubmit: true,
        fetch: async (text: string, update: (items: any[]) => void) => {
            text = text.toLowerCase();
            let search = {
                search: text,
                country_id: {
                    list: {
                        nid: getHiddenFieldCountry(),
                    },
                },
            };
            let data = await getAutocompleteRequest(type, search);
            if (data !== undefined) {
                update(data);
            }
        },
        onSelect: async (item: any) => {
            elem.value = item.label;
            // Set values.
            type === "country" && setHiddenFieldCountry(item.nid);
        },
        render: function (item: any): HTMLDivElement | undefined {
            const itemElement = document.createElement("div");
            itemElement.classList.add(item.type);
            if (item.type == "country") {
                itemElement.textContent = item.label;
                const itemSubElement = document.createElement("div");
                itemSubElement.textContent = item.details;
                itemElement.append(itemSubElement);
            }
            return itemElement;
        },
        renderGroup: function (item: any): HTMLDivElement | undefined {
            const itemElement = document.createElement("div");
            itemElement.classList.add("group");
            itemElement.classList.add(item);
            return itemElement;
        },
    });
};

const injectAutocompleter = (destinations: any) => {
    let renderCities = "Aucun résultat disponible.";

    if (destinations.length > 0) {
        renderCities = renderAutocompleter(destinations);
    }

    ui.autoCompleteCities.innerHTML = renderCities;
    // selectAllCities();
};

const renderAutocompleter = (destinations: any) => {
    let html = "";
    destinations.forEach((destination: any) => {
        let iatas = "";
        if (destination.type == "select_all") {
            iatas = "all";
            html += `<div class="js-form-item form-item js-form-type-checkbox ${destination.type}">
            <input data-drupal-selector="destination-check" type="checkbox" id="destination-${iatas}" name="destination-${iatas}" value="${iatas}" class="form-checkbox">
            <label for="destination-${iatas}" class="option">${destination.label}</label>
            </div>`;
        }
        if (destination?.iatas?.length > 0) {
            let ids = [] as any;
            destination.iatas.forEach((i: any) => {
                ids.push(i.tid);
            });
            if (ids.length > 0) {
                iatas = ids.toString();
            }
            html += `<div class="js-form-item form-item js-form-type-checkbox ${destination.type}">
            <input data-drupal-selector="destination-check" type="checkbox" id="destination-${iatas}" name="destination-${iatas}" value="${iatas}" class="form-checkbox">
            <label for="destination-${iatas}" class="option">${destination.label}</label>
            </div>`;
        }
        showAutocomplete();
    });
    return html;
};

const initCompleter = async (elem: HTMLInputElement) => {
    let text = elem.value;
    let search = {
        search: text,
        country_id: {
            list: {
                nid: getHiddenFieldCountry(),
            },
        },
    };
    // let destinations = await getAutocompleteRequest(type, search);
    // if (destinations !== undefined) {
    //     injectAutocompleter(destinations);
    // }
};

export { autocompleterRequest, initCompleter };
