export default function fixedParkingBooking() {
    if (!document.body.classList.contains("page-node-type-page-parkings")) return;

    let scrollpos = window.scrollY;
    const parkingBooking = document.querySelector(".parking-booking") as HTMLElement;

    const add_class_on_scroll = () => parkingBooking?.classList.add("is-fixed");
    const remove_class_on_scroll = () => parkingBooking?.classList.remove("is-fixed");

    window.addEventListener("scroll", function () {
        scrollpos = window.scrollY;
        if (scrollpos > 300) {
            add_class_on_scroll();
        } else {
            remove_class_on_scroll();
        }
    });
}
