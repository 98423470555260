import { data } from "../../utils/data";
import { Flight } from "../../utils/types";
import { ui } from "../../utils/ui";

const setFlightsData = (value: Flight[]) => {
    if (!data?.flights || !value) return;
    data.flights.value = JSON.stringify(value);
};

const setFlightsRangeTime = (value: string) => {
    if (!ui?.filter?.fields?.flightTime || !value) return;
    ui.filter.fields.flightTime.value = value;
};

export { setFlightsData, setFlightsRangeTime };
