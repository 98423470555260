import { Calendar, DatesSetArg, EventClickArg, EventSourceInput } from "fullcalendar";
import { renderDestinationAirlines } from "./render";
import { Airline, Destination, CalendarDate, Flight, Charterer } from "../../utils/types";
import { ui } from "../../utils/ui";
import { formatDate } from "../../utils/functions";
import { getFields } from "../Search/getter";
import { getFlights } from "../../utils/api";
import { getFilterFields } from "../Filter/getter";
import { setFlightsData } from "../Filter/setter";
import { sortFlights } from "../Filter/process";
import { hideLoader } from "../Loader/action";
import { resetCalendar, updateCalendar } from "./action";

const formatFlights = (flights: Flight[], destinations: Destination[]): EventSourceInput => {
    const dates: { [key: string]: CalendarDate } = {};
    const events: EventSourceInput = [];

    Object.values(flights).map((flight: Flight) => {
        const { takeoffDate, destination } = flight;

        if (!dates[takeoffDate]) {
            dates[takeoffDate] = {
                takeoffDate: takeoffDate,
                destinations: [],
            };
        }

        if (!dates[takeoffDate].destinations[destination]) {
            dates[takeoffDate].destinations[destination] = {
                id: destination,
                flights: [],
            };
        }

        dates[takeoffDate].destinations[destination].flights.push(flight);
    });

    Object.values(dates).map((date: CalendarDate) => {
        Object.values(date.destinations).map((destination: Destination) => {
            const name = destinations[destination?.id]?.name;
            const country = destinations[destination?.id]?.country;
            const flightTime = destinations[destination?.id]?.flightTime;
            const takeoffDate = date?.takeoffDate;
            const flights = destination?.flights;
            const airlines = [...new Set(destination?.flights?.map((flight: Flight) => flight?.airline))];

            if (!name || !country || !flightTime || !takeoffDate || !flights || !airlines) return;

            events.push({
                title: name,
                start: takeoffDate,
                extendedProps: {
                    country: country,
                    arrivalAirportIATA: "",
                    flightTime: flightTime,
                    airlines: airlines,
                    flights: flights,
                },
            });
        });
    });

    return events;
};

const syncFlights = async (calendar: Calendar, config: DatesSetArg, destinations: Destination[]) => {
    if (config?.view?.type !== "dayGridMonth") return;

    const viewStart = new Date(config?.view?.activeStart);
    const viewEnd = new Date(config?.view?.activeEnd);

    if (!viewStart || !viewEnd) return;

    const { hiddenFrom, hiddenTo, hiddenAirports } = getFields();

    let from = hiddenFrom ? new Date(hiddenFrom) : new Date();
    let to = hiddenTo && new Date(hiddenTo);

    if (from < viewStart) from = viewStart;
    if (!to || to > viewEnd) to = viewEnd;

    const fromDate = formatDate(from);
    const toDate = formatDate(to);

    if (!fromDate || !toDate) return;

    const flightsResponse = await getFlights(fromDate, toDate, hiddenAirports);
    const flights = flightsResponse?.data?.flights;

    setFlightsData(flights);

    const filterFields = getFilterFields();

    resetCalendar(calendar);

    const filteredFlights = sortFlights(flights, filterFields);

    updateCalendar(calendar, filteredFlights, destinations);

    hideLoader();
};

const populateFlightsPanelDate = (date: Date) => {
    ui.flightsPanel.date.innerHTML = date.toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

const populateDestinationAirlines = (destination: EventClickArg, airlines: Airline[], charterers: Charterer[]) => {
    let renderedDestinationFlights = "Aucun résultat disponible.";

    if (destination?.event?.title && destination?.event?.start && destination?.event?.extendedProps?.airlines) {
        renderedDestinationFlights = renderDestinationAirlines(destination.event, airlines, charterers);
    }

    ui.flightsPanel.flights.innerHTML = renderedDestinationFlights;
};

export { formatFlights, syncFlights, populateFlightsPanelDate, populateDestinationAirlines };
