import { Map, List, Search, ViewMode } from "./features";
import { getDestinations } from "./utils/api";
import { ui } from "./utils/ui";
import { setDefaultDates } from './features/Search/datepicker';

const Proflights = async () => {
    if (!ui.module) return;

    const proflights = await getDestinations();
    const airlines = proflights?.data?.airlines;
    const destinations = proflights?.data?.destinations;
    const totalDestinations = proflights?.meta?.destinations?.total;
    const charterers = proflights?.data?.charterers;

    /**
     * Initialisation de la fonctionnalité "carte"
     * ********************************
     */
    Map(airlines, destinations);

    /**
     * Initialisation de la fonctionnalité "liste"
     * ********************************
     */
    List(airlines, destinations);

    /**
     * Initialisation de la fonctionnalité "recherche / calendrier"
     * ********************************
     */
    Search(airlines, destinations, charterers);

    /**
     * Initialisation de la fonctionnalité "mode d'affichage"
     * ********************************
     */
    ViewMode(totalDestinations);

    /**
     * Set date init.
     * ********************************
     */
    // setDefaultDates();

};

export default Proflights;
