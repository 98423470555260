// @ts-ignore
import { Datepicker } from 'vanillajs-datepicker';
// @ts-ignore
import fr from "vanillajs-datepicker/locales/fr";
// @ts-ignore
import en from "vanillajs-datepicker/locales/en-GB";
// @ts-ignore
import NiceSelect from "nice-select2/src/js/nice-select2";

export default function datePicker(inputs: any) {

    Object.assign(Datepicker.locales, fr, en);

    let datepickers = [] as Array<any>;
    let minDate = {};
    const lang = document.documentElement.lang;
    const today = new Date();
    const entry_time = document.querySelector('[name="data[entry-time]"]') as HTMLInputElement;
    const exit_time = document.querySelector('[name="data[exit-time]"]') as HTMLInputElement;
    const entry_date_data = document.querySelector('[name="data[entry-date]"]') as HTMLInputElement;
    const exit_date_data = document.querySelector('[name="data[exit-date]"]') as HTMLInputElement;

    let entrySelectTime = new NiceSelect(entry_time, {searchable: false});
    let exitSelectTime = new NiceSelect(exit_time, {searchable: false});

    // Check if entry is set.
    const start = document.querySelector('[name="data[entry-date]"]') as HTMLInputElement;

    function getCurrentQuarter(date: Date) {
        let quarter = '00';
        if (date.getMinutes() >= 0 && date.getMinutes() < 15) {
            quarter = '15';
        }
        if (date.getMinutes() >= 15 && date.getMinutes() < 30) {
            quarter = '30';
        }
        if (date.getMinutes() >= 30 && date.getMinutes() < 45) {
            quarter = '45';
        }
        if (date.getMinutes() >= 45 && date.getMinutes() < 60) {
            quarter = '00';
        }
        return quarter;
    }

    if (start.value) {
        minDate = start.value;
    } else {
        minDate = today;
    }

    // Generate.
    inputs.forEach((elem: Element, key: number|string) => {
        let name = elem.getAttribute('name');
        let tag = name?.split('[')?.pop()?.split(']')[0];
        if (tag) {
            key = tag;
        }
        // @ts-ignore
        datepickers[key] = new Datepicker(elem, {
            format: 'D dd M yy',
            minDate: minDate,
            language: lang
        });
    });

    // Manage exit date after start date.
    if (inputs.length) {
        inputs.forEach((dateInputEl: HTMLInputElement) => {
            dateInputEl.addEventListener( 'changeDate', (event: Event) => {
                let currentElem = event.target as HTMLInputElement;
                // Close when select.
                let name = currentElem.getAttribute('name');
                let tag = name?.split('[')?.pop()?.split(']')[0];

                if (tag) {
                    // @ts-ignore
                    datepickers[tag].hide();
                }
                if (name && name.includes('entry-date-dp')) {
                    let options = entry_time.querySelectorAll('option');
                    options.forEach((option: HTMLOptionElement) => {
                        option.removeAttribute('disabled');
                        option.removeAttribute('selected');
                    });
                    // @ts-ignore
                    if (event.detail.date) {
                        // @ts-ignore
                        let entry_as_date = event.detail.date;
                        // @ts-ignore
                        let parsedMin = new Date(minDate);
                        if (parsedMin.toDateString() == entry_as_date.toDateString()) {
                            let timeCurrent = today.getHours()
                            let quarterCurrent = getCurrentQuarter(today);
                            if (quarterCurrent == '00') {
                                timeCurrent = timeCurrent + 1;
                            }
                            options.forEach((option: HTMLOptionElement) => {

                                let val = option.value;
                                if (val !== "default") {
                                    const array = val.split(':');
                                    if (parseInt(array[0]) < timeCurrent + 1) {
                                        option.setAttribute('disabled', 'disabled');
                                    }
                                    if (parseInt(array[0]) == timeCurrent+1 && parseInt(array[1]) < parseInt(quarterCurrent)) {
                                        option.setAttribute('disabled', 'disabled');
                                    }
                                }

                                if (val == `${timeCurrent + 1}:${quarterCurrent}`) {
                                    option.setAttribute('selected', 'selected');
                                }
                            });
                            entrySelectTime.update();
                        }
                    }

                    // @ts-ignore
                    let entry = datepickers['entry-date-dp'].getDate();
                    entry_date_data.value = entry.toLocaleDateString("fr");
                    // @ts-ignore
                    let parsedMin = new Date(minDate * 1000);
                    if (entry.toDateString() == parsedMin.toDateString() && parsedMin.getHours() >= 17) {
                        minDate = entry.setDate(entry.getDate() + 1)
                    }
                    // @ts-ignore
                    if (datepickers['exit-date-dp']) {
                        // @ts-ignore
                        datepickers['exit-date-dp'].setOptions({
                            minDate: entry
                        });
                    }
                }

                if (name && name.includes('exit-date-dp')) {

                    let options = exit_time.querySelectorAll('option');
                    options.forEach((option: HTMLOptionElement) => {
                        option.removeAttribute('disabled');
                        option.removeAttribute('selected');
                    });

                    // @ts-ignore
                    if (event.detail.date) {
                        // @ts-ignore
                        let exit_as_date = event.detail.date;

                        if (today.toDateString() == exit_as_date.toDateString()) {
                            let timeCurrent = today.getHours()
                            let quarterCurrent = getCurrentQuarter(today);
                            if (quarterCurrent == '00') {
                                timeCurrent = timeCurrent + 1;
                            }
                            options.forEach((option: HTMLOptionElement) => {
                                let val = option.value;
                                if (val !== "default") {
                                    const array = val.split(':');
                                    if (parseInt(array[0]) < timeCurrent + 2) {
                                        option.setAttribute('disabled', 'disabled');
                                    }
                                    if (parseInt(array[0]) == timeCurrent+2 && parseInt(array[1]) < parseInt(quarterCurrent)) {
                                        option.setAttribute('disabled', 'disabled');
                                    }
                                }
                                if (val == `${timeCurrent + 2 }:${quarterCurrent}`) {
                                    option.setAttribute('selected', 'selected');
                                }
                            });
                            exitSelectTime.update();
                        }

                        // @ts-ignore
                        let exit = datepickers['exit-date-dp'].getDate();
                        exit_date_data.value = exit.toLocaleDateString("fr");

                    }
                }
                currentElem.setAttribute('value', currentElem.value);

                // update select.
                entrySelectTime.update();
                exitSelectTime.update();

            });
        });
    }

}
