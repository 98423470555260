import { ui } from "../../utils/ui";
// @ts-ignore
import NiceSelect from "nice-select2/src/js/nice-select2";

/**
 * Style select
 */
let departureDaysSelect: any = {};
let returnDaysSelect: any = {};
if (ui.search.fields.departureDays) {
    departureDaysSelect = new NiceSelect(ui.search.fields.departureDays, {searchable: false});
}
if (ui.search.fields.returnDays) {
    returnDaysSelect = new NiceSelect(ui.search.fields.returnDays, {searchable: false});
}

const showFilterPanel = () => ui?.filter?.form && (ui.filter.form.hidden = false);

const hideFilterPanel = () => ui?.filter?.form && (ui.filter.form.hidden = true);

const resetFilterFields = () => {
    ui?.filter?.form?.reset();
    departureDaysSelect.update();
    returnDaysSelect.update();
}

export { showFilterPanel, hideFilterPanel, resetFilterFields, departureDaysSelect, returnDaysSelect };
