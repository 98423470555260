export default function parkingBookingMobile() {
    let scrollpos = window.scrollY;
    const parkingBookingMobile = document.querySelector(".parking-booking-mobile") as HTMLElement;
    const mobileBookingButton = document.querySelector(".mobile-booking__button") as HTMLElement;
    const parkingBooking = document.querySelector(".parking-booking") as HTMLElement;
    const parkingBookingMobileClose = document.querySelector(".parking-booking__close") as HTMLElement;


    if(parkingBookingMobile) {
        const add_class_on_scroll = () => parkingBookingMobile?.classList.add("is-fixed");
        const remove_class_on_scroll = () => parkingBookingMobile?.classList.remove("is-fixed");

        window.addEventListener('scroll', function() {
            scrollpos = window.scrollY;
            if (scrollpos > 300) { add_class_on_scroll() }
            else { remove_class_on_scroll() }
        })


        mobileBookingButton?.addEventListener('click', (event) => {
            event.preventDefault();
            parkingBooking?.classList.add('is-visible');
        });

        parkingBookingMobileClose?.addEventListener('click', (event) => {
            event.preventDefault();
            parkingBooking?.classList.remove('is-visible');
        });
    }
}
