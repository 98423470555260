import { ui } from "../../utils/ui";
import rangeSlider from 'range-slider-input';
import 'range-slider-input/dist/style.css';
import { setFlightsRangeTime } from './setter';

let filterSliderElement = {};

const createSliderFilters = () => {
    if (!ui?.filter?.fields?.flightRange) return;
    filterSliderElement = rangeSlider(ui.filter.fields.flightRange, {
        min: 0,
        max: 12,
        step: 1,
        value: [0, 0],
        thumbsDisabled: [true, false],
        onInput: function (value: [number,number], userInteraction: boolean)  {
            if (userInteraction) {
                if (value[1] > 0) {
                    let minutes = value[1] * 60;
                    setFlightsRangeTime(minutes.toString());
                    ui.filter.flightRangeCounter.textContent = `${value[1].toString()}h`;
                }
                else {
                    setFlightsRangeTime('0');
                    ui.filter.flightRangeCounter.textContent = '';
                }
            }
        }
    });
}

export { createSliderFilters, filterSliderElement };
