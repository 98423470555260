import frLocale from "@fullcalendar/core/locales/fr";
import { CalendarOptions } from "fullcalendar";

export const calendarOptions: CalendarOptions = {
    locale: frLocale,
    initialView: "dayGridMonth",
    height: "auto",
    eventOrder: "title",
    dayMaxEventRows: true,
    headerToolbar: {
        start: "prev",
        center: "title",
        end: "next",
    },
    dayHeaderFormat: {
        weekday: "long",
    },
    views: {
        dayGridMonth: {
            dayMaxEventRows: 4,
            moreLinkClick: "popover",
        },
    },
};
