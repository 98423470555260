import autocomplete, {AutocompleteItem} from "autocompleter";
// @ts-ignore
import serialize from 'form-serialize';
import rangeSlider from 'range-slider-input';
import 'range-slider-input/dist/style.css';
// @ts-ignore
import { Datepicker } from 'vanillajs-datepicker';
// @ts-ignore
import fr from "vanillajs-datepicker/locales/fr";
// @ts-ignore
import en from "vanillajs-datepicker/locales/en-GB";
// @ts-ignore
import NiceSelect from "nice-select2/src/js/nice-select2";

export default function guidance() {

    const store = document.querySelector('.store') as HTMLElement;

    Object.assign(Datepicker.locales, fr, en);
    const translations = window.translations;

    const guidance = document.getElementById('guidance') as HTMLElement;
    const nte_tid = guidance?.dataset.ntetid;
    const target = document.querySelector('[data-target="list"]') as HTMLElement;
    const form = guidance?.querySelector('.guidance-form') as HTMLElement;
    const where = form?.querySelector('[name="where"]') as HTMLInputElement;
    const when = form?.querySelector('[name="when"]') as HTMLInputElement;
    const budget = form?.querySelector('[name="budget"]') as HTMLInputElement;
    const range = form?.querySelector('#range-slider') as HTMLElement;
    const lang = document.documentElement.lang;
    const urlPage = `/${lang}/moteur-de-guidance`;
    const loader = '<div class="ajax-progress ajax-progress-fullscreen">&nbsp;</div>';
    let parser = new DOMParser();
    const allPrices = form?.querySelector('.all-prices') as HTMLButtonElement;
    const panelBudget = form?.querySelector('.panel--budget') as HTMLElement;
    const panelWhen = form?.querySelector('.panel--when') as HTMLElement;
    const durationsCheckboxes = form?.querySelectorAll('#edit-duration .form-checkbox') as NodeListOf<HTMLInputElement>;
    const monthsCheckboxes = form?.querySelectorAll('#edit-months .form-checkbox') as NodeListOf<HTMLInputElement>;
    const nextPanel = form?.querySelector('.open-next-panel') as HTMLElement;
    const firstPanel = form?.querySelector('.first-panel') as HTMLElement;
    const secondPanel = form?.querySelector('.second-panel') as HTMLElement;

    // Api
    const counter = guidance?.querySelector('.counter--destinations em') as HTMLElement;

    // Dates.
    const today = new Date();
    let minDate = new Date(today.setDate(today.getDate() + 2));
    const datePickers = form?.querySelectorAll('.js-guidance-datepicker');
    const cheapest = form?.querySelector('.cheapest-dates') as HTMLElement;
    const selectDepartureDay = form?.querySelector('[name="departure-days"]') as HTMLSelectElement;
    const selectReturnDay = form?.querySelector('[name="return-days"]') as HTMLSelectElement;

    let niceDeparture = {} as any;
    let niceReturn = {} as any;

    if (selectDepartureDay && selectReturnDay) {
        niceDeparture = new NiceSelect(selectDepartureDay, {searchable: false});
        niceReturn = new NiceSelect(selectReturnDay, {searchable: false});
    }

    // Fields for search.
    let tos = form?.querySelector('[name="hiddens[tos]"]') as HTMLInputElement;
    let country = form?.querySelector('[name="hiddens[country]"]') as HTMLInputElement;
    let region_airports = form?.querySelector('[name="hiddens[regionAirports]"]') as HTMLInputElement;
    const maxPrice = form?.querySelector('[name="hiddens[maxPrice]"]') as HTMLInputElement;
    const sliderRange = form?.querySelector('[name="slider"]') as HTMLInputElement;
    const departureDate = form?.querySelector('[name="hiddens[departureDate]"]') as HTMLInputElement;
    const departureDays = form?.querySelector('[name="hiddens[departureDays]"]') as HTMLInputElement;
    const departureMonths = form?.querySelector('[name="hiddens[departureMonths]"]') as HTMLInputElement;
    const durationMax = form?.querySelector('[name="hiddens[durationMax]"]') as HTMLInputElement;
    const durationMin = form?.querySelector('[name="hiddens[durationMin]"]') as HTMLInputElement;
    const returnDays = form?.querySelector('[name="hiddens[returnDays]"]') as HTMLInputElement;
    const returnMonths = form?.querySelector('[name="hiddens[returnMonths]"]') as HTMLInputElement;

    // Maps
    const backToThumb = document.querySelector('.display-type--cards') as HTMLElement;

    // Filters.
    const panelFilters = guidance?.querySelector('.guidance-filters') as HTMLElement;
    const underlay = panelFilters?.querySelector('.underlay') as HTMLElement;
    const btnFilters = guidance?.querySelector('.open-filters') as HTMLElement;
    const closeFilters = guidance?.querySelector('.close-filters') as HTMLElement;
    const filters = guidance?.querySelector('.guidance-filters-form') as HTMLElement;
    const filterTime = filters?.querySelector('#range-slider-filter') as HTMLElement;
    const filterTimeInput = filters?.querySelector('[name="slider"]') as HTMLInputElement;
    const filterTimeInputShow = filters?.querySelector('[name="slider_show"]') as HTMLInputElement;

    // Filters form elem.
    const filtersCheckboxes = filters?.querySelectorAll('.form-checkbox');
    const btnValidFilters = filters?.querySelector('[data-twig-suggestion="filtered"]') as HTMLElement;
    const btnResetFilters = filters?.querySelector('[data-twig-suggestion="reset"]') as HTMLElement;

    // For menu search mobile
    const mobileSearch = guidance?.querySelector('.mobile-search') as HTMLElement;
    const formContainer = guidance?.querySelector('.page-guidance__form') as HTMLElement;
    const formTitles = formContainer?.querySelector('.page-guidance__form-titles') as HTMLElement;
    const formTitle = formTitles?.querySelector('.page-guidance__form-title') as HTMLElement;
    const formSubtitle = formTitles?.querySelector('.page-guidance__form-subtitle') as HTMLElement;
    const formItemWhere = form?.querySelector('.form-item-where') as HTMLElement;
    const formItemWhen = form?.querySelector('#edit-wrapper-when') as HTMLElement;
    const formItemBudget = form?.querySelector('#edit-wrapper-budget') as HTMLElement;

    function insertLoader() {
        // Loader.
        if (target) {
            target.innerHTML = '';
            let load = parser.parseFromString(loader, 'text/html');
            let loading = load.querySelector('.ajax-progress') as HTMLElement;
            document.body.append(loading);
        }
    }

    // Reinit form.
    function reinitForm() {
        // Reinit.
        tos.value = '';
        country.value = '';
        region_airports.value = '';
    }

    // Reinit form.
    function reinitAll() {
        reinitForm();
        const isform = form as HTMLFormElement;
        isform.reset();
    }

    // Reset dates.
    function resetDates() {
        departureDate.value = '';
        departureDays.value = '';
        departureMonths.value = '';
        durationMax.value = '';
        durationMin.value = '';
        returnDays.value = '';
        returnMonths.value = '';
        when.value = '';
        when.removeAttribute('title');
        Object.keys(when.dataset).forEach(key => {
            if (key != 'drupalSelector') {
                when.removeAttribute(`data-${key}`);
            }
        });

        datePickers.forEach((elem: Element, key: number|string) => {
            const currentElem = elem as HTMLElement;
            let name = currentElem.dataset.type;
            let tag = name?.split('[')?.pop()?.split(']')[0];
            if (tag) {
                key = tag;
            }
            // @ts-ignore
            datePickers[key].setDate({clear: true});
        });
        durationsCheckboxes.forEach((checkbox: HTMLInputElement) => {
            checkbox.checked = false;
        });
        monthsCheckboxes.forEach((checkbox: HTMLInputElement) => {
            checkbox.checked = false;
        });
        selectDepartureDay.selectedIndex = 0;
        selectReturnDay.selectedIndex = 0;
        niceDeparture.update();
        niceReturn.update();

    }

    // CheckUpdateMonths
    function checkUpdateMonths() {
        let searchMonth = '';
        let stringDataMonth = '';
        monthsCheckboxes.forEach((input: any) => {
            if (input.checked) {
                const label = input.nextElementSibling?.textContent;
                if (searchMonth == '') {
                    searchMonth += `${input.value}`;
                    stringDataMonth += `${label}`;
                }
                else {
                    searchMonth += `,${input.value}`;
                    stringDataMonth += `, ${label}`;
                }
            }
            departureMonths.value = searchMonth;
            when.setAttribute('data-monthsdeparture', stringDataMonth);
        });
    }

    // Returns an array of dates between the two dates
    function getDates (startDate: Date, endDate: Date, array: any) {
        const dates = [];
        let currentDate = startDate;
        const addDays = function (days: number) {
            // @ts-ignore
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
        while (currentDate <= endDate) {
            let currentIso = currentDate.toISOString().split('T')[0];
            if (!array.includes(currentIso)) {
                dates.push(currentDate);
            }
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    }

    // Transforms dates.
    function dateTransform(date: string): string {
        let array = date.split('-');
        let returnValue = `${array[0]}-${array[1].toString().padStart(2, '0')}-${array[2].toString().padStart(2, '0')}`;
        let parsedDate = Datepicker.parseDate(returnValue, 'yyyy-mm-dd');
        return parsedDate;
    }

    // Update flight.
    function updateFlights(code_iata_arrival: string) {
        let resultsSchedule = {};
        fetch(`/${lang}/ajax/flights/list/rest/${nte_tid}/${code_iata_arrival}/all`)
            .then(response => response.json())
            .then(function (json) {
                resultsSchedule = json;
                let flightDates = [] as any;
                Object.values(resultsSchedule).forEach((schedule: any) => {
                    flightDates.push(dateTransform(schedule.field_takeoff_date));
                });
                // Disabled dates.
                //const datesDisabled = getDates(new Date(), new Date(today.getFullYear()+1, 11, 31), flightDates);
                flightDates.forEach((date: string) => {
                    let hasFlight: NodeListOf<Element> = document.querySelectorAll(`[data-date="${date}"]`)
                    hasFlight.forEach((elem: Element) => {
                        if (elem && !elem.classList.contains('next')) {
                            elem.classList.add('hasVol');
                        }
                    })
                });

            })
            .catch(error => console.log(error.message)) // logs any error from the promise
    }

    // Autocomplete destination.
    function autoCompleteWhere(elem: HTMLInputElement) {
        type MyItem = AutocompleteItem;
        const empty = elem.getAttribute('data-empty') ?? '';
        autocomplete<MyItem>({
            input: elem,
            emptyMsg: empty,
            minLength: 3,
            showOnFocus: true,
            disableAutoSelect: true,
            fetch: (text: string, update: (items: any[]) => void) => {
                let resultsAjax = {};
                text = text.toLowerCase();
                let search = {
                    search: text,
                };
                fetch(`/${lang}/ajax/guidance/autocomplete`, {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(search)
                })
                    .then(response => response.json())
                    .then(function (json) {
                        update(json);
                    })
                    .catch(error => console.log(error.message)) // logs any error from the promise
            },
            onSelect: async (item: any) => {
                reinitForm();

                elem.value = item.label;

                // Reset flights.
                const allDays = document.querySelectorAll('.datepicker-main .days .day');
                allDays.forEach((elem: Element) => {
                    const day = elem as HTMLElement;
                    day.classList.remove('hasVol');
                });
                let resultsSchedule = {};

                // Set values in form.
                if (item.iatas) {
                    let list = [] as any;
                    item.iatas.forEach(function(iata: any) {
                        list.push(iata.name);
                        updateFlights(iata.tid);
                        tos.setAttribute('data-tos', iata.tid);
                    });
                    tos.value = list;
                }
                if (item.iatas_country) {
                    country.value = item.iatas_country;
                    tos.value = '';
                    tos.setAttribute('data-tos', '');
                }
                if (item.region_airports) {
                    tos.value = '';
                    tos.setAttribute('data-tos', '');
                    let list = [] as any;
                    item.region_airports.forEach(function(iata: any) {
                        list.push(iata[0].name);
                    });
                    region_airports.value = list.join();
                }
                // Anywhere button.
                if (item.type == 'anywhere') {
                    reinitForm();
                }

                // Close pan.
                /*
                const closeWherePanMob = document.querySelector('.search-pan--where .close-pan') as HTMLElement;
                if (closeWherePanMob) {
                    closeWherePanMob.click();
                }*/

            },
            render: function (item: any): HTMLDivElement | undefined {
                const itemElement = document.createElement("div");
                itemElement.classList.add(item.type);
                if (item.type != 'anywhere') {
                    itemElement.textContent = item.label;
                    const itemSubElement = document.createElement("div");
                    itemSubElement.textContent = item.details;
                    itemElement.append(itemSubElement);
                }
                if (item.type == 'anywhere') {
                    const itemSubElement = document.createElement("span");
                    const iconSubElement = '<span class="icon"><svg width="24" height="24" viewBox="0 0 24 24" preserveAspectRatio="xMinYMin"> <use xlink:href="#img-travel-explore"></use> </svg></span>';
                    itemSubElement.classList.add('btn','btn--ghost');
                    itemSubElement.innerHTML = iconSubElement + item.label;
                    itemElement.append(itemSubElement);
                }
                return itemElement;
            },
            renderGroup: function (item: any): HTMLDivElement | undefined {
                const itemElement = document.createElement("div");
                itemElement.classList.add('group');
                itemElement.classList.add(item);
                return itemElement;
            },
            preventSubmit: true
        });
    }

    // Reset when field.
    function resetWhenField() {
        when.value = '';
        when.removeAttribute('title');
        let newValue = '';
        if (when.dataset.monthsdeparture) {
            newValue += `${when.dataset.monthsdeparture}`;
        }
        if (when.dataset.departure) {
            newValue += `${translations.departure} ${when.dataset.departure}`;
        }
        if (when.dataset.duration) {
            if (newValue) {
                newValue += `, ${when.dataset.duration}`;
            } else {
                newValue += `${when.dataset.duration}`;
            }
        }
        if (when.dataset.departuredays) {
            if (newValue) {
                newValue += `, ${when.dataset.departuredays}`;
            } else {
                newValue += `${when.dataset.departuredays}`;
            }
        }
        if (when.dataset.returndays) {
            if (newValue) {
                newValue += `, ${when.dataset.returndays}`;
            } else {
                newValue += `${when.dataset.returndays}`;
            }
        }
        when.value = newValue;
        when.setAttribute('title', newValue);
    }

    // Get fields for search.
    function searchFieldsPopulate(datePickers: any) {
        let queryString = window.location.search;
        if (queryString && !queryString.includes('departureDate')) {
            const search = localStorage.getItem('search');
            if (search) {
                // @ts-ignore
                const originals = Object.fromEntries(new URLSearchParams(search));
                const keys = Object.keys(originals);
                keys.forEach((key: string) => {
                    const elemInput = form?.querySelector(`.form-text[name="${key}"]`) as HTMLInputElement;
                    const elemSelect = form?.querySelector(`select[name="${key}"]`) as HTMLSelectElement;
                    const elemCheckbox = form?.querySelector(`.form-checkbox[name="${key}"]`) as HTMLInputElement;
                    const elemHidden = form?.querySelector(`[type="hidden"][name="${key}"]`) as HTMLInputElement;

                    if (elemInput) {
                        elemInput.value = originals[key];
                    }
                    if (elemSelect) {
                        elemSelect.value = originals[key];
                    }
                    if (elemCheckbox) {
                        elemCheckbox.checked = true;
                    }
                    if (elemHidden) {
                        if (!key.includes('form')) {
                            elemHidden.value = originals[key];
                        }
                    }
                });
                if (keys.includes('hiddens[departureDate]')){
                    let date = Datepicker.parseDate(originals['hiddens[departureDate]'], 'yyyy-mm-dd');
                    datePickers['start'].setDate(date);
                }
            }
        }
        else {
            if (queryString.includes('departureDate=cheapest')) {
                const btnCheapest = document.querySelector('.btn.cheapest-dates') as HTMLElement;
                if (btnCheapest) {
                    let btnText = btnCheapest.textContent;
                    if (btnText) {
                        btnText = btnText.trim();
                        queryString = `${queryString}&when=${encodeURIComponent(btnText)}`;
                    }
                }
            }
            if (queryString) {
                // @ts-ignore
                const originals = Object.fromEntries(new URLSearchParams(queryString));
                const keys = Object.keys(originals);
                keys.forEach((key: string) => {
                    const elemInput = form?.querySelector(`.form-text[name="${key}"]`) as HTMLInputElement;
                    const elemSelect = form?.querySelector(`select[name="${key}"]`) as HTMLSelectElement;
                    const elemCheckbox = form?.querySelector(`.form-checkbox[name="${key}"]`) as HTMLInputElement;
                    const elemHidden = form?.querySelector(`[type="hidden"][name="${key}"]`) as HTMLInputElement;

                    if (elemInput) {
                        elemInput.value = originals[key];
                    }
                    if (elemSelect) {
                        elemSelect.value = originals[key];
                    }
                    if (elemCheckbox) {
                        elemCheckbox.checked = true;
                    }
                    if (elemHidden) {
                        if (!key.includes('form')) {
                            elemHidden.value = originals[key];
                        }
                    }
                });
                if (keys.includes('hiddens[departureDate]')){
                    let date = Datepicker.parseDate(originals['hiddens[departureDate]'], 'yyyy-mm-dd');
                    datePickers['start'].setDate(date);
                }
            }
        }
    }

    // Close when panel.
    function closeWhen() {
        panelWhen.removeAttribute('style');
        firstPanel.removeAttribute('style');
        secondPanel.setAttribute('hidden', '');
    }

    // Serialize url.
    function serializeUrl(obj: any) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    function paginate() {
        const destinationBlock = document.querySelector('.destination-by') as HTMLElement;
        let pages = document.querySelectorAll('.destination-by .group');

        pages.forEach((page: Element, key: number) =>{
            if (key > 1) {
                page.setAttribute('style', 'display:none;');
            }
        });

        let num = null;
        let counter = document.querySelector('.counter--destinations em') as HTMLElement;
        if (pages.length > 1) {
            let button = `<div class="more-destinations"><p>${translations.pagescount}</p><div class="range"><span></span></div><button class="btn btn--ghost show-next">${translations.more}</button></div>`;
            let doc = parser.parseFromString(button, 'text/html');
            let insert = doc.querySelector('.more-destinations') as HTMLElement;
            destinationBlock.append(insert);
            let pagesVisible = document.querySelectorAll('.destination-by .group:not([style])');
            let destinationBlockMaxCounter = destinationBlock.querySelector('p em:last-child') as HTMLElement;
            let destinationBlockCurrentCounter = destinationBlock.querySelector('p em:first-child') as HTMLElement;
            let range = destinationBlock.querySelector('.range span') as HTMLElement;
            let percent = pagesVisible.length * 100 / pages.length;

            if (range) {
                range.setAttribute('style', `width:${percent}%`);
            }
            if (counter && destinationBlockMaxCounter) {
                destinationBlockMaxCounter.textContent = counter.textContent;
            }
            if (pagesVisible && destinationBlockCurrentCounter && counter) {
                if (counter.textContent) {
                    num = parseInt(counter.textContent);
                }
                if (num && num <= 14) {
                    destinationBlockCurrentCounter.textContent = counter.textContent;
                } else {
                    destinationBlockCurrentCounter.textContent = `${pagesVisible.length * 7}`;
                }
            }
            let eventButton = destinationBlock.querySelector('.show-next') as HTMLElement;
            let zoneButton = destinationBlock.querySelector('.more-destinations') as HTMLElement;
            eventButton.addEventListener('click', () => {
                let morePages = document.querySelectorAll('.destination-by .group[style]');
                morePages.forEach((page: Element, key: number) =>{
                    if (key == 0) {
                        page.removeAttribute("style");
                    }
                });

                // Changes load and count.
                pagesVisible = document.querySelectorAll('.destination-by .group:not([style])');
                percent = pagesVisible.length * 100 / pages.length;
                if (range) {
                    range.setAttribute('style', `width:${percent}%`);
                }
                if (pagesVisible && destinationBlockCurrentCounter) {
                    destinationBlockCurrentCounter.textContent = `${pagesVisible.length * 7}`;
                }

                if (morePages.length == 1) {
                    zoneButton.remove();
                }
            });
        }

        if (counter) {
            if (counter.textContent) {
                num = parseInt(counter.textContent);
            }
            let parentCounter = counter.closest('.counter--destinations') as HTMLElement;
            let span = parentCounter.querySelector('span') as HTMLElement;
            if (parentCounter && num && num > 1) {
                span.removeAttribute('hidden');
            }
            else if (parentCounter && num && num <= 1) {
                span.setAttribute('hidden', '');
            }
        }

    }

    // Get json from api optionway.
    function fetchFromApiOptionway(params: any, store: any) {
        const api_optionway_response = document.querySelector('.api_optionway_response') as HTMLElement;
        if (api_optionway_response) {
            api_optionway_response.remove();
        }
        fetch(`/${lang}/ajax/guidance/results`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(params)
        }).then(response => response.json())
            .then(function (json) {
                let storeElem = '<div class="store-elem api_optionway_response"></div>';
                let doc = parser.parseFromString(storeElem, 'text/html');
                let insert = doc.querySelector('.api_optionway_response') as HTMLElement;
                insert.innerHTML = JSON.stringify(json);
                store.append(insert);
                localStorage.setItem('api_optionway_response', JSON.stringify(json));
            });
    }

    // Get twig response.
    function fetchTwigResponse(apiOptionwayResponseString: string, filters: string|null, counters: boolean|null) {
        let bodyOfPost;
        let newData = {
            'dataApi' : apiOptionwayResponseString,
            'filters' : '',
            'counters': counters,
        };
        // Add filters if data exists.
        if (filters) {
            newData = {
                'dataApi' : apiOptionwayResponseString,
                'filters' : filters,
                'counters': counters,
            }
        }
        // Transform to string for request.
        bodyOfPost = JSON.stringify(newData);
        if (counters) {
            let load = parser.parseFromString(loader, 'text/html');
            let loading = load.querySelector('.ajax-progress') as HTMLElement;
            let isLoading = btnValidFilters?.querySelector('.ajax-progress') as HTMLElement;
            if (!isLoading) {
                btnValidFilters.append(loading);
            }

            fetch(`/${lang}/ajax/guidance/twig`, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: bodyOfPost
            }).then(response => response.json())
                .then(function (json) {
                    let translation = translations.show_destinations;
                    translation = translation.replace('#counter', json);
                    if(btnValidFilters) {
                        btnValidFilters.textContent = translation;
                    }
                });
        }
        else {
            fetch(`/${lang}/ajax/guidance/twig`, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: bodyOfPost
            }).then(function (response) {
                return response.text();
            }).then(function (html) {
                // Empty html.
                if (target) {
                    target.innerHTML = '';
                }
                // Convert the HTML string into a document object
                let doc = parser.parseFromString(html, 'text/html');
                let insert = doc.querySelector('.destination-by') as HTMLElement;
                if (insert) {
                    let destis = insert.querySelectorAll('.destination');
                    if (counter) {
                        counter.textContent = String(destis.length);
                    }
                    if (destis.length == 0) {
                        insert.innerHTML = `<p class="no-results">${translations.woops}</p>`;
                    }
                }

                if (target) {
                    target.append(insert);
                    const loader = document.querySelector('body>.ajax-progress') as HTMLElement;
                    if (loader) {
                        loader.remove();
                    }

                }

                const results = document.querySelector('.guidance__results') as HTMLElement;
                if (results) {
                    const client = results.getBoundingClientRect();
                    window.scrollTo({top: client.top - 200, behavior: 'smooth'});
                }
                if (form) {
                    form.classList.remove('active');
                }
                const body = document.querySelector('body') as HTMLElement;
                body.removeAttribute('style');
                paginate();
            });
        }

    }

    const isObjectEmpty = (objectName: any) => {
        for (let prop in objectName) {
            if (objectName.hasOwnProperty(prop)) {
                return false;
            }
        }
        return true;
    };

    // Set data;
    function setData() {
        let data = {} as DataApi;

        if (tos.value != '') {
            data.tos = tos.value;
        }
        if (country.value != '') {
            data.country = country.value;
        }
        if (region_airports.value != '') {
            data.region_airports = region_airports.value;
        }
        if (maxPrice.value != '') {
            data.maxPrice = parseInt(maxPrice.value);
        }
        if (departureDate.value != '') {
            data.departureDate = departureDate.value;
        }
        if (departureDays.value != '') {
            data.departureDays = departureDays.value;
        }
        if (departureMonths.value != '') {
            data.departureMonths = departureMonths.value;
        }
        if (durationMax.value != '') {
            data.durationMax = parseInt(durationMax.value);
        }
        if (durationMin.value != '') {
            data.durationMin = parseInt(durationMin.value);
        }
        if (maxPrice.value != '') {
            data.maxPrice = parseInt(maxPrice.value);
        }
        if (returnDays.value != '') {
            data.returnDays = returnDays.value;
        }
        if (returnMonths.value != '') {
            data.returnMonths = returnMonths.value;
        }
        return data;
    }

    // Reinit du formulaire.
    const formReset = document.querySelector('.reset-form.button') as HTMLInputElement;
    formReset.onclick = function(e: MouseEvent) {
        e.preventDefault();
        reinitAll();
        return false;
    };

    // Event when click on search.
    const buttonSearchElem = document.querySelector('[data-twig-suggestion="search"]') as HTMLInputElement;
    buttonSearchElem.onclick = function() {
        // Cleanup of url.
        window.history.pushState({}, document.title, window.location.pathname);
    };


    // Form submit.
    function formSubmit(form: HTMLElement, filterSliderElement: any) {

        if (guidance.dataset.guidance && guidance.dataset.guidance == 'home') {
            // Make url and redirect.
            form.addEventListener('submit', function (e: SubmitEvent) {

                // Serialize form.
                const search = serialize(form);
                localStorage.setItem('search', search);

                let data = setData() as DataApi;
                const url = serializeUrl(data);
                if (url) {
                    window.location.href = `${urlPage}?${url}`;
                }
                else {
                    window.location.href = `${urlPage}?home=true`;
                }
                e.preventDefault();
            }, false)
        }
        else {
            form.addEventListener('submit', function (e: SubmitEvent) {

                // Serialize form.
                const search = serialize(form);
                localStorage.setItem('search', search);

                window.history.replaceState(null, '', '');
                // Loader.
                insertLoader();

                let errors = [];
                let data = setData() as DataApi;

                // Check errors.
                if (errors.length > 0) {
                    // TODO display error.
                    e.preventDefault();
                } else {
                    // Fetch the ajax and show in target div.
                    resetFormFilters(filterSliderElement);
                    triggerCounterFilter();
                    fetchFromApiOptionway(data, store);

                }
                e.preventDefault();
            }, false)
        }
    }

    // Filters submit.
    function filtersSubmit(filters: HTMLElement) {
        filters.addEventListener('submit', function (e: SubmitEvent) {
            const filtersElem = document.querySelector('.store-elem.filters') as HTMLElement;
            if (filtersElem) {
                filtersElem.remove();
            }

            // Loader.
            insertLoader();

            const thisForm = e.target as HTMLFormElement;
            let filters = serialize(thisForm);

            let storeElem = '<div class="store-elem filters"></div>';
            let doc = parser.parseFromString(storeElem, 'text/html');
            let insert = doc.querySelector('.filters') as HTMLElement;
            insert.innerHTML = filters;
            store.append(insert);
            localStorage.setItem('filters', filters);

            let api_optionway_response = document.querySelector('.api_optionway_response')?.textContent;
            if (api_optionway_response) {
                fetchTwigResponse(api_optionway_response, filters, false);
                if (panelFilters.classList.contains('active')) {
                    panelFilters.classList.remove('active');
                }
            }
            e.preventDefault();
        }, false)
    }

    // Change counter.
    function triggerCounterFilter() {
        let thosefilters = serialize(filters);
        let api_optionway_response = document.querySelector('.api_optionway_response')?.textContent;
        if (api_optionway_response && api_optionway_response != 'false') {
            fetchTwigResponse(api_optionway_response, thosefilters, true);
        }
    }

    function createSliderFilters () {
        let filterSliderElement = rangeSlider(filterTime, {
            min: 0,
            max: 12,
            step: 1,
            value: [0, 0],
            thumbsDisabled: [true, false],
            onInput: function (value: [number,number], userInteraction: boolean)  {
                let load = parser.parseFromString(loader, 'text/html');
                let loading = load.querySelector('.ajax-progress') as HTMLElement;
                let isLoading = btnValidFilters?.querySelector('.ajax-progress') as HTMLElement;
                if (!isLoading) {
                    btnValidFilters.append(loading);
                }
                if (userInteraction) {
                    if (value[1] > 0) {
                        filterTimeInput.value = value[1].toString();
                        filterTimeInputShow.value = `${value[1].toString()}${translations.hour_max}`
                    }
                    else {
                        filterTimeInput.value = '';
                        filterTimeInputShow.value = ''
                    }
                }
                setTimeout(function (){
                    triggerCounterFilter();
                }, 300)
            }
        });
        return filterSliderElement
    }

    // Reset form filters.
    function resetFormFilters(filterSliderElement: any) {
        filtersCheckboxes.forEach((input: any) => {
            input.checked = false;
        });

        // Reset and rearm the filter sliders.
        filterTimeInput.value = '';
        filterTimeInputShow.value = '';
        filterSliderElement.removeGlobalEventListeners();
        filterSliderElement = '';
        filterSliderElement = createSliderFilters();
        localStorage.removeItem('filters');
        triggerCounterFilter();
    }

    // Function DOMLoaded
    if (guidance) {

        let observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                mutation.addedNodes.forEach((item) => {
                    let elm = item as HTMLElement;
                    if (elm.classList.contains('api_optionway_response')) {
                        let apiInfos = elm.textContent;
                        if (apiInfos) {
                            fetchTwigResponse(apiInfos, null, false);
                        }
                    }
                })
            });
        });

        // configuration of the observer
        const config = { attributes: true, childList: true, characterData: true, subtree:true };
        observer.observe(store, config);

        const apiPrevious = document.querySelector('.store-elem.api_optionway_response') as HTMLElement;
        if (apiPrevious) {
            apiPrevious.remove();
        }
        /*
        let api_optionway_response = localStorage.getItem('api_optionway_response');
        if (api_optionway_response) {
            let storeElem = '<div class="store-elem api_optionway_response"></div>';
            let doc = parser.parseFromString(storeElem, 'text/html');
            let insert = doc.querySelector('.api_optionway_response') as HTMLElement;
            insert.textContent = api_optionway_response;
            store.append(insert);
        }*/

        autoCompleteWhere(where);

        where.addEventListener('keyup', function(e: Event){
            const elem = e.target as HTMLInputElement;
            if (elem.value == '' && elem.value.length <= 2) {
                tos.value = '';
                country.value = '';
                region_airports.value = '';
            } else {
                // Fallback when error in typing a city.
                tos.value = elem.value;
                country.value = '';
                region_airports.value = '';
            }

        });
        where.addEventListener('blur', function(e: Event){
            const elem = e.target as HTMLInputElement;
            if (elem.value == '' && elem.value.length <= 2) {
                tos.value = '';
            }
            const selectedAutoComp = document.querySelector('.autocomplete .selected:not(.anywhere, .empty)') as HTMLElement;
            if (selectedAutoComp) {
                selectedAutoComp.click();
            }
        });


        // Range slider element.
        const rangeSliderElement = rangeSlider(range, {
            min: 50,
            max: 500,
            step: 10,
            value: [50, 50],
            thumbsDisabled: [true, false],
            onInput: function () {
                sliderRange.value = rangeSliderElement.value()[1].toString();
                budget.value = rangeSliderElement.value()[1].toString() + "€ " + translations.budget_perperson
                maxPrice.value = rangeSliderElement.value()[1].toString();
            }
        });
        // Range filter.
        let filterSliderElement = {} as any;
        if (filterTime) {
            filterSliderElement = createSliderFilters();
        }

        if (sliderRange) {
            let timeout = null as any;
            sliderRange.addEventListener('keyup', (e: KeyboardEvent) => {
                let elem = e.target as HTMLInputElement;
                let val = Number(elem.value);
                let parent = elem.closest('.form-item-slider') as HTMLElement;
                if (timeout) {
                    clearTimeout(timeout);
                }
                if (!isNaN(val)) {
                    timeout = setTimeout(() => {
                        parent?.classList.remove('error');
                        if (val < 10) {
                            clearTimeout(timeout);
                        }
                        else if (val > 500) {
                            parent?.classList.add('error');
                        }
                        else {
                            rangeSliderElement.value([0, val]);
                        }
                    }, 600);
                } else {
                    parent?.classList.add('error');
                }
            });
        }

        // Forms and filters triggers.
        if (form) {
            formSubmit(form, filterSliderElement);
        }
        if (filters) {
            filtersSubmit(filters);
        }

        // Charge search if params exists in url from home.
        let url = new URL(window.location.href);
        // @ts-ignore
        let params = Object.fromEntries(new URLSearchParams(url.search));
        if (!isObjectEmpty(params)) {
            let executeFetch = false;
            // Loader.
            insertLoader();

            // Verify case before doing a fetch request.
            if (params.home == 'true') {
                params = {};
                executeFetch = true;
            }
            else if (params.hasOwnProperty('cards')) {
                // Loader.
                insertLoader();

                executeFetch = false;
                searchFieldsPopulate(datePickers);
                let api_optionway_response = localStorage.getItem('api_optionway_response');
                if (api_optionway_response) {
                    let storeElem = '<div class="store-elem api_optionway_response"></div>';
                    let doc = parser.parseFromString(storeElem, 'text/html');
                    let insert = doc.querySelector('.api_optionway_response') as HTMLElement;
                    insert.innerHTML = api_optionway_response;
                    store.append(insert);

                    fetchTwigResponse(api_optionway_response, null, false);
                }
            }
            else if (params.hasOwnProperty('map')) {
                executeFetch = false;
            }
            else if (params.hasOwnProperty('departureDate')) {
                // Loader.
                insertLoader();

                // Use data from url to populate search.
                searchFieldsPopulate(datePickers);
                let queryString = window.location.search;
                if (!queryString.includes('departureDate=cheapest')) {
                    // Fill fields = display for user.
                    const search = localStorage.getItem('search');
                    if (search) {
                        const originals = Object.fromEntries(new URLSearchParams(search));
                        const keys = Object.keys(originals);
                        keys.forEach((key: string) => {
                            const elemInput = form?.querySelector(`.form-text[name="${key}"]`) as HTMLInputElement;
                            const elemSelect = form?.querySelector(`select[name="${key}"]`) as HTMLSelectElement;
                            const elemCheckbox = form?.querySelector(`.form-checkbox[name="${key}"]`) as HTMLInputElement;
                            const elemHidden = form?.querySelector(`[type="hidden"][name="${key}"]`) as HTMLInputElement;

                            if (elemInput) {
                                elemInput.value = originals[key];
                            }
                            if (elemSelect) {
                                elemSelect.value = originals[key];
                            }
                            if (elemCheckbox) {
                                elemCheckbox.checked = true;
                            }
                            if (elemHidden) {
                                if (!key.includes('form')) {
                                    elemHidden.value = originals[key];
                                }
                            }
                        });
                    }
                }
                executeFetch = true;
            }
            else if (params.hasOwnProperty('departureMonths')) {

                executeFetch = true;
            }
            else {
                // Loader.
                insertLoader();

                executeFetch = true;
            }
            if (executeFetch) {
                fetchFromApiOptionway(params, store);
                const loading = document.querySelector('.ajax-progress') as HTMLElement;
                if (loading) {
                    loading.remove();
                }
            }
        }

        // All prices button.
        allPrices.addEventListener('click', function (e: MouseEvent) {
            budget.value = translations.budget_any;
            maxPrice.value = '';
            panelBudget.removeAttribute('style');
            e.preventDefault();
            return false;
        });

        // Open price panel.
        budget.addEventListener('focus', function (e) {
            panelBudget.setAttribute('style', 'display:block;');
            e.stopPropagation();
            return false;
        });
        const wrapperPanelBudget = document.querySelector('[data-drupal-selector="edit-wrapper-budget"]') as HTMLElement;
        document.addEventListener('click', function (e) {
            const elem = e.target as HTMLElement;
            if (!wrapperPanelBudget.contains(elem)) {
                panelBudget.removeAttribute('style');
            }
        });

        // Open time panel.
        when.addEventListener('focus', function (e) {
            panelWhen.setAttribute('style', 'display:block;');
            e.stopPropagation();
            return false;
        });
        const wrapperPanelWhen = document.querySelector('[data-drupal-selector="edit-wrapper-when"]') as HTMLElement;
        document.addEventListener('click', function (e: MouseEvent) {
            const elem = e.target as HTMLElement;
            if (!wrapperPanelWhen.contains(elem)) {
                closeWhen();
            }
        });

        // Filters panel.
        btnFilters?.addEventListener('click', function (e: MouseEvent) {
            panelFilters.classList.add('active');
            triggerCounterFilter();
            e.stopPropagation();
            return false;
        });
        closeFilters?.addEventListener('click', function (e: MouseEvent) {
            panelFilters.classList.remove('active');
            e.stopPropagation();
            return false;
        });

        underlay?.addEventListener('click', () => {
            if (panelFilters.classList.contains('active')) {
                panelFilters.classList.remove('active');
            }
        })

        // Duration of stay.
        durationsCheckboxes.forEach((input: any) => {
            input.addEventListener('click', function (e: MouseEvent) {
                const elem = e.target as HTMLInputElement;
                durationsCheckboxes.forEach((checkbox: HTMLInputElement) => {
                    if (checkbox != elem) {
                        checkbox.checked = false;
                    }
                });
            });
            input.addEventListener('change', function (e: MouseEvent) {
                const elem = e.target as HTMLInputElement;
                when.removeAttribute('data-duration');
                if (elem.checked) {
                    let value;
                    switch (elem.value) {
                        case 'less_week':
                            value = 6;
                            durationMax.value = String(value);
                            durationMin.value = '';
                            break;
                        case 'week':
                            value = 7;
                            durationMax.value = String(value);
                            durationMin.value = String(value);
                            break;
                        case 'more_week':
                            value = 7;
                            durationMax.value = '';
                            durationMin.value = String(value);
                            break;
                        case 'any':
                        default:
                            durationMax.value = '';
                            durationMin.value = '';
                    }
                    if (durationMax.value || durationMin.value) {
                        let addText = elem.nextElementSibling?.textContent;
                        if (addText) {
                            when.setAttribute('data-duration', addText);
                            resetWhenField();
                        }
                    }
                }
                else {
                    resetWhenField();
                }
            });
        })

        // Select Months
        monthsCheckboxes.forEach((input: any) => {
            input.addEventListener('change', function () {
                departureDate.value = '';
                when.removeAttribute('data-departure');
                // @ts-ignore
                datePickers['start'].setDate({clear: true});
                // @ts-ignore
                datePickers['back'].setDate({clear: true});
                checkUpdateMonths();
                resetWhenField();
            });
        });

        // Panels elements.
        nextPanel.addEventListener('click', function (e: MouseEvent) {
            const elem = e.target as HTMLElement;
            firstPanel.setAttribute('style', 'display: none;');
            secondPanel.removeAttribute('hidden');
        });

        // Datepickers functions.
        // Generate.
        datePickers.forEach((elem: Element, key: number|string) => {
            const currentElem = elem as HTMLElement;
            let name = currentElem.dataset.type;
            let tag = name?.split('[')?.pop()?.split(']')[0];
            if (tag) {
                key = tag;
            }
            // @ts-ignore
            datePickers[key] = new Datepicker(elem, {
                format: 'dd/mm/yyyy',
                defaultViewDate: minDate,
                minDate: minDate,
                language: lang,
                maxView: 0,
            });
            if (tag == "back") {
                const setDate = new Date(today.getFullYear(), today.getMonth()+1, 1);
                // @ts-ignore
                datePickers['back'].setOptions({
                    minDate: setDate,
                    defaultViewDate: setDate
                });
            }
        });
        datePickers.forEach((dateEl: Element) => {
            // Synchronous months.
            dateEl.addEventListener('changeMonth', (event: any) => {
                let currentElem = event.target as HTMLInputElement;
                let name = currentElem.dataset.type;
                // Change next when update prev.
                if (name && name.includes('start')) {
                    let viewDate = event.detail.viewDate;
                    let changeDay = new Date(viewDate.getFullYear(), viewDate.getMonth()+1, 1);
                    // @ts-ignore
                    datePickers['back'].setOptions({
                        defaultViewDate: changeDay
                    });
                }
                // Change next when update prev.
                if (name && name.includes('back')) {
                    let viewDate = event.detail.viewDate;
                    let changeDay = new Date(viewDate.getFullYear(), viewDate.getMonth()-1, 1);
                    if (changeDay < minDate) {
                        changeDay = minDate;
                    }
                    // @ts-ignore
                    datePickers['start'].setOptions({
                        defaultViewDate: changeDay
                    });
                }
                if (tos.getAttribute('data-tos')) {
                    let iata_id = `${tos.getAttribute('data-tos')}`;
                    updateFlights(iata_id);
                }

            });
            // Chose date event.
            dateEl.addEventListener('changeDate', (event: any) => {
                let currentElem = event.target as HTMLInputElement;
                let name = currentElem.dataset.type;
                if (name && name.includes('start')) {
                    // @ts-ignore
                    datePickers['back'].setDate({
                        clear: true
                    });
                }
                if (name && name.includes('back')) {
                    // @ts-ignore
                    datePickers['start'].setDate({
                        clear: true
                    });
                }
                let choseDate = event.detail.date;
                if (name) {
                    // @ts-ignore
                    datePickers[name].setDate(choseDate);
                }
                let apiDate = Datepicker.formatDate( choseDate, 'yyyy-mm-dd');
                let showDate = Datepicker.formatDate( choseDate, 'D dd M yy', lang);

                // Set value for search.
                departureDate.value = apiDate;
                departureDays.value = '';
                returnDays.value = '';
                when.setAttribute('data-departure', showDate);
                when.removeAttribute('data-monthsdeparture');
                when.removeAttribute('data-departuredays');
                when.removeAttribute('data-returndays');
                resetWhenField();
            });
        });

        // Populate search form.
        searchFieldsPopulate(datePickers);

        // Cheapest dates.
        cheapest.addEventListener('click', function() {
            resetDates();
            when.value = `${cheapest.textContent?.trim()}`;
            when.setAttribute('title',  `${cheapest.textContent?.trim()}`);
            panelWhen.removeAttribute('style');
            departureDate.value = "cheapest";
        });

        // Select days.
        selectDepartureDay.addEventListener('change', function(e: Event) {
            const elem = e.target as HTMLSelectElement;
            const selected = elem.querySelector(`option[value="${elem.value}"]`);
            departureDate.value = '';
            when.removeAttribute('data-departure');
            // @ts-ignore
            datePickers['start'].setDate({clear: true});
            // @ts-ignore
            datePickers['back'].setDate({clear: true});
            departureDays.value = elem.value;
            when.setAttribute('data-departuredays', `${translations.depart_day} ${selected?.textContent}`);
            resetWhenField();
        });
        selectReturnDay.addEventListener('change', function(e: Event) {
            const elem = e.target as HTMLSelectElement;
            const selected = elem.querySelector(`option[value="${elem.value}"]`);
            departureDate.value = '';
            when.removeAttribute('data-departure');
            // @ts-ignore
            datePickers['start'].setDate({clear: true});
            // @ts-ignore
            datePickers['back'].setDate({clear: true});
            returnDays.value = elem.value;
            when.setAttribute('data-returndays', `${translations.return_day} ${selected?.textContent}`);
            resetWhenField();
        });

        // Force remove hash from url.
        if (backToThumb) {
            backToThumb.onclick = function(e: MouseEvent) {
                e.preventDefault();
                const elem = e.target as HTMLElement;
                if (!elem.classList.contains('is-active')) {
                    window.location.href = `${urlPage}?cards`;
                }
                return false;
            }
        }

        // Verify change on all filters checkbox.
        filtersCheckboxes?.forEach((input: any) => {
            input.addEventListener('change', function () {
                triggerCounterFilter();
            });
        });
        btnResetFilters?.addEventListener('click', function(e: MouseEvent) {
            resetFormFilters(filterSliderElement);
            triggerCounterFilter();
            e.preventDefault();
            return false;
        });

        // Menu search Mobile

        mobileSearch?.addEventListener('click', () => {
            guidance.classList.add('active');
            form.classList.add('active');
            const body = document.querySelector('body') as HTMLElement;
            if (body) {
                body.classList.add('open-guidance-home');
            }


            const headMobileInsert = document.querySelector('.guidance-form .page-guidance_panel-heading') as HTMLElement;
            const headMobileInsertSub = document.querySelector('.guidance-form .page-guidance_panel-subtitle') as HTMLElement;
            if (headMobileInsert) {
                headMobileInsert.remove();
            }
            if (headMobileInsertSub) {
                headMobileInsertSub.remove();
            }

            const mobileFormHeading = document.createElement('div') as HTMLElement;
            mobileFormHeading.classList.add('page-guidance_panel-heading');

            const closeSearch = document.createElement('div') as HTMLElement;
            closeSearch.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24" preserveAspectRatio="xMinYMin"><use xlink:href="#img-cross"></use></svg>';
            mobileFormHeading.appendChild(closeSearch);

            const mobileFormTitle = document.createElement('h2') as HTMLElement;
            mobileFormTitle.classList.add('page-guidance_panel-title', 'subtitle', 'heading--medium');
            if (formTitle) {
                mobileFormTitle.innerText = `${formTitle.innerText}`;
            }
            mobileFormHeading.appendChild(mobileFormTitle);

            form.insertBefore(mobileFormHeading, formItemWhere);

            const mobileFormSubtitle = document.createElement('p') as HTMLElement;
            mobileFormSubtitle.classList.add('page-guidance_panel-subtitle');
            if (formSubtitle) {
                mobileFormSubtitle.innerText = `${formSubtitle.innerText}`;
            }
            form.insertBefore(mobileFormSubtitle, formItemWhere);

            document.body.style.overflow = 'hidden';
            formContainer.style.zIndex = '21';

            closeSearch.addEventListener('click', () => {
                form.classList.remove('active');
                guidance.classList.remove('active');
                const body = document.querySelector('body') as HTMLElement;
                if (body) {
                    body.classList.remove('open-guidance-home');
                }
                mobileFormHeading.remove();
                mobileFormSubtitle.remove();
                document.body.style.overflow = 'auto';
                formContainer.style.zIndex = '2';
            });
        });

        const searchPanRender = () => {
            if (form.classList.contains('active')) {
                const searchPan = document.createElement('div') as HTMLElement;
                searchPan.classList.add('search-pan');
                form.appendChild(searchPan);
                let elemClose = document.querySelector('.close-pan') as HTMLElement;
                if (elemClose) {
                    elemClose.remove();
                }
                const closePan = document.createElement('div') as HTMLElement;
                closePan.classList.add('close-pan');
                closePan.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24" preserveAspectRatio="xMinYMin"><use xlink:href="#img-arrow-left"></use></svg> Retour';
                searchPan.appendChild(closePan);

                const validateClosePan = document.createElement('div') as HTMLElement;
                validateClosePan.classList.add('wrap--validation');
                validateClosePan.innerHTML = `<button class="btn close-pan-when">${translations.validate}</button>`;
                searchPan.appendChild(validateClosePan);
                const validateButton = validateClosePan.querySelector('button');
                if (validateButton) {
                    closePan.onclick = validateButton.onclick = () => {
                        where.blur();
                        const autocom = document.querySelector('.autocomplete') as HTMLElement;
                        if (autocom) {
                            autocom.remove();
                        }
                        if (form.classList.contains('open-where')) {
                            form.appendChild(formItemWhere);
                            form.classList.remove('open-where');
                        }
                        if (form.classList.contains('open-when')) {
                            form.appendChild(formItemWhen);
                            form.classList.remove('open-when');
                        }
                        if (form.classList.contains('open-budget')) {
                            form.appendChild(formItemBudget);
                            form.classList.remove('open-budget');
                        }
                        if (searchPan) {
                            searchPan.remove();
                        }

                        let searchPanAll = form.querySelectorAll('.search-pan');
                        if (searchPanAll.length > 0) {
                            searchPanAll.forEach((elem: Element) => {
                                const element = elem as HTMLElement;
                                element.remove();
                            });
                        }
                    };
                }

            }
        }

        formItemWhere.addEventListener('click', () => {
            if (form.classList.contains('active') && !form.classList.contains('open-where')) {
                form.classList.add('open-where');
                searchPanRender();
                const searchPanItem = document.querySelector('.search-pan') as HTMLElement;
                searchPanItem.classList.add('search-pan--where');
                searchPanItem.appendChild(formItemWhere);
            }
        });

        formItemWhen.addEventListener('click', () => {
            if (form.classList.contains('active') && !form.classList.contains('open-when')) {
                form.classList.add('open-when');
                searchPanRender();
                const searchPanItem = document.querySelector('.search-pan') as HTMLElement;
                searchPanItem.classList.add('search-pan--when');
                searchPanItem.appendChild(formItemWhen);
            }
        });

        formItemBudget.addEventListener('click', () => {
            if (form.classList.contains('active') && !form.classList.contains('open-budget')) {
                form.classList.add('open-budget');
                searchPanRender();
                const searchPanItem = document.querySelector('.search-pan') as HTMLElement;
                searchPanItem.classList.add('search-pan--budget');
                searchPanItem.appendChild(formItemBudget);
            }
        });

        const isNumeric = (val: string) : boolean => {
            return !isNaN(Number(val));
        }
        if (filterTimeInputShow) {
            let timeout = null as any;
            filterTimeInputShow.onkeyup = (e: KeyboardEvent) => {
                const elem = e.target as HTMLInputElement;
                let value = elem.value;
                if (timeout) {
                    clearTimeout(timeout);
                }
                timeout = setTimeout(function () {
                    if (value && isNumeric(value)) {
                        if(filterSliderElement) {
                            filterSliderElement.value([0, Number(value)]);
                        }
                        if (Number(value) > 12) {
                            filterTimeInputShow.value = '12';
                            filterSliderElement.value([0, 12]);
                        }
                    }
                    else {
                        filterTimeInputShow.value = '';
                    }
                }, 600);
            }
        }


        const tabsGuidance = document.querySelectorAll('.second-panel .nav-tabs__link');
        function theTabClicksGuidance (tabClickEvent: any) {
            const clickedTab = tabClickEvent.currentTarget;
            const tabParent = tabClickEvent.currentTarget?.parentNode?.parentNode?.parentNode;
            const theTabs = tabParent.querySelectorAll(".nav-tabs__link");
            for (let i = 0; i < theTabs.length; i++) {
                theTabs[i].classList.remove("current");
            }

            clickedTab.classList.add("current");
            const anchorReference = clickedTab.querySelector('a') as HTMLElement;
            const activePaneId = anchorReference.getAttribute("href");

            if (activePaneId?.search(/#/) === -1) {
                return;
            }

            tabClickEvent.preventDefault();
            const contentPanes = tabParent.querySelectorAll(".tab__pane");
            for (let i = 0; i < contentPanes.length; i++) {
                contentPanes[i].classList.remove("current");
            }
            const activePane = tabParent.querySelector(activePaneId);
            activePane?.classList.add("current");
        }
        for (let i = 0; i < tabsGuidance.length; i++) {
            const elem = tabsGuidance[i] as HTMLElement;
            elem.onclick = theTabClicksGuidance;
        }

    }

}
