import {Loader} from 'google-maps';
import {MarkerClusterer, GridAlgorithm} from "@googlemaps/markerclusterer";
import {MarkerWithLabel} from '@googlemaps/markerwithlabel';
// @ts-ignore
import serialize from 'form-serialize';

export default async function destinationMap() {
    const filter_id = window.drupalSettings.filter_id == '' ? '' : '/' + window.drupalSettings.filter_id;
    const url_ajax = '/' + window.drupalSettings.path.pathPrefix + 'ajax/destination/map/rest' + filter_id;
    let response;
    const lang = document.documentElement.lang;
    const translations = window.translationsMap;

    // Create map.
    const loader = new Loader('AIzaSyDROHqIm0so7OaPI43XCY2hEy7c0-ojsMw');
    const google = await loader.load();
    const map = createMap(google);
    let markers = [] as any;
    let markerCluster = [] as any;

    const guidanceMap = document.querySelector('.guidance-map') as HTMLElement;
    const formFilters = document.querySelector('.guidance-filters-form') as HTMLElement;
    const counter = document.querySelector('.counter--destinations em') as HTMLElement;

    // Get guidance response.
    function fetchGuidanceResponse(apiOptionwayResponseString: string, filters: string|null, counters: boolean|null) {
        let bodyOfPost;
        let newData = {
            'dataApi' : apiOptionwayResponseString,
            'filters' : '',
            'counters': counters,
        };
        // Add filters if data exists.
        if (filters) {
            newData = {
                'dataApi' : apiOptionwayResponseString,
                'filters' : filters,
                'counters': counters,
            }
        }
        // Transform to string for request.
        bodyOfPost = JSON.stringify(newData);

        fetch(`/${lang}/ajax/guidance/map`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: bodyOfPost
        }).then(response => response.json())
        .then(function (json) {
            let destinations = json;
            counter.textContent = String(destinations.length);
            if (markers){
                removeAllMarkers(markers, markerCluster);
            }
            initMap(destinations, filter_id, map, google);
        });
    }

    // Test for guidance.
    if (guidanceMap) {

        const store = document.querySelector('.store') as HTMLElement;
        let observerMap = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                mutation.addedNodes.forEach((item) => {
                    let elm = item as HTMLElement;
                    if (elm.classList.contains('api_optionway_response')) {
                        let apiInfos = elm.textContent;
                        if (apiInfos) {
                            fetchGuidanceResponse(apiInfos, null, false);
                        }
                    }
                    if (elm.classList.contains('filters')) {
                        let filters = document.querySelector('.store-elem.filters')?.textContent;
                        let api_optionway_response = document.querySelector('.store-elem.api_optionway_response')?.textContent;

                        if (filters && api_optionway_response) {
                            fetchGuidanceResponse(api_optionway_response, filters, false);
                        }
                    }
                })
            });
        });

        // configuration of the observer
        const config = { attributes: true, childList: true, characterData: true, subtree:true };
        observerMap.observe(store, config);

        let parser = new DOMParser();
        const apiPrevious = document.querySelector('.store-elem.api_optionway_response') as HTMLElement;
        if (apiPrevious) {
            apiPrevious.remove();
        }
        let api_optionway_response = localStorage.getItem('api_optionway_response');
        if (api_optionway_response) {
            let storeElem = '<div class="store-elem api_optionway_response"></div>';
            let doc = parser.parseFromString(storeElem, 'text/html');
            let insert = doc.querySelector('.api_optionway_response') as HTMLElement;
            insert.textContent = api_optionway_response;
            store.append(insert);
        }

    }
    else {
        // Call ajax.
        response = await fetch(url_ajax);
        if (response.ok) {
            let destinations = await response.json();
            await initMap(destinations, filter_id, map, google);
        } else {
            console.log("HTTP-Error: " + response.status);
        }
    }
    function removeAllMarkers(markers: any, markerCluster: any) {
        for (let i = 0; i < markers.length; i++) {
            if (markerCluster.markers && markerCluster.markers.length > 0) {
                markerCluster.removeMarker(markers[i]);
                markers[i].setMap(null);
                markers[i] = null;
            }
        }
    }
    async function initMap(destinations : any, filter_id : any, map: any, google: any) {

        if (destinations.length !== undefined) {
            if (markers.length > 0) {
                let i = markers.length
                while (i--) {
                    if (markers != null) {
                        markers.splice(i, 1);
                    }
                }
            }
            let bounds = new google.maps.LatLngBounds();
            let infoWindow = new google.maps.InfoWindow({
                pixelOffset: new google.maps.Size(-20,-45),
            });

            destinations.forEach((destination: any) => {
                // Create marker and infowindow.
                createMarker(map, markers, destination, bounds, infoWindow);
            });

            // Add MarkerClusterer.
            createMarkerClusterer(map, markers);

            // Auto-center.
            if(filter_id) {
                // Need this condition because in case of we have only one destination, fitBounds zoom too much.
                if (destinations.length == 1) {
                    map.setZoom(8);
                    const coordinates = destinations[0].field_coordinates.split(",");
                    const lat = Number(coordinates[1]);
                    const lng = Number(coordinates[0]);
                    map.setCenter(new google.maps.LatLng(lat, lng));
                }
                else {
                    map.setZoom(6);
                    map.fitBounds(bounds);
                }
            }

            // Click on the map.
            map.addListener('click', function() {
                closeMarkers(markers, infoWindow);
            });

            // Click zoom;
            map.addListener('zoom_changed', function() {
                closeMarkers(markers, infoWindow);
            });
        }
    }

    /*
     * Create map.
     */
    function createMap(google : any) {
        // Create an array of styles.
        const mapStyles =
            [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#828282"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{"visibility": "off"}]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#F4F4F2"
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#828282"
                        },
                        {
                            "lightness": "36"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#CAD2D3"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {"featureType": 'poi', "stylers": [{"visibility": 'off'}]},
                {"featureType": 'transit', "stylers": [{"visibility": 'off'}]}
            ];

        const center: google.maps.LatLngLiteral = {lat: 47.16910520252873, lng: -1.6141251552252385};
        const mapOptions = {
            center,
            zoom: 4,
            minZoom: 4,
            styles: mapStyles,
            streetViewControl: false,
        };

        return new google.maps.Map(document.getElementById("destination-map") as HTMLElement, mapOptions);
    }

    /*
    * Create marker.
    */
    function createMarker(map : any, markers : any, destination : any, bounds : any, infoWindow: any) {
        const title = String(destination.title);
        const coordinates = destination.field_coordinates.split(",");
        const price = `<p>A partir de <strong>${destination.field_cheapflight_price}€</strong></p>`;
        const lat = Number(coordinates[1]);
        const lng = Number(coordinates[0]);
        const position = {lat: lat, lng: lng};

        // Create marker.
        const marker = new MarkerWithLabel({
            map: map,
            position: position,
            clickable: true,
            title: title,
            icon: ' ',
            labelContent: `<p><strong>${title}</strong></p>${price}`,
            labelAnchor: new google.maps.Point(-90, -40),
            labelClass: 'destination-label',
        });

        bounds.extend(position);

        // Create info window.
        addInfoWindow(map, marker, destination, infoWindow);

        // All markers.
        markers.push(marker);
    }

    /*
    * Add InfoWindow for each marker.
    */
    function addInfoWindow(map : any, marker : any, destination : any, infoWindow: any) {

        marker.addListener('click', function (this: any) {
            const thumbnail = destination.field_thumbnail;
            const title = destination.title;
            const country_label = destination.field_country_label;
            const country_url = destination.field_country_url;
            const destination_type = destination.field_destination_type;
            const field_destination_type_key = destination.field_destination_type_key;
            const destination_url = destination.destination_url;
            const cheapflight_url = destination.field_cheapflight_url;
            let destination_type_class = destination_type;
            destination_type_class = destination_type_class.replaceAll(' ', '-');
            destination_type_class = destination_type_class.toLowerCase();
            const price = destination.field_cheapflight_price ? '<div class="card__price">' + destination.field_cheapflight_price + '€</div>' : '';
            const departure_date = destination.field_cheapflight_departure_date;
            const return_date = destination.field_cheapflight_return_date;
            let destination_title = '';
            let destination_thumbnail = '';

            if (field_destination_type_key == 'direct') {
                destination_title = `<a href="${destination_url}">${title}</a>`;
                destination_thumbnail = `<a href="${destination_url}">${thumbnail}</a>`;
            }
            else {
                destination_title = title;
                destination_thumbnail = thumbnail;
            }

            let html = `<div class="card destination-infowindow">` +
                `<p class="card__picture card__picture--default">${destination_thumbnail}</p>` +
                `<div class="card__content">` +
                `<div class="card__title"><h3>${destination_title}, <a href="${country_url}">${country_label}</a></h3></div>` +
                `<div class="card__flight-type ${destination_type_class}">${destination_type}</div>` +
                `${price}` +
                `<div class="card__date">Du ${departure_date} au ${return_date}</div>` +
                `<a href="${cheapflight_url}" target="_blank" class="btn">${translations.book_now}</a>` +
                `</div>`;
            `</div>`;

            const labels = document.querySelectorAll('.destination-label');
            labels.forEach((label: Element) => {
                label.classList.remove('open');
            })

            this.set("labelClass", "destination-label open");
            infoWindow.setContent(html);
            infoWindow.setPosition(this.getPosition());

            infoWindow.open(map, marker);
        });

    }

    /*
    * Create MarkerClusterer.
    */
    function createMarkerClusterer(map : any, markers : any) {
        const svg = window.btoa(`
                    <svg width="70" height="60" viewBox="0 0 70 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="69" height="59" rx="23.5" fill="white"/>
                    <rect x="0.5" y="0.5" width="69" height="59" rx="23.5" stroke="#B2C6CE"/>
                    </svg>`);

        // Render MarkerClusterer.
        const renderer = {
            // @ts-ignore
            render: ({count, position}) =>
                new google.maps.Marker({
                    label: {text: String(count), color: "##10213A", fontSize: "14px", fontWeight: "400"},
                    icon: {
                        url: `data:image/svg+xml;base64,${svg}`,
                        // scaledSize: new google.maps.Size(45, 45),
                    },
                    position,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                })
        };

        // Param MarkerClusterer zone.
        const algorithm = new GridAlgorithm({
            gridSize: 60
        });

        // First init of clusterer.
        if (markerCluster.length == 0) {
            markerCluster = new MarkerClusterer({map, markers, algorithm, renderer});
        } else {
            markerCluster.addMarkers(markers);
        }

    }

    /*
    * Close infowindow et remove "open" class to the markers.
    */
    function closeMarkers(markers: any, infoWindow: any) {
        if (infoWindow) infoWindow.close();

        markers.forEach((marker: any) => {
            marker.set("labelClass", "destination-label");
        })
    }

}
