import { ui } from "../../utils/ui";

const setFieldCountry = (value: string | null) => {
    if (!ui?.search?.fields?.country) return;
    if (!value) value = "";
    ui.search.fields.country.value = value;
};

const setFieldDestinations = (value: string | null) => {
    if (!ui?.search?.fields?.destinations) return;
    if (!value) value = "";
    ui.search.fields.destinations.value = value;
};

const setFieldFrom = (value: string | null) => {
    if (!ui?.search?.fields?.from) return;
    if (!value) value = "";
    ui.search.fields.from.value = value;
    // ui.search.fields.from.dispatchEvent(new Event("change"));
};

const setFieldTo = (value: string | null) => {
    if (!ui?.search?.fields?.to) return;
    if (!value) value = "";
    ui.search.fields.to.value = value;
    // ui.search.fields.to.dispatchEvent(new Event("change"));
};

const setHiddenFieldCountry = (value: string | null) => {
    if (!ui?.search?.fields?.hiddenCountry) return;
    if (!value) value = "";
    ui.search.fields.hiddenCountry.value = value;
    ui.search.fields.hiddenCountry.dispatchEvent(new Event("change"));
};

const setHiddenFieldAirports = (value: string | null) => {
    if (!ui?.search?.fields?.hiddenAirports) return;
    if (!value) value = "";
    ui.search.fields.hiddenAirports.value = value;
    ui.search.fields.hiddenAirports.dispatchEvent(new Event("change"));
};

const setHiddenFieldFrom = (value: string | null) => {
    if (!ui?.search?.fields?.hiddenFrom) return;
    if (!value) value = "";
    ui.search.fields.hiddenFrom.value = value;
    ui.search.fields.hiddenFrom.dispatchEvent(new Event("change"));
};

const setHiddenFieldTo = (value: string | null) => {
    if (!ui?.search?.fields?.hiddenTo) return;
    if (!value) value = "";
    ui.search.fields.hiddenTo.value = value;
    ui.search.fields.hiddenTo.dispatchEvent(new Event("change"));
};

export {
    setFieldCountry,
    setFieldDestinations,
    setFieldFrom,
    setFieldTo,
    setHiddenFieldCountry,
    setHiddenFieldAirports,
    setHiddenFieldFrom,
    setHiddenFieldTo,
};
