// @ts-ignore
import { Datepicker } from "vanillajs-datepicker";
// @ts-ignore
import fr from "vanillajs-datepicker/locales/fr";
// @ts-ignore
import en from "vanillajs-datepicker/locales/en-GB";
import { setHiddenFieldFrom, setHiddenFieldTo, setFieldFrom, setFieldTo } from "./setter";
import { hidePanelTo, hidePanelFrom, showLandingPanel } from "./action";
import { ui } from "../../utils/ui";

const lang = document.documentElement.lang;
const today = new Date();
const allDatePickers = {
    from: {
        start: null as Datepicker,
        back: null as Datepicker,
    },
    to: {
        start: null as Datepicker,
        back: null as Datepicker,
    },
} as any;
let minDate = today;
Object.assign(Datepicker.locales, fr, en);

const initAllDatepickers = () => {
    ui.allDatePickers.forEach((elem: Element) => {
        const element = elem as HTMLElement;
        let type = "" as string;
        const parent = element.closest(".panel--when") as HTMLElement;
        const thisElemType = element?.dataset?.type;
        const typeElement = parent.previousElementSibling as HTMLElement;
        if (typeElement?.classList.contains("form-item-from")) {
            type = "from";
        }
        if (typeElement?.classList.contains("form-item-to")) {
            type = "to";
        }
        if (thisElemType && type) {
            allDatePickers[type][thisElemType] = new Datepicker(element, {
                format: "dd/mm/yyyy",
                defaultViewDate: minDate,
                minDate: minDate,
                language: lang,
                maxView: 0,
            });

            // Set second calendar.
            if (thisElemType == "back") {
                let setDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                // @ts-ignore
                allDatePickers[type][thisElemType].setOptions({
                    minDate: setDate,
                    defaultViewDate: setDate,
                });
            }
        }
    });

    // Events.
    Object.keys(allDatePickers).forEach((key) => {
        Object.keys(allDatePickers[key]).forEach((type) => {
            // Change the month.
            allDatePickers[key][type].element.addEventListener("changeMonth", (event: any) => {
                if (type == "back") {
                    let viewDate = event.detail.viewDate;
                    let changeDay = new Date(viewDate.getFullYear(), viewDate.getMonth() - 1, 1);
                    if (changeDay < minDate) {
                        changeDay = minDate;
                    }
                    // @ts-ignore
                    allDatePickers[key].start.setOptions({
                        defaultViewDate: changeDay,
                    });
                }
                if (type == "start") {
                    let viewDate = event.detail.viewDate;
                    let changeDay = new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 1);
                    // @ts-ignore
                    allDatePickers[key].back.setOptions({
                        defaultViewDate: changeDay,
                    });
                }
            });

            // Change the date.
            // Pick a date.
            allDatePickers[key][type].element.addEventListener("changeDate", (event: any) => {
                let choseDate = event.detail.date;

                if (type == "start") {
                    // @ts-ignore
                    allDatePickers[key].back.setDate({
                        clear: true,
                    });
                }
                if (type == "back") {
                    // @ts-ignore
                    allDatePickers[key].start.setDate({
                        clear: true,
                    });
                }

                if (type) {
                    // @ts-ignore
                    allDatePickers[key][type].setDate(choseDate);
                }

                /**
                 * Set the dates in fields.
                 */
                let apiDate = Datepicker.formatDate(choseDate, "yyyy-mm-dd");
                let showDate = Datepicker.formatDate(choseDate, "D dd M yy", lang);

                if (key == "to") {
                    setHiddenFieldTo(apiDate);
                    setFieldTo(showDate);
                    setFromDatepickers(choseDate, allDatePickers);
                    hidePanelTo();
                }

                if (key == "from") {
                    setHiddenFieldFrom(apiDate);
                    setFieldFrom(showDate);
                    setToDatepickers(choseDate, allDatePickers);
                    hidePanelFrom();
                    ui?.landingPanel?.setAttribute("style", "display: block;");
                }
            });
        });
    });
};

const setToDatepickers = (choseDate: any, allDatePickers: any) => {
    // Set To.
    let from = addOneDayToDate(choseDate);
    allDatePickers.to.start.setOptions({
        minDate: from,
        defaultViewDate: from,
    });
    allDatePickers.to.back.setOptions({
        minDate: from,
    });
};

const setFromDatepickers = (choseDate: any, allDatePickers: any) => {
    // Set from.
    let to = choseDate;
    allDatePickers.from.start.setOptions({
        maxDate: to,
        defaultViewDate: to,
    });
    allDatePickers.from.back.setOptions({
        maxDate: to,
    });
};

const addOneDayToDate = (date: any) => {
    date.setDate(date.getDate() + 1);

    return date;
};

const addOneMonthToDate = (date: any) => {
    date = new Date(date.setMonth(date.getMonth() + 1));
    return date;
};

const setDatesEvent = () => {
    const getToday = new Date();
    /**
     * Set From.
     */
    let apiDate = Datepicker.formatDate(getToday, "yyyy-mm-dd");
    let showDate = Datepicker.formatDate(getToday, "D dd M yy", lang);
    setHiddenFieldFrom(apiDate);
    // setFieldFrom(showDate);

    /**
     * Set to.
     */
    let to = addOneMonthToDate(getToday);
    let toApiDate = Datepicker.formatDate(to, "yyyy-mm-dd");
    let toShowDate = Datepicker.formatDate(to, "D dd M yy", lang);
    setHiddenFieldTo(toApiDate);
    // setFieldTo(toShowDate);
};

const setDefaultDates = () => {
    /**
     * Set From.
     */
    let apiDate = Datepicker.formatDate(today, "yyyy-mm-dd");
    setHiddenFieldFrom(apiDate);

    /**
     * Set to.
     */
    let to = addOneMonthToDate(today);
    let toApiDate = Datepicker.formatDate(to, "yyyy-mm-dd");
    setHiddenFieldTo(toApiDate);
};

export { initAllDatepickers, setToDatepickers, setFromDatepickers, setDatesEvent, setDefaultDates, allDatePickers };
