import { google } from "google-maps";
import { ui } from "../../utils/ui";

const createMap = (google: google) => new google.maps.Map(ui.map, options);

const center: google.maps.LatLngLiteral = {
    lat: 47.16910520252873,
    lng: -1.6141251552252385,
};

const styles: google.maps.MapTypeStyle[] = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#828282",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#F4F4F2",
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#828282",
            },
            {
                lightness: 36,
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#CAD2D3",
            },
            {
                visibility: "on",
            },
        ],
    },
    { featureType: "poi", stylers: [{ visibility: "off" }] },
    { featureType: "transit", stylers: [{ visibility: "off" }] },
];

const options: google.maps.MapOptions = {
    center,
    zoom: 4,
    minZoom: 4,
    styles: styles,
    streetViewControl: false,
};

export { createMap };
