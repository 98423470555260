import { Calendar } from "fullcalendar";
import { getFlightsData, getFilterFields } from "../Filter/getter";
import {
    setFieldDestinations,
    setFieldFrom,
    setFieldTo,
    setHiddenFieldAirports,
    setHiddenFieldCountry,
    setHiddenFieldFrom,
    setHiddenFieldTo,
} from "./setter";
import { sortFlights, populateAirlineFilter, populateCharterersFilter } from "../Filter/process";
import { resetFilterFields, showFilterPanel, hideFilterPanel } from "../Filter/action";
import { populateFlightsPanelDate, populateDestinationAirlines, syncFlights } from "../Calendar/process";
import { checkSearchFields, resetSearchFields, showLandingPanel, showTakeoffPanel } from "./action";
import { renderCalendarEvent } from "../Calendar/render";
import { showDestinationList } from "../Map/action";
import { hidePanelFrom, hidePanelTo } from "./action";
import { ui } from "../../utils/ui";
import { Destination, Airline, Charterer } from "../../utils/types";
import { resetCalendar, updateCalendar, showFlightsPanel, hideFlightsPanel, showCalendar } from "../Calendar/action";
import { showLoader, hideLoader } from "../Loader/action";
import { getHiddenFieldCountry } from "./getter";
import { allDatePickers, initAllDatepickers } from "./datepicker.js";

const populateDestinationFilter = (destinations: Destination[]) => {
    const options = Object.values(destinations)
        .map((destination) => {
            return {
                label: destination.name,
                value: destination.airports,
            };
        })
        .sort((a, b) => (a.label && b.label ? a.label.localeCompare(b.label) : 0));

    //@ts-ignore
    ui?.search?.fields?.destinations?.setOptions(options);
};

const manageCalendarEvents = (
    calendar: Calendar,
    destinations: Destination[],
    airlines: Airline[],
    charterers: Charterer[]
) => {
    calendar.render();
    calendar.setOption("eventContent", (destination) => renderCalendarEvent(destination, airlines));
    calendar.setOption("eventClick", (destination) => {
        if (!destination?.event?.start) return;
        populateFlightsPanelDate(destination.event.start);
        populateDestinationAirlines(destination, airlines, charterers);
        showFlightsPanel();
    });

    /**
     * Synchronisation des vols lors du changement de date
     * ********************************
     */
    calendar.on("datesSet", (config) => syncFlights(calendar, config, destinations));

    /**
     * Fermeture du panneau des vols
     * ********************************
     */
    ui.flightsPanel.actions.close.onclick = async (e: Event) => {
        e.preventDefault();
        hideFlightsPanel();
    };
};

const manageSearchEvents = (calendar: Calendar, destinations: Destination[]) => {
    // @ts-ignore
    VirtualSelect.init({
        ele: ui?.search?.fields?.destinations,
        multiple: true,
        search: true,
        optionsCount: 8,
        placeholder: "Toutes les villes",
        searchPlaceholderText: "Rechercher…",
        allOptionsSelectedText: "Toutes",
        optionsSelectedText: "destinations",
        noSearchResultsText: "Aucune destination trouvée",
    });

    populateDestinationFilter(destinations);

    // /**
    //  * Vérification des champs du formulaire de recherche
    //  * ********************************
    //  */
    // const fields = [
    //     ui.search.fields.destinations,
    //     ui.search.fields.hiddenCountry,
    //     ui.search.fields.hiddenFrom,
    //     ui.search.fields.hiddenTo,
    // ];

    // fields.map((field) => (field.onchange = () => checkSearchFields()));

    /**
     * Soumission du formulaire de recherche
     * ********************************
     */
    ui.search.actions.submit.onclick = async (e: Event) => {
        e.preventDefault();
        resetCalendar(calendar);
        resetFilterFields();
        hideFilterPanel();
        hideFlightsPanel();
        showCalendar(calendar);
    };

    /**
     * Réinitialisation du formulaire de recherche
     * ********************************
     */
    ui.search.actions.reset.onclick = async (e: Event) => {
        e.preventDefault();
        showLoader();
        resetCalendar(calendar);
        resetSearchFields();
        resetFilterFields();
        hideFilterPanel();
        hideFlightsPanel();
        showDestinationList();
        setTimeout(() => hideLoader(), 500);
    };

    /**
     * Réinitialisation du formulaire de recherche
     * ********************************
     */
    ui.search.form.onreset = () => {
        setHiddenFieldCountry(null);
        setHiddenFieldFrom(null);
        setHiddenFieldTo(null);
        allDatePickers?.from?.start.destroy();
        allDatePickers?.from?.back.destroy();
        allDatePickers?.to?.start.destroy();
        allDatePickers?.to?.back.destroy();
        initAllDatepickers();
    };

    /**
     * Manage empty country.
     */
    ui.search.fields.country.onchange = (e: Event) => {
        const target = e.target as HTMLInputElement;
        if (!target.value) {
            setHiddenFieldCountry(null);
            setHiddenFieldAirports(null);
        }
    };

    /**
     * Manage empty country
     * ********************************
     */
    ui.search.fields.hiddenCountry.onchange = () => {
        const country = getHiddenFieldCountry();
        const sortedDestinations = Object.values(destinations)
            .filter((destination) => country === null || destination.countryId === country)
            .map((destination) => {
                return {
                    label: destination.name,
                    value: destination.airports,
                };
            })
            .sort((a, b) => (a.label && b.label ? a.label.localeCompare(b.label) : 0));

        if (!sortedDestinations) return;

        // @ts-ignore
        ui?.search?.fields?.destinations?.setOptions(sortedDestinations);
        // @ts-ignore
        ui?.search?.fields?.destinations?.toggleSelectAll(true);
    };

    /**
     * Gestion du champ 'detinations'
     * ********************************
     */
    ui.search.fields.destinations.onchange = () => {
        checkSearchFields();
    };

    /**
     * Gestion du champ 'from'
     * ********************************
     */
    ui.search.fields.hiddenFrom.onchange = (e: Event) => {
        const target = e.target as HTMLInputElement;
        target?.value ? (ui.search.actions.clearFrom.hidden = false) : (ui.search.actions.clearFrom.hidden = true);

        checkSearchFields();
    };

    /**
     * Gestion du champ 'to'
     * ********************************
     */
    ui.search.fields.hiddenTo.onchange = (e: Event) => {
        const target = e.target as HTMLInputElement;
        target?.value ? (ui.search.actions.clearTo.hidden = false) : (ui.search.actions.clearTo.hidden = true);

        checkSearchFields();
    };

    /**
     * Réinitialisation du champ 'from
     * ********************************
     */
    ui.search.actions.clearFrom.onclick = () => {
        setFieldFrom(null);
        setHiddenFieldFrom(null);
    };

    /**
     * Réinitialisation du champ 'to
     * ********************************
     */
    ui.search.actions.clearTo.onclick = () => {
        setFieldTo(null);
        setHiddenFieldTo(null);
    };

    /**
     * Trigger panels when.
     * *******************************
     */
    ui.search.fields.from.onclick = () => showTakeoffPanel();
    ui.search.fields.to.onclick = () => showLandingPanel();
};

const manageFilterEvents = (
    calendar: Calendar,
    destinations: Destination[],
    airlines: Airline[],
    charterers: Charterer[]
) => {
    /**
     * Ajout des compagnies aériennes dans le filtre 'airline'
     * ********************************
     */
    populateAirlineFilter(airlines);

    /**
     * Ajout des affréteurs dans le filtre 'charterer'
     * ********************************
     */
    populateCharterersFilter(charterers);

    /**
     * Ouverture du filtre
     * ********************************
     */
    ui.filter.actions.open.onclick = async (e: Event) => {
        e.preventDefault();
        hideFlightsPanel();
        showFilterPanel();
    };

    /**
     * Fermeture du filtre
     * ********************************
     */
    ui.filter.actions.close.onclick = async (e: Event) => {
        e.preventDefault();
        hideFilterPanel();
    };

    /**
     * Soumission du formulaire de filtre
     * ********************************
     */
    ui.filter.actions.submit.onclick = async (e: Event) => {
        e.preventDefault();

        const flights = getFlightsData();
        const filterFields = getFilterFields();
        const filteredFlights = sortFlights(flights, filterFields);

        hideFilterPanel();
        showLoader();

        setTimeout(() => {
            resetCalendar(calendar);
            updateCalendar(calendar, filteredFlights, destinations);
            hideLoader();
        }, 500);
    };

    /**
     * Réinitialisation du formulaire de filtre
     * ********************************
     */
    ui.filter.actions.reset.onclick = async (e: Event) => {
        e.preventDefault();
        resetFilterFields();
        ui.filter.actions.submit.click();
    };
};

const manageEvents = () => {
    document.onclick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (
            target?.classList.contains("btn") &&
            target?.dataset.location &&
            target?.dataset.airports &&
            target?.dataset.airports?.length > 0
        ) {
            // setFieldDestinations(target.dataset.location);
            // setHiddenFieldAirports(target.dataset.airports);
            const airports = target.dataset.airports;

            // @ts-ignore
            ui.search.fields.destinations.setValue(airports);
            ui?.search?.actions?.submit.dispatchEvent(new Event("click"));
        }

        /**
         * Hide flights panel on click outside
         */
        if (target?.classList.contains("flights-panel")) {
            e.preventDefault();
            hideFlightsPanel();
        }

        /**
         * Hide filter panel on click outside
         */
        if (target?.classList.contains("filter")) {
            e.preventDefault();
            hideFilterPanel();
        }

        /**
         * Manage clicks outside when panels.
         */
        if (target.id != "edit-from") {
            if (!ui.takeoffPanel.contains(target)) {
                hidePanelFrom();
            }
        }
        if (target.id != "edit-to") {
            if (!ui.landingPanel.contains(target) && !target.classList.contains("datepicker-cell")) {
                hidePanelTo();
            }
        }
    };
};

export { manageCalendarEvents, manageSearchEvents, manageFilterEvents, manageEvents, populateDestinationFilter };
