export default function parkingBookingValidation() {
    const form = document.getElementById("parking-reservation");

    const errors_block = document.querySelector(".parkings--errors") as HTMLElement;
    const translations = window.parking_translations;

    const datefrom = document.querySelector('[data-drupal-selector="edit-datefrom"]') as HTMLInputElement;
    const dateto = document.querySelector('[data-drupal-selector="edit-dateto"]') as HTMLInputElement;

    let parser = new DOMParser();

    if (form) {
        errors_block.innerHTML = "";
        errors_block.hidden = true;
        form.addEventListener(
            "submit",
            function (e: SubmitEvent) {
                errors_block.innerHTML = "";

                let errors = [],
                    errors_text = [];
                let entry_date = form.querySelector('[name="data[entry-date-dp]"]') as HTMLInputElement,
                    exit_date = form.querySelector('[name="data[exit-date-dp]"]') as HTMLInputElement,
                    entry_date_data = form.querySelector('[name="data[entry-date]"]') as HTMLInputElement,
                    exit_date_data = form.querySelector('[name="data[exit-date]"]') as HTMLInputElement,
                    entry_time = form.querySelector('[name="data[entry-time]"]') as HTMLInputElement,
                    exit_time = form.querySelector('[name="data[exit-time]"]') as HTMLInputElement;
                entry_date.classList.remove("error");
                exit_date.classList.remove("error");
                entry_time.classList.remove("error");
                exit_time.classList.remove("error");

                entry_date.closest(".js-form-item")?.classList.remove("error");
                exit_date.closest(".js-form-item")?.classList.remove("error");
                entry_time.closest(".js-form-item")?.classList.remove("error");
                exit_time.closest(".js-form-item")?.classList.remove("error");

                if (!entry_date.value) {
                    entry_date.classList.add("error");
                    entry_date.closest(".js-form-item")?.classList.add("error");
                    errors.push(entry_date);
                    errors_text.push("entry_date");
                }
                if (!exit_date.value) {
                    exit_date.classList.add("error");
                    exit_date.closest(".js-form-item")?.classList.add("error");
                    errors.push(exit_date);
                    errors_text.push("exit_date");
                }
                if (entry_time.value === "default") {
                    entry_time.classList.add("error");
                    entry_time.closest(".js-form-item")?.classList.add("error");
                    errors.push(entry_time);
                    errors_text.push("entry_time");
                }
                if (exit_time.value === "default") {
                    exit_time.classList.add("error");
                    exit_time.closest(".js-form-item")?.classList.add("error");
                    errors.push(exit_time);
                    errors_text.push("exit_time");
                }
                // Check time
                if (entry_date_data.value && entry_time.value && exit_date_data.value && exit_time.value) {
                    const entry_array = entry_date_data.value.split("/");
                    const exit_array = exit_date_data.value.split("/");
                    let entry_complete =
                        entry_array[1] + "/" + entry_array[0] + "/" + entry_array[2] + " " + entry_time.value;
                    let exit_complete =
                        exit_array[1] + "/" + exit_array[0] + "/" + exit_array[2] + " " + exit_time.value;

                    let entry = new Date(entry_complete) as Date,
                        exit = new Date(exit_complete) as Date;

                    if (entry_date_data.value && exit_date_data.value) {
                        // @ts-ignore
                        let diff = (exit - entry) / 3600000;
                        if (diff < 1) {
                            errors.push(entry_time);
                            errors.push(exit_time);
                            errors_text.push("error_dates");
                            entry_time.closest(".js-form-item")?.classList.add("error");
                            exit_time.closest(".js-form-item")?.classList.add("error");
                        }
                    }
                }
                // Check errors.
                if (errors.length > 0) {
                    errors_block.hidden = false;
                    errors_text.forEach((text: string) => {
                        // @ts-ignore
                        let textError = translations[text] as string;
                        let div = `<div class="error-line">${textError}</div>`;
                        let doc = parser.parseFromString(div, "text/html");
                        let insert = doc.querySelector(".error-line") as HTMLElement;
                        errors_block.append(insert);
                    });
                    e.preventDefault();
                } else {
                    errors_block.hidden = true;
                    // Convert date.
                    let entry_data_array = entry_date_data?.value.split(/[/\:]/g);
                    let datefrom_raw = `${entry_data_array[2]}${entry_data_array[1]}${entry_data_array[0]}${entry_time.value}`;
                    datefrom.value = datefrom_raw.replace(/[/\:]/g, '');

                    let exit_data_array = exit_date_data?.value.split(/[/\:]/g);
                    let dateto_raw = `${exit_data_array[2]}${exit_data_array[1]}${exit_data_array[0]}${exit_time.value}`;
                    dateto.value = dateto_raw.replace(/[/\:]/g, '');

                }
            },
            false
        );
    }
}
