export default function parkingToggleDetails() {
    let seeInfo = document.querySelectorAll('.see-info');

    if(seeInfo) {
        seeInfo.forEach((button) => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                let infoToSee = button.closest('.info-to-see');
                infoToSee?.classList.toggle('info-is-visible');
            });
        });
    }
}