import { renderListCard } from "./render";
import { hideLoader } from "../Loader/action";
import { Airline, Destination } from "../../utils/types";
import { ui } from "../../utils/ui";

const List = (airlines: Airline[], destinations: Destination[]) => {
    if (!ui.list) return;

    const sortedDestinations = Object.values(destinations).sort((a, b) =>
        a.name && b.name ? a.name.localeCompare(b.name) : 0
    );

    const renderedCards: string = sortedDestinations
        .map((destination) => renderListCard(destination, airlines))
        .join("");

    ui.list.innerHTML = renderedCards ? renderedCards : "Aucun résultat disponible.";
    hideLoader();
};

export { List };
