import {Loader, LoaderOptions} from 'google-maps';

export default async function itineraryMap() {

    const translations = window.translations;
    const loader = new Loader('AIzaSyDROHqIm0so7OaPI43XCY2hEy7c0-ojsMw', {
        libraries: ['places'],
    });
    let google = await loader.load();

    await initMap();
    await initAutocomplete();

    async function initMap() {

        // Static NTE coordinates.
        const lat = Number(47.1574188);
        const lng = Number(-1.6084203);
        const nteLatLng = {lat: lat, lng: lng};
        const center: google.maps.LatLngLiteral = nteLatLng;

        const mapOptions = {
            center,
            zoom: 11,
            minZoom: 4,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };


        // Create map.
        const map = new google.maps.Map(document.getElementById("itinerary-map-block") as HTMLElement, mapOptions);

        new google.maps.Marker({
            position: nteLatLng,
            map,
            title: translations.nte_airport,
        });
    }

    /*
    * Init autocomplete location input.
    */
    async function initAutocomplete() {

        let itinerary_destinations = [
            document.querySelector(`input[name="itinerary_departure"]`) as HTMLInputElement,
            document.querySelector(`input[name="itinerary_arrival"]`) as HTMLInputElement,
        ];

        itinerary_destinations.forEach(destination => {
            let options = {
                componentRestrictions: {country: "fr"},
            };
            new google.maps.places.Autocomplete(destination, options);
        });

        await initFormListeners();
    }

    async function initFormListeners() {
        let submit_button = document.getElementById("itinerary_submit") as HTMLElement
        submit_button.addEventListener("click", redirectToGMaps);

        let switch_button = document.getElementById("itinerary_switch") as HTMLElement
        switch_button.addEventListener("click", swapArrivalDeparture);
    }

    /*
    * Init autocomplete location input.
    */
    function redirectToGMaps() {

        const itinerary_departure = document.querySelector(`input[name="itinerary_departure"]`) as HTMLInputElement;
        const itinerary_arrival = document.querySelector(`input[name="itinerary_arrival"]`) as HTMLInputElement;
        if (itinerary_departure && itinerary_arrival) {
            const url = "https://www.google.com/maps/dir/" + itinerary_departure.value + "/" + itinerary_arrival.value;
            window.open(url, '_blank');
        }
    }

    /*
    * Init autocomplete location input.
    */
    function swapArrivalDeparture() {

        let itinerary_departure_input = document.querySelector(`input[name="itinerary_departure"]`) as HTMLInputElement;
        let itinerary_arrival_input = document.querySelector(`input[name="itinerary_arrival"]`) as HTMLInputElement;
        let departure_val = itinerary_departure_input.value;
        let arrival_val = itinerary_arrival_input.value;
        if (departure_val || arrival_val) {
            // Swap input values.
            [departure_val, arrival_val] = [arrival_val, departure_val];
            // Set swapped values to input.
            itinerary_departure_input.value = departure_val;
            itinerary_arrival_input.value = arrival_val;
            // Swap disabled attributes.
            if (itinerary_departure_input.getAttribute('disabled')) {
                itinerary_departure_input.removeAttribute('disabled');
                itinerary_arrival_input.setAttribute('disabled', 'disabled');
            } else {
                itinerary_departure_input.setAttribute('disabled', 'disabled');
                itinerary_arrival_input.removeAttribute('disabled');
            }
        }
    }
}
