export default function stickyHeader() {
    let scrollpos = window.scrollY;
    const body = document.querySelector("body");
    const header = document.querySelector(".header") as HTMLElement;
    const header_height = header?.offsetHeight;

    const add_class_on_scroll = () => body?.classList.add("menu-is-reduced");
    const remove_class_on_scroll = () => body?.classList.remove("menu-is-reduced");

    window.addEventListener('scroll', function() {
        scrollpos = window.scrollY;
        if (scrollpos > 8) { add_class_on_scroll() }
        else { remove_class_on_scroll() }
    })
}
