import { populateDestinationCounter, viewModeSwitcher } from "./action";
import { ui } from "../../utils/ui";

const ViewMode = (totalDestinations: number) => {
    if (!ui.viewMode.element || !ui.viewMode.options || !ui.map || !ui.list) return;

    /**
     * Insertion du nombre de destinations dans le "compteur"
     * ********************************
     */
    populateDestinationCounter(totalDestinations);

    /**
     * Changement de mode d'affichage (carte / liste / calendrier)
     * ********************************
     */
    viewModeSwitcher();
};

export { ViewMode };
