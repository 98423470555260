import { EventContentArg } from "fullcalendar";
import { formatToHoursAndMinutes } from "../../utils/functions";
import { Airline, Charterer, Flight } from "../../utils/types";

const renderCalendarEvent = (destination: EventContentArg, airlines: Airline[]) => {
    const destinationName = destination.event.title;
    const destinationAirlines = destination.event.extendedProps.airlines;

    return {
        html: `
            <div class="calendar-item">
                <p class="calendar-item__destination">${destinationName}</p>
                <ul class="calendar-item__airlines">
                    ${destinationAirlines
                        .map((airline: number) =>
                            airlines[airline]?.name && airlines[airline]?.logo
                                ? `<li><img src="${airlines[airline]?.logo}" alt="${airlines[airline]?.name}" title=${airlines[airline]?.name}" /></li>`
                                : `<li><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M194-220h517l97-520H551L194-220ZM80-160l440-640h360L760-160H80Zm500-260q33 0 56.5-23.5T660-500q0-33-23.5-56.5T580-580q-33 0-56.5 23.5T500-500q0 33 23.5 56.5T580-420ZM194-220h517-517Z"/></svg></li>`
                        )
                        .join("")}
                </ul>
            </div>
        `,
    };
};

const renderDestinationAirlines = (destination: any, airlines: Airline[], charterers: Charterer[]): string => {
    const airlineIds: number[] = destination.extendedProps.airlines;
    const flights: Flight[] = destination.extendedProps.flights;

    return Object.values(airlineIds)
        .map((airlineId) => {
            const destinationName = destination.title;
            const destinationCountry = destination.extendedProps.country;
            const airlineName = airlines[airlineId]?.name;
            const airlineLogo = airlines[airlineId]?.logo;
            const flightTime = formatToHoursAndMinutes(destination?.extendedProps?.flightTime);
            const arrivalAirportsIATA = [...new Set(flights.flatMap((flight) => flight.arrivalAirportIATA))].join(", ");

            const renderedTags = [
                ...new Set(
                    flights.flatMap(
                        (flight) =>
                            flight?.airline === airlineId && [
                                flight?.newDestination && "<li><span>N</span> Nouveauté</li>",
                                flight?.shortStayDestination && "<li><span>W</span> Week-end & courts séjours</li>",
                                flight?.type === "charter"
                                    ? "<li><span>C</span> Charter</li>"
                                    : "<li><span>R</span> Régulier</li>",
                            ]
                    )
                ),
            ]
                .filter(Boolean)
                .join("");

            const renderedCharterers = [
                ...new Set(
                    flights.flatMap((flight) =>
                        flight?.charterersIds?.map(
                            (charterId) => flight?.airline === airlineId && charterers[charterId].name
                        )
                    )
                ),
            ]
                .filter(Boolean)
                .join(", ");

            return `
                <div class="airline-card card-destination">
                    <div class="card-destination__header">
                        ${
                            destinationName &&
                            `<div class="card-destination__name">${destinationName}${
                                arrivalAirportsIATA && ` (${arrivalAirportsIATA})`
                            }${destinationCountry && `, ${destinationCountry}`}</div>`
                        }

                        <div class="card-destination__flight-time">${flightTime}</div>
                    </div>

                    <ul class="card-destination__airlines">
                        ${
                            airlineLogo
                                ? `<li><img src="${airlineLogo}" alt="${airlineName}" title="${airlineName}" /></li>`
                                : `<li><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M194-220h517l97-520H551L194-220ZM80-160l440-640h360L760-160H80Zm500-260q33 0 56.5-23.5T660-500q0-33-23.5-56.5T580-580q-33 0-56.5 23.5T500-500q0 33 23.5 56.5T580-420ZM194-220h517-517Z"/></svg></li>`
                        }
                    </ul>

                    ${
                        renderedTags &&
                        `<ul class="card-destination__tags">
                        ${renderedTags}
                        </ul>`
                    }

                    ${
                        renderedCharterers &&
                        `<div class="card-destination__charterers">
                            <div class="card-destination__small-title">Affreteurs</div>
                            <p>${renderedCharterers}</p>
                        </div>`
                    }
                </div>`;
        })
        .join("");
};

export { renderCalendarEvent, renderDestinationAirlines };
