// @ts-ignore
import { Datepicker } from 'vanillajs-datepicker';
// @ts-ignore
import fr from "vanillajs-datepicker/locales/fr";
// @ts-ignore
import en from "vanillajs-datepicker/locales/en-GB";
import {HttpProxy} from "vite";

export default async function userFormInteraction(isRegisterForm: boolean) {

    /**
     * Simulate a click event.
     * @public
     * @param {Element} elem  the element to simulate a click on
     */
    let simulateClick = function (elem: HTMLElement) {
        // Create our event (with options)
        let evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        let canceled = !elem.dispatchEvent(evt);
    };

    let birthday = document.querySelector('[name="field_birthday[0][value][date]"]') as HTMLSelectElement;
    birthday?.classList.add('hidden');
    let birthday_datepicker = document.createElement('input');
    birthday_datepicker.setAttribute('type', 'text');
    birthday_datepicker.setAttribute('placeholder', 'jj/mm/aaaa');
    birthday.parentElement?.insertBefore(birthday_datepicker, birthday);

    const lang = document.documentElement.lang;
    Object.assign(Datepicker.locales, fr, en);
    let minDate = new Date().setFullYear(new Date().getFullYear() - 100)
    let maxDate = new Date().setFullYear(new Date().getFullYear() - 13)
    let datepicker_object = new Datepicker(birthday_datepicker, {
        format: 'dd/mm/yyyy',
        maxDate: maxDate,
        minDate: minDate,
        language: lang,
        autohide: true,
        startView: 3,
    });
    if (birthday.value) {
        let birthday_array = birthday.value.split('-');
        datepicker_object.setDate(birthday_array[2] + '/' + birthday_array[1] + '/' + birthday_array[0]);
    }

    birthday_datepicker.addEventListener('changeDate', function() {
        const date = birthday_datepicker.value.split('/');
        birthday.value = date[2] + '-' + date[1] + '-' + date[0];
    })

    // Switch Yes and No divs.
    let newsletterContainer = document.querySelector('#edit-field-newsletter') as HTMLElement;
    let children = Array.prototype.filter.call(
        newsletterContainer.childNodes,
        function(node) {
            return node.nodeType === 1;
        }
    );
    children[1].parentNode.insertBefore(children[1], children[0]);

    let countrySelect = document.querySelector('#edit-field-country') as HTMLSelectElement;
    let postcodeInput = document.querySelector('#edit-field-postcode-0-value') as HTMLInputElement;
    let city = document.querySelector('#edit-field-city-0-value') as HTMLInputElement;

    // Select France by default.
    if (isRegisterForm) {
        let countrySelectOptions = document.querySelectorAll('#edit-field-country option') as NodeListOf<HTMLOptionElement>;
        countrySelectOptions.forEach(function(option) {
            if (option.text === 'France') {
                countrySelect.value = option.value;
            }
        });
        countrySelect.addEventListener('change', () => {
            displayPhoneField("none");
            let selectedOption = countrySelect.querySelector(`[value="${countrySelect.value}"]`) as HTMLElement;
            let countryName = selectedOption.textContent;
            const internationalPhone = document.querySelector('.iti--allow-dropdown') as HTMLElement;
            const allPhones = internationalPhone?.querySelectorAll('.iti__country');
            const flag = internationalPhone?.querySelector('.iti__selected-flag') as HTMLElement;
            simulateClick(flag);
            allPhones.forEach((element: Element) => {
                const elem = element as HTMLElement;
                const text = elem.textContent;
                if (text && text.includes(`${countryName}`)) {
                    simulateClick(elem);
                }
            });
            displayPhoneField();
        });
    }

    postcodeConstraint(postcodeInput, countrySelect);

    countrySelect.addEventListener('change', function() {
        postcodeConstraint(postcodeInput, countrySelect);
        interactionCityField()
    });
    postcodeInput.addEventListener('keyup', function() {
        interactionCityField()
    });

    // Hide username div and copy email value into username input.
    if (isRegisterForm) {
        let usernameDiv = document?.querySelector('#edit-account .form-item-name') as HTMLElement;
        usernameDiv.style.display = 'none';
        let mail = document.querySelector('#edit-mail') as HTMLInputElement;
        mail.addEventListener('keyup', function() {
            let username_input = usernameDiv.querySelector('input') as HTMLInputElement;
            username_input.value = mail.value;
        });
    }

    const deleteAccountPanel = document.querySelector('.delete-account-panel') as HTMLElement;
    const underlay = document.querySelector('.delete-account-panel .underlay') as HTMLElement;
    const close = deleteAccountPanel?.querySelectorAll('.close-panel') as NodeListOf<HTMLElement>;
    const deleteAccountPanelOpenCTA = document.querySelector(`#delete-account-cta`) as HTMLElement;

    deleteAccountPanelOpenCTA?.addEventListener('click', (event) => {
        event.preventDefault();
        deleteAccountPanel.classList.add('active');
    });

    close?.forEach(closeButton => {
        closeButton.addEventListener('click', () => {
            if (deleteAccountPanel) {
                deleteAccountPanel?.classList.remove('active');
            }
        });
    })

    underlay?.addEventListener('click', () => {
        if (deleteAccountPanel) {
            deleteAccountPanel.classList.remove('active');
            deleteAccountPanel.removeAttribute('style');
        }
    });

    function interactionCityField() {
        let countrySelectText = countrySelect.options[countrySelect.selectedIndex].text;
        if (countrySelectText === 'France' && postcodeInput.value.length === 5) {
            cityAutocomplete(postcodeInput.value);
        }
        else {
            city?.parentElement?.classList.remove('hidden');
            let citySelectElement = document.querySelector('.select-city');
            if (citySelectElement) {
                citySelectElement.remove();
            }
        }
    }

    function postcodeConstraint(postcodeInput: any, countrySelect: any) {
        let countrySelectText = countrySelect.options[countrySelect.selectedIndex].text;
        if (countrySelectText === 'France') {
            postcodeInput.setAttribute('maxlength', '5');
        }
        else {
            postcodeInput.setAttribute('maxlength', '15');
        }
    }

    function cityAutocomplete(postcodeValue: any) {
        const base_url = 'https://maps.googleapis.com/maps/api/geocode/json'
        const key = 'AIzaSyBfFyVQ7_AOr30Sg0lbxQoqEyQtzelt2S4';
        let query = '?components=country:FR|postal_code:' + postcodeValue + '&key=' + key;
        let results = [] as any;

        fetch(base_url + query)
            .then(response => response.json())
            .then(function (json) {
                if (json.status == 'OK') {
                    if (json.results[0].postcode_localities) {
                        json.results[0].postcode_localities.forEach(function(elem: any) {
                            results.push(elem);
                        });
                    }
                    else {
                        json.results[0]['address_components'].forEach(function(elem: any) {
                            if (elem.types[0] == 'locality') {
                                results.push(elem.long_name);
                            }
                        });
                    }

                    // Display the only result into input text.
                    if (results.length === 1) {
                        city.value = results[0];
                    }
                    // Display select tag with options.
                    else if (results.length > 1) {
                        let citySelectElement = document.querySelector('.select-city');
                        if (citySelectElement) {
                            let citySelectError = citySelectElement?.parentElement?.querySelector('.message-error') as HTMLDivElement;
                            if (citySelectError) {
                                citySelectError.remove();
                            }
                            citySelectElement.remove();
                        }

                        city?.parentElement?.classList.add('hidden');

                        const citySelectNewElement = document.createElement('select') as HTMLSelectElement;
                        citySelectNewElement.classList.add('select-city');
                        citySelectNewElement.id = 'select-city';
                        let cityOptionsElement = document.createElement('option') as HTMLOptionElement;
                        cityOptionsElement.text =  window.userFormTranslations.choose_city;
                        cityOptionsElement.value = '';
                        citySelectNewElement.appendChild(cityOptionsElement);
                        results.forEach(function(city: any) {
                            let cityOptionsElement = document.createElement('option') as HTMLOptionElement;
                            cityOptionsElement.text = city;
                            cityOptionsElement.value = city;
                            citySelectNewElement.appendChild(cityOptionsElement);
                        });
                        city?.parentElement?.parentElement?.append(citySelectNewElement);

                        // By default, set first option value into input text city.
                        city.value = results[0];

                        citySelectNewElement.addEventListener('change', function(e) {
                            let selectedOption = e.target as HTMLOptionElement;
                            city.value = selectedOption.value;
                        });
                    }
                }
            })
            .catch(error => console.log(error.message))
    }

    // Affichage du champ téléphone seulement si une préférence de communication par sms est cochée
    const radioBtns = document.querySelectorAll("#group-communication-preference .form-radio") as NodeListOf<HTMLInputElement>;
    const hiddenDiv = document.getElementById("edit-field-phone-wrapper") as HTMLDivElement;
    const displayPhoneField = (forcedValue? : string) => {
        // Vérifier si au moins un bouton radio avec la valeur sms est déjà coché au chargement de la page
        const isAtLeastOneSms = Array.from(radioBtns).some((btn) => {
            return btn.checked && btn.value === "sms";
        });
        hiddenDiv.style.display = isAtLeastOneSms ? "block" : "none";
        if (forcedValue) {
            hiddenDiv.style.display = forcedValue;
        }

        const label = document.querySelector("#edit-field-phone-wrapper label") as HTMLLabelElement;
        label.innerText = window.userFormTranslations.phone_description;
    };

    // Écouter les changements dans les boutons radio
    radioBtns.forEach((radioBtn) => {
        radioBtn.addEventListener("change", () => {
            displayPhoneField();
        });
    });

    displayPhoneField();

}
