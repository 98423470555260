export default function multiLevelMenu() {
    const footerMenu = document.querySelector('.footer__menu-wrapper') as HTMLElement;
    if (window.matchMedia("(max-width: 992px)").matches) {
        footerMenu.addEventListener('click', function (event) {

            let elem = event.target as HTMLElement;
            if (elem.closest('.has-children__trigger--mobile') && elem.parentElement?.classList.contains('menu__link--0')){
                event.preventDefault();
                elem.closest('.parent')?.classList.add('has-open-submenu');
                elem.closest('.has-children')?.classList.add('is-expanded');
                const parent = elem.closest('.has-children.is-expanded');
                let height = parent?.querySelector('.parent.submenu')?.clientHeight;
                if (height) {
                    footerMenu.style.height = (height + 30) + 'px';
                }
            }
            if (elem.closest('.back')){
                event.preventDefault();
                elem.closest('.has-children.is-expanded')?.classList.remove('is-expanded');
                elem.closest('.parent.has-open-submenu')?.classList.remove('has-open-submenu');
                footerMenu.removeAttribute('style');
            }
            return false;
        }, false);
    }
}
