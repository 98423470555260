import { ui } from "../../utils/ui";

const populateDestinationCounter = (count: number) => {
    if (!count || !ui?.counter) return;
    ui.counter.innerHTML = count.toString();
};

const viewModeSwitcher = () => {
    Array.from(ui.viewMode.options).map((option) => {
        option.onclick = () => {
            const mode = option.dataset.proflightsDisplayOption;

            if (!mode) return;

            const selectedOption = ui?.viewMode?.element?.querySelector("[data-selected]") as HTMLLIElement;

            delete selectedOption?.dataset.selected;
            option.dataset.selected = "";
            ui.module.dataset.proflightsDisplay = mode;
        };
    });
};

export { populateDestinationCounter, viewModeSwitcher };
