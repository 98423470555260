import { lang } from "./variables";

const allAccordions = () => {
    const accordions = document.querySelectorAll(".accordion-trigger");
    if (accordions.length > 0) {
        accordions.forEach((element: Element) => {
            const elem = element as HTMLElement;
            if (elem.classList.contains("accordion-trigger")) {
                elem.onclick = (event: MouseEvent) => {
                    const trigger = event.target as HTMLElement;
                    if (trigger.classList.contains("open")) {
                        trigger.classList.remove("open");
                    } else {
                        trigger.classList.add("open");
                    }
                };
            }
        });
    }
};

const enabledSaveButton = () => {
    const destinations = Array.from(
        document.querySelectorAll('.destination-by-country input[type="checkbox"]') as NodeListOf<HTMLInputElement>
    );
    const submit = document.querySelector("button.add-all-to-promo") as HTMLButtonElement;

    if (!Array.isArray(destinations) || !destinations.length || !submit) return;

    destinations.map((destination) => {
        const checked = destinations.filter((option) => option.checked);

        if (checked.length) {
            submit.disabled = false;
        }

        destination.onchange = () => {
            const checked = destinations.filter((option) => option.checked);

            if (checked.length) {
                submit.disabled = false;
            } else {
                submit.disabled = true;
            }
        };
    });
};

const inpromo = () => {
    allAccordions();
    enabledSaveButton();

    // Trigger save new promo.
    const addAllPromos = document.querySelector(".add-all-to-promo") as HTMLButtonElement;
    if (addAllPromos) {
        addAllPromos.onclick = () => {
            const allSelected = document.querySelectorAll('[name^="desti-"]:checked');
            addAllItemToPromo(allSelected);
            return false;
        };
    }

    const checkListingPromos = document.querySelectorAll('[name^="alertpromo-"]');
    if (checkListingPromos.length > 0) {
        checkListingPromos.forEach((elem: Element) => {
            const input = elem as HTMLInputElement;
            input.onchange = () => {
                if (input.checked === false) {
                    const allSelected = document.querySelectorAll('[name^="alertpromo-"]:checked');
                    addAllItemToPromo(allSelected);
                }
                return false;
            };
        });
    }
};

const addAllItemToPromo = (allSelected: NodeListOf<Element>) => {
    const addDestinationPanel = document.querySelector(".wishlist-panel__add") as HTMLElement;
    addDestinationPanel?.setAttribute("style", "margin-right: 0");

    let values = [] as any;
    allSelected.forEach((element: Element) => {
        const check = element as HTMLInputElement;
        values.push(check.value);
    });

    fetch(`/${lang}/ajax/add-multiple-promo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .then(function (json) {
            if (json.status == true) {
                window.location.reload();
            }
        });
};

export default inpromo;
