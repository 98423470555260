import { formatToHoursAndMinutes } from "../../utils/functions.js";
import { Airline, Destination } from "../../utils/types.js";

const renderListCard = (destination: Destination, allAirlines: Airline[]): string => {
    const name = destination?.name;
    const country = destination?.country;
    const flightTime = destination?.flightTime && formatToHoursAndMinutes(destination.flightTime);
    const airports = destination?.airports;
    const airlines = destination?.airlines
        ?.map((airline: number) =>
            allAirlines[airline]?.name && allAirlines[airline]?.logo
                ? `<li><img src="${allAirlines[airline]?.logo}" alt="${allAirlines[airline]?.name}" title=${allAirlines[airline]?.name}" /></li>`
                : `<li><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M194-220h517l97-520H551L194-220ZM80-160l440-640h360L760-160H80Zm500-260q33 0 56.5-23.5T660-500q0-33-23.5-56.5T580-580q-33 0-56.5 23.5T500-500q0 33 23.5 56.5T580-420ZM194-220h517-517Z"/></svg></li>`
        )
        .join("");

    return `
        <div class="card-destination">
            <div class="card-destination__header">
                <p class="card-destination__name">${name}, ${country}</p>
                <p class="card-destination__flight-time">${flightTime}</p>
            </div>
            <ul class="card-destination__airlines">${airlines}</ul>
            <div class="card-destination__btn btn" data-location="${name}" data-airports="${airports}">
                Voir tous les prochains départs
                <span class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" preserveAspectRatio="xMinYMin">
                        <use xlink:href="#img-arrow-right"></use>
                    </svg>
                </span>
            </div>
        </div>
    `;
};

export { renderListCard };
