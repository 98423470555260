import { Loader } from "google-maps";
import { createMap } from "./map";
import { addMarkers } from "./markers";
import { addClusters } from "./clusters";
import { Airline, Destination } from "../../utils/types";
import { ui } from "../../utils/ui";
import { hideLoader } from "../Loader/action";

const Map = async (airlines: Airline[], destinations: Destination[]): Promise<void> => {
    if (!ui.map) return;

    const googleMapsLoader: string = import.meta.env.VITE_GOOGLE_MAPS_LOADER;

    if (!googleMapsLoader) return;

    const loader = new Loader(googleMapsLoader);
    const google = await loader.load();
    const map = createMap(google);
    const infoWindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(-20, -45),
    });
    const markers = addMarkers(google, map, infoWindow, airlines, destinations);
    addClusters(map, markers);

    google.maps.event.addListenerOnce(map, "tilesloaded", () => hideLoader());
    google.maps.event.addListener(map, "click", () => infoWindow.close());
    google.maps.event.addListener(map, "zoom_changed", () => infoWindow.close());
    google.maps.event.addListener(map, "dragstart", () => infoWindow.close());
};

export { Map };
