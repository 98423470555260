/**
 * Import des librairies
 * ******************************
 */
import Swiper, { Navigation, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "reset-css";

/**
 * Import des styles et des modules
 * ****************************************
 */
import "./../sass/style.scss";
import stickyHeader from "./modules/stickyHeader";
import multiLevelMenu from "./modules/multiLevelMenu";
import burgerMenu from "./modules/burgerMenu";
import menuFooter from "./modules/menuFooter";
import destinationMap from "./modules/destinationMap";
import calendar from "./modules/calendar";
import videoLoaded from "./modules/videoLoaded";
import destinationHeader from "./modules/destinationHeader";
import bookingPanel from "./modules/bookingPanel";
import scrollToTop from "./modules/scrollToTop";
import itineraryMap from "./modules/itineraryMap";
import datePicker from "./modules/datePicker";
import parkingBookingValidation from "./modules/parkingBookingValidation";
import fixedParkingBooking from "./modules/fixedParkingBooking";
import parkingPage from "./modules/parkingPage";
import parkingToggleDetails from "./modules/parkingToggleDetails";
import parkingBookingMobile from "./modules/parkingBookingMobile";
import alerts from "./modules/alerts";
import guidance from "./modules/guidance";
import dayflights from "./modules/dayflights";
import dayflightsSearch from "./modules/dayflightsSearch";
import dayflightsEngineInteraction from "./modules/dayflightsEngineInteraction";
import accountMenu from "./modules/accountMenu";
import followedFlights from "./modules/followedFlights";
import bookingList from "./modules/bookingList";
import followFlightAction from "./modules/followFlightAction";
import userFormInteraction from "./modules/userFormInteraction";
import userFormValidation from "./modules/userFormValidation";
import inwhishlist from "./modules/inwishlist";
import inpromo from "./modules/AlertPromo/inpromo";
import addtopromo from "./modules/AlertPromo/addtopromo";
import loginPanel from "./modules/loginPanel";
import bookingDetail from "./modules/bookingDetail";
import Proflights from "./modules/Proflights";
import ManageDestination from "./modules/ManageDestination";
import BookingEngine from "./modules/BookingEngine";

window.onload = () => loaderInit();

/**
 * Initialisation des modules
 * ******************************
 */
const loaderInit = (timeout?: any) => {
    /**
     * Calendrier des vols pro
     * ******************************
     */
    Proflights();

    const accountMenuTabs = document.querySelector(".account-menu__tabs") as HTMLElement;
    const parkingsPlan = document.querySelectorAll("[data-parking]");
    const parkingBooking = document.querySelector(".parking-booking") as HTMLElement;
    const destinationMapBlock = document.getElementById("destination-map");
    const destiSliders = document.querySelectorAll(".destinations-slider");
    const destiPagination = document.querySelectorAll(".destinations-slider__pagination");
    const discoverSlider = document.querySelector(".discover-slider") as HTMLElement;
    const instaSlider = document.querySelector(".insta-slider") as HTMLElement;
    const hotelSlider = document.querySelector(".hotel-slider") as HTMLElement;
    const selectionsSliders = document.querySelectorAll(".selections-slider");
    const topContentGallery = document.querySelectorAll(".top-content__gallery");
    const picturesGallerySlider = document.querySelector(".pictures-gallery__slider") as HTMLElement;
    const masonryContainer = document.querySelector(".masonry") as HTMLElement;
    const editorialArea = document.querySelector(".editorial__container") as HTMLElement;
    const suggestionsSlider = document.querySelector(".suggestions__slider") as HTMLElement;
    const itineraryMapBlock = document.getElementById("itinerary-map-block");
    const dayflightsBlock = document.getElementById("dayflights-block");
    const inputs = document.querySelectorAll(".js-datepicker");
    const dayflightsEngine = document.getElementById("dayflights-engine");
    let guidanceElem = document.getElementById("guidance") as HTMLElement;
    const accountFollowedFlights = document.getElementById("followed-flights-block");
    const accountBookingList = document.getElementById("account-booking-list");
    const accountBookingFlightDetails = document.querySelector(".booking-full--flight");
    const registerFormBlock = document.getElementById("user-register-form");
    const updateProfileFormBlock = document.getElementById("user-edit-account-form");
    const updatePasswordFormBlock = document.getElementById("user-update-password-form");
    const loginFormBlock = document.getElementById("user-login-form");
    const resetPasswordRequestFormBlock = document.querySelector(
        ".page-account-form--forgot-password form"
    ) as HTMLElement;
    const addToWishlists = document.querySelectorAll(".actions .add-to-wishlist:not(.open-login)");
    const wishPanel = document.querySelector(".wishlist-panel") as HTMLElement;
    const deleteAccountPanel = document.querySelector(".delete-account-panel") as HTMLElement;
    const loginPanelElement = document.querySelector(".login-panel") as HTMLElement;
    const bookingDetailsPage = document.querySelector(".booking-full") as HTMLElement;
    const priceAlertsUser = document.querySelector(".price-alerts-user") as HTMLElement;
    const addElemToPromo = document.querySelector(".add-to-promo:not(.open-login)") as HTMLElement;
    const pageDestination = document.querySelector(".page-node-type-destination") as HTMLElement;
    const followedFlightsAction = document.querySelector("section.followed-flights") as HTMLElement;

    const parentTargetNode = document.documentElement;
    const config = { childList: true, subtree: true };

    if (timeout) {
        clearTimeout(timeout);
    }

    stickyHeader();
    fixedParkingBooking();
    multiLevelMenu();
    burgerMenu();
    menuFooter();
    scrollToTop();
    parkingToggleDetails();
    parkingBookingMobile();
    alerts();
    BookingEngine();

    // Check if parking plan
    if (parkingsPlan.length > 1) {
        parkingPage();
    }

    // Check if datepicker.
    if (inputs.length > 1) {
        datePicker(inputs);
    }
    parkingBookingValidation();

    // Guidance page.
    if (guidanceElem) {
        guidance();
    } else {
        const callback = (mutationsList: MutationRecord[]) => {
            for(let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((node) => {
                        const target = node as HTMLElement;
                        if (target.id === "block-guidanceblock") {
                            let guidanceElem = document.getElementById("guidance") as HTMLElement;
                            if (guidanceElem) {
                                guidance();
                            }
                        }
                    });
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(parentTargetNode, config);
    }

    // In Wishlist.
    if (addToWishlists.length > 0) {
        inwhishlist(addToWishlists);
    }
    if (addToWishlists.length == 0 && wishPanel) {
        inwhishlist(addToWishlists);
    }

    // Fonction de rappel pour quand les mutations sont observées
    const callback = (mutationsList: MutationRecord[]) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach((node) => {
                    const target = node as HTMLElement;
                    if (target.id == "block-insert-destinations") {
                        const newList = document.querySelectorAll(".actions .add-to-wishlist:not(.open-login)");
                        inwhishlist(newList);
                    }
                });
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(parentTargetNode, config);

    // In promo.
    if (priceAlertsUser) {
        inpromo();
    }
    // Add to promo.
    if (addElemToPromo) {
        addtopromo(addElemToPromo);
    }

    if (scrollToTop) {
        scrollToTop();
    }

    if (loginPanelElement) {
        loginPanel();
    }

    const calendarElement = document.querySelector<HTMLElement>(".calendar");
    if (calendarElement) {
        calendar("", "", false);
    }

    if (destinationMapBlock) {
        destinationMap();
    }

    const BookingPanelElement = document.getElementById("booking-panel") as HTMLLinkElement;
    if (BookingPanelElement) {
        bookingPanel();
    }

    const heroVideo = document.querySelector<HTMLVideoElement>(".hero-video");
    if (heroVideo) {
        videoLoaded(heroVideo);
    }

    // Destination header
    if (topContentGallery) {
        destinationHeader();
    }

    // Parkings booking
    if (parkingBooking) {
        fixedParkingBooking();
    }

    // Langswitcher
    const langSwitcher = document.querySelectorAll(".language-switcher-language-url");
    const choseLang = () => {
        langSwitcher.forEach((item: Element) => {
            item.classList.toggle("dropped");
        });
    };

    langSwitcher.forEach((item: Element) => {
        item.addEventListener("click", choseLang);
        window.addEventListener("click", (ev) => {
            if (ev.target !== item && ev.target !== item.querySelector("div")) {
                item.classList.remove("dropped");
            }
        });
    });

    // Slider destinations homepage
    if (destiSliders) {
        destiSliders.forEach((sliderDestinations: Element, index: number) => {
            let sliderLength = sliderDestinations.children[0].children.length;
            let result = sliderLength > 1 ? true : false;
            const sliderDestinationElem = sliderDestinations as HTMLElement;
            const swipe = new Swiper(sliderDestinationElem, {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: 16,

                breakpoints: {
                    992: {
                        spaceBetween: 24,
                    },
                },

                // Navigation arrows
                navigation: {
                    nextEl: ".destinations-slider__nav--next",
                    prevEl: ".destinations-slider__nav--prev",
                },

                // Pagination bullets
                pagination: {
                    // @ts-ignore
                    el: destiPagination[index],
                    type: "bullets",
                    clickable: true,
                },

                // configure Swiper to use modules
                modules: [Navigation, Pagination, EffectFade],
            });
        });
    }

    // Slider discover homepage
    if (discoverSlider) {
        const discoverSwipe = new Swiper(".discover-slider", {
            loop: true,
            centeredSlides: true,
            spaceBetween: 16,
            slideToClickedSlide: true,

            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: ".discover-slider__nav--next",
                prevEl: ".discover-slider__nav--prev",
            },

            // Pagination bullets
            pagination: {
                el: ".discover-slider__pagination",
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    }

    // Slider instagram
    if (instaSlider) {
        const instaSwipe = new Swiper(".insta-slider", {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 16,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,

            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: ".insta-slider__nav--next",
                prevEl: ".insta-slider__nav--prev",
            },

            // Pagination bullets
            pagination: {
                el: ".insta-slider__pagination",
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    }

    // Top content Gallery
    if (topContentGallery) {
        const totalSlide = document.querySelectorAll(".top-content__gallery .swiper-slide").length;
        let topContentSwipe = new Swiper(".top-content__gallery", {
            pagination: {
                el: ".gallery-pagination--bullet-mobile",
                type: "bullets",
                clickable: true,
            },
            // Navigation arrows
            navigation: {
                nextEl: ".gallery-nav--next",
                prevEl: ".gallery-nav--prev",
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });

        // Gestion de la pagination en fraction
        let current = topContentSwipe.realIndex + 1;
        if (current > totalSlide) current = 1;
        let idx = current < 10 ? current : current;
        let tdx = totalSlide < 10 ? totalSlide : totalSlide;
        let galleryPagination = document.querySelectorAll(".gallery-pagination");
        // @ts-ignore
        for (let fraction of galleryPagination) {
            fraction.innerHTML = idx + "/" + tdx;
        }

        topContentSwipe.on("slideChange", function () {
            let current = topContentSwipe.realIndex + 1;
            if (current > totalSlide) current = 1;
            let idx = current < 10 ? current : current;
            let tdx = totalSlide < 10 ? totalSlide : totalSlide;
            let galleryPagination = document.querySelectorAll(".gallery-pagination");
            // @ts-ignore
            for (let fraction of galleryPagination) {
                fraction.innerHTML = idx + "/" + tdx;
            }
        });
    }

    // Slider hotel destination page
    if (hotelSlider) {
        const hotelSwipe = new Swiper(".hotel-slider", {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 16,

            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: ".hotel-slider__nav--next",
                prevEl: ".hotel-slider__nav--prev",
            },

            // Pagination bullets
            pagination: {
                el: ".hotel-slider__pagination",
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    }

    // Pictures gallery
    if (picturesGallerySlider) {
        const picturesGallerySwipe = new Swiper(".pictures-gallery__slider", {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 16,

            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: ".pictures-gallery-slider__nav--next",
                prevEl: ".pictures-gallery-slider__nav--prev",
            },

            // Pagination bullets
            pagination: {
                el: ".pictures-gallery__pagination",
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    }

    // Suggestions slider
    if (suggestionsSlider) {
        const suggestionsSwipe = new Swiper(".suggestions__slider", {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 16,

            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },

            // Pagination bullets
            pagination: {
                el: ".suggestions__pagination",
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    }

    // Text image slider with multiple occurences handling
    let textImageSliders = document.querySelectorAll(".rc-text-image__slider");
    let textImageSliderPagination = document.querySelectorAll(".rc-text-image__pagination");

    textImageSliders.forEach((slider: Element, index) => {
        let textImageSlidersLength = slider.children[0].children.length;
        let result = textImageSlidersLength > 1 ? true : false;

        // @ts-ignore
        const swiperTextImage = new Swiper(slider, {
            loop: false,

            // Navigation arrows
            navigation: {
                nextEl: ".rc-text-image__nav--next",
                prevEl: ".rc-text-image__nav--prev",
            },

            pagination: {
                el: textImageSliderPagination[index],
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    });

    // Block slider images with multiple occurences handling
    let imageSliders = document.querySelectorAll(".rc-slider-images__slider");
    let imageSlidersPagination = document.querySelectorAll(".rc-slider-images__pagination");

    imageSliders.forEach((slider: Element, index) => {
        let imageSlidersLength = slider.children[0].children.length;
        let result = imageSlidersLength > 1 ? true : false;

        // @ts-ignore
        const swiperImage = new Swiper(slider, {
            loop: false,

            // Navigation arrows
            navigation: {
                nextEl: ".rc-slider-images__nav--next",
                prevEl: ".rc-slider-images__nav--prev",
            },

            pagination: {
                el: imageSlidersPagination[index],
                type: "bullets",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    });

    // Block slider text images with multiple occurences handling
    let imageTextSliders = document.querySelectorAll(".rc-slider-text-images__slider");

    imageTextSliders.forEach((slider: Element) => {
        let imageTextSlidersLength = slider.children[0].children.length;
        let result = imageTextSlidersLength > 1 ? true : false;

        // @ts-ignore
        const swiperImageText = new Swiper(slider, {
            loop: false,
            spaceBetween: 16,
            slidesPerView: "auto",

            breakpoints: {
                448: {
                    slidesPerView: 1,
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: ".rc-slider-text-images__nav--next",
                prevEl: ".rc-slider-text-images__nav--prev",
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    });

    // Store slider with multiple occurences handling
    let storeSliders = document.querySelectorAll(".rc-store__slider");
    if (storeSliders) {
        let storeSliderPagination = document.querySelectorAll(".rc-store__pagination");

        storeSliders.forEach((slider: Element, index) => {
            let storeSlidersLength = slider.children[0].children.length;
            let result = storeSlidersLength > 1 ? true : false;

            // @ts-ignore
            const swiperStore = new Swiper(slider, {
                loop: false,

                // Navigation arrows
                navigation: {
                    nextEl: ".rc-store__nav--next",
                    prevEl: ".rc-store__nav--prev",
                },

                pagination: {
                    el: storeSliderPagination[index],
                    type: "bullets",
                    clickable: true,
                },

                // configure Swiper to use modules
                modules: [Navigation, Pagination, EffectFade],
            });
        });
    }

    // Slider interest point with multiple occurences handling
    let interestPointSliders = document.querySelectorAll(".rc-interest-points__slider");
    if (interestPointSliders) {
        let interestPointSliderPagination = document.querySelectorAll(".rc-interest-points__pagination");

        interestPointSliders.forEach((slider: Element, index) => {
            let interestPointSlidersLength = slider.children[0].children.length;
            let result = interestPointSlidersLength > 1 ? true : false;

            // @ts-ignore
            const swiperInterestPoint = new Swiper(slider, {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: 16,
                slidesOffsetAfter: 16,

                breakpoints: {
                    992: {
                        spaceBetween: 24,
                        slidesOffsetAfter: 0,
                    },
                },

                // Navigation arrows
                navigation: {
                    nextEl: ".rc-interest-points__nav--next",
                    prevEl: ".rc-interest-points__nav--prev",
                },

                pagination: {
                    el: interestPointSliderPagination[index],
                    type: "bullets",
                    clickable: true,
                },

                // configure Swiper to use modules
                modules: [Navigation, Pagination, EffectFade],
            });
        });
    }

    // Slider cross content with multiple occurences handling
    let crossContentSliders = document.querySelectorAll(".rc-cross-content__slider");
    if (crossContentSliders) {
        let crossContentSliderPagination = document.querySelectorAll(".rc-cross-content__pagination");

        crossContentSliders.forEach((slider: Element, index) => {
            let crossContentSlidersLength = slider.children[0].children.length;
            let result = crossContentSlidersLength > 1 ? true : false;

            // @ts-ignore
            const swiperCrossContent = new Swiper(slider, {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: 16,

                breakpoints: {
                    992: {
                        spaceBetween: 24,
                    },
                },

                pagination: {
                    el: crossContentSliderPagination[index],
                    type: "bullets",
                    clickable: true,
                },

                // configure Swiper to use modules
                modules: [Pagination, EffectFade],
            });
        });
    }

    // Slider rubric
    const rubricSlider = document.querySelector(".rubric__slider") as HTMLElement;
    if (rubricSlider) {
        const rubricSwipe = new Swiper(".rubric__slider", {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 16,

            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: ".rubric-slider__nav--next",
                prevEl: ".rubric-slider__nav--prev",
            },

            // configure Swiper to use modules
            modules: [Navigation, EffectFade],
        });
    }

    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia("(max-width:768px)");

    // keep track of swiper instances to destroy later
    let noMobileSlider: any;

    const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
            // clean up old instances and inline styles when available
            if (noMobileSlider !== undefined) noMobileSlider.destroy(true, true);

            // or/and do nothing
            return;

            // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {
            // fire small viewport version of swiper
            return enableSwiper();
        }
    };

    const enableSwiper = function () {
        selectionsSliders.forEach((slider: Element) => {
            let selectionsSlidersLength = slider.children[0].children.length;
            let resultSelections = selectionsSlidersLength > 1 ? true : false;

            // @ts-ignore
            noMobileSlider = new Swiper(slider, {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: 16,

                breakpoints: {
                    992: {
                        spaceBetween: 24,
                    },
                },

                // Navigation arrows
                navigation: {
                    nextEl: ".selections-slider__nav--next",
                    prevEl: ".selections-slider__nav--prev",
                },

                // configure Swiper to use modules
                modules: [Navigation, Pagination, EffectFade],
            });
        });
    };
    // keep an eye on viewport size changes.
    breakpoint.addListener(breakpointChecker);
    // kickstart
    breakpointChecker();

    // Tabs content
    const theTabs = document.querySelectorAll(".nav-tabs__link");
    function theTabClicks(tabClickEvent: any) {
        const clickedTab = tabClickEvent.currentTarget;
        const tabParent = tabClickEvent.currentTarget?.parentNode?.parentNode?.parentNode;
        const theTabs = tabParent.querySelectorAll(".nav-tabs__link");
        for (let i = 0; i < theTabs.length; i++) {
            theTabs[i].classList.remove("current");
        }

        clickedTab.classList.add("current");
        const anchorReference = clickedTab.querySelector("a") as HTMLElement;
        const activePaneId = anchorReference.getAttribute("href");

        if (activePaneId?.search(/#/) === -1) {
            return;
        }

        tabClickEvent.preventDefault();
        const contentPanes = tabParent.querySelectorAll(".tab__pane");
        for (let i = 0; i < contentPanes.length; i++) {
            contentPanes[i].classList.remove("current");
        }
        const activePane = tabParent.querySelector(activePaneId);
        activePane?.classList.add("current");
    }
    for (let i = 0; i < theTabs.length; i++) {
        theTabs[i].addEventListener("click", theTabClicks);
    }

    // Masonry layout on editorial pages
    if (masonryContainer) {
        let allItems = [];

        function resizeGridItem(item: any) {
            let masonry = document.getElementsByClassName("masonry")[0];
            let rowHeight = parseInt(window.getComputedStyle(masonry).getPropertyValue("grid-auto-rows"));
            let rowGap = parseInt(window.getComputedStyle(masonry).getPropertyValue("grid-row-gap"));
            let rowSpan = Math.ceil(
                (item.querySelector(".masonry-content").getBoundingClientRect().height + rowGap) / (rowHeight + rowGap)
            );
            item.style.gridRowEnd = "span " + rowSpan;
        }

        function resizeAllGridItems() {
            allItems = document.getElementsByClassName("masonry-item") as any;
            for (let x = 0; x < allItems.length; x++) {
                resizeGridItem(allItems[x]);
            }
        }

        function resizeInstance(instance: any) {
            let item = instance.elements[0];
            resizeGridItem(item);
        }

        // @ts-ignore
        window.onload = resizeAllGridItems();
        window.addEventListener("resize", resizeAllGridItems);

        // Get element position relative to its parent
        const apps = document.querySelectorAll(".masonry-item");
        const parentPos = document.getElementsByClassName("masonry")[0].getBoundingClientRect();

        const positions = [];

        Array.prototype.forEach.call(apps, function (app) {
            var positionInfo = app.getBoundingClientRect();
            var relativePos = positionInfo.left - parentPos.left;

            if (relativePos == 0) {
                app.classList.add("is-narrow");
            }
        });
    }

    // Load more button on editorial content if there are more than 10 blocks
    if (editorialArea) {
        const work = document.querySelector(".editorial__container");

        let loadMore = document.getElementById("editorial-more");
        let footer = document.querySelector(".editorial__footer") as HTMLElement;

        let maxItems = 10;
        let loadItems = 10;
        let hiddenClass = "sr-only";
        let hiddenItems = Array.from(document.querySelectorAll(".sr-only"));
        if (work) {
            const items = Array.from(work.querySelectorAll(".editorial__item"));
            items.forEach(function (item: any, index: any) {
                if (index > maxItems - 1) {
                    item.classList.add(hiddenClass);
                }
            });
        }

        if (loadMore) {
            loadMore.addEventListener("click", function () {
                [].forEach.call(document.querySelectorAll("." + hiddenClass), function (item: Element, index) {
                    if (index < loadItems) {
                        item.classList.remove(hiddenClass);
                    }

                    if (document.querySelectorAll("." + hiddenClass).length === 0) {
                        if (footer) {
                            footer.style.display = "none";
                        }
                    }
                });
            });
        }

        if (document.querySelectorAll("." + hiddenClass).length === 0) {
            if (footer) {
                footer.style.display = "none";
            }
        }
    }

    if (itineraryMapBlock) {
        itineraryMap();
    }

    if (dayflightsEngine) {
        dayflightsEngineInteraction();
    }

    if (dayflightsBlock) {
        dayflights();
        dayflightsSearch();
    }

    if (accountFollowedFlights) {
        followedFlights(accountFollowedFlights);
    }

    if (registerFormBlock) {
        userFormInteraction(true);
        userFormValidation("registerForm");
    }

    if (updateProfileFormBlock) {
        userFormInteraction(false);
        userFormValidation("updateProfile");
    }

    if (updatePasswordFormBlock) {
        userFormValidation("updatePassword");
    }

    if (resetPasswordRequestFormBlock) {
        userFormValidation("resetPasswordRequest");
    }

    if (loginFormBlock) {
        userFormValidation("loginForm");
    }

    if (accountMenuTabs) {
        accountMenu();
    }

    if (accountBookingList) {
        bookingList();
    }

    if (accountBookingFlightDetails) {
        followFlightAction();
    }
    if (followedFlightsAction) {
        followFlightAction();
    }

    const messages = document.querySelector(".messages-notice") as HTMLElement;
    if (messages) {
        const closeMessages = messages.querySelector(".close") as HTMLElement;
        closeMessages.addEventListener("click", () => {
            messages.remove();
        });
    }

    if (bookingDetailsPage) {
        bookingDetail();
    }

    const cookieRenew = document.querySelector('[href="#cookies-renew"]') as HTMLElement;
    if (cookieRenew) {
        cookieRenew.onclick = (e: MouseEvent) => {
            e.preventDefault();
            // @ts-ignore
            if (typeof Cookiebot === "undefined") {
                return;
            }
            // @ts-ignore
            Cookiebot.renew();
            return false;
        };
    }

    /*
     Manage page destination.
     */
    if (pageDestination) {
        ManageDestination();
    }
};
