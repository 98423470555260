import { createSliderFilters } from './range';

const renderAirlineFilterItem = (id: number, name: string): string => {
    return `
        <div class="option">
            <input class="input" type="checkbox" id="airline-${id}" name="airline" value="${id}">
            <label class="label" for="airline-${id}">${name}</label>
        </div>
    `;
};

const renderCharterersFilterItem = (id: number, name: string): string => {
    return `
        <div class="option">
            <input class="input" type="checkbox" id="charterer-${id}" name="charterer" value="${id}">
            <label class="label" for="charterer-${id}">${name}</label>
        </div>
    `;
};

createSliderFilters();

export { renderAirlineFilterItem, renderCharterersFilterItem };
