export default function bookingDetail() {

    const ctaBackToList = document.querySelector(`.booking-full__return`) as HTMLElement;
    const params = new URLSearchParams(window.location.search);

    // Set get params from bookings list to keep search filters when user click on back button.
    if (ctaBackToList && (params.has('date') || params.has('type'))) {
        const href = ctaBackToList.getAttribute('href');
        ctaBackToList.setAttribute('href', href + '?' + params.toString());
    }
}
