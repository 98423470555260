const BookingEngine = () => {
    const module = document.querySelector(".booking-engine") as HTMLElement;

    if (!module) return;

    const buttonsWrapper = module?.querySelector(".booking-engine__head") as HTMLElement;
    const buttons = buttonsWrapper?.querySelectorAll(".buttons__item") as NodeListOf<HTMLElement>;
    const tabsWrapper = module?.querySelector(".booking-engine__body .body__tabs") as HTMLElement;
    const tabs = tabsWrapper?.querySelectorAll(".tabs__item") as NodeListOf<HTMLElement>;

    if (!buttonsWrapper || !buttons || !tabsWrapper || !tabs) return;

    buttons.forEach((button) => {
        button.onclick = () => {
            const target = button.dataset.tab;

            if (!target) return;

            updateButton(buttonsWrapper, target);
            updateTab(tabsWrapper, target);
        };
    });
};

const updateButton = (buttonsWrapper: HTMLElement, target: string) => {
    const oldButton = buttonsWrapper?.querySelector(".buttons__item.active") as HTMLElement;
    const newButton = buttonsWrapper?.querySelector(`.buttons__item[data-tab="${target}"]`) as HTMLElement;

    if (oldButton) oldButton.classList.remove("active");

    if (newButton) newButton.classList.add("active");
};

const updateTab = (tabsWrapper: HTMLElement, target: string) => {
    const oldTab = tabsWrapper?.querySelector(".tabs__item.active") as HTMLElement;
    const newTab = tabsWrapper?.querySelector(`.tabs__item[data-tab="${target}"]`) as HTMLElement;

    if (oldTab) oldTab.classList.remove("active");

    if (newTab) newTab.classList.add("active");
};

export default BookingEngine;
