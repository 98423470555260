export default function scrollToTop() {
    const toTopButton = document.querySelector(".scroll-to-top") as HTMLElement;
    if (toTopButton) {
        toTopButton.style.display = 'none';
        window.addEventListener('scroll', () => {
            if (window.scrollY > window.innerHeight) {
                toTopButton.style.display = 'flex';
            } else {
                toTopButton.style.display = 'none';
            }
        });

        toTopButton?.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    }
}
