export default function videoLoaded(element: HTMLVideoElement) {
    if (element) {
        element.addEventListener('loadedmetadata', function() {
            element.play();
        }, false);
        // Fix for firefox.
        if (element.readyState >= 2) {
            element.play();
        }
    }
}
