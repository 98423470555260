export default async function dayflights() {
    const url_ajax = '/' + window.drupalSettings.path.pathPrefix + 'ajax/dayflights/list';
    const url_follow_flight = '/' + window.drupalSettings.path.pathPrefix + 'ajax/account/follow-flight';
    const translations = window.dayflightsTranslations;

    let searchInput = document.querySelector(`#df-flight`) as HTMLInputElement;
    let dateInput = document.querySelector(`#df-when`) as HTMLInputElement;
    let timeInput = document.querySelector(`#df-hour`) as HTMLInputElement;
    let flight_type = document.querySelector(`input[name="df-flight-type"]`) as HTMLInputElement;
    let parser = new DOMParser();
    const loader = '<div class="ajax-progress ajax-progress-fullscreen">&nbsp;</div>';
    let dayflights_block = document.querySelector("#dayflights-block .dayflights-listing__flights") as HTMLElement;

    // Prevent form submit on search button click.
    let searchForm = document.querySelector(`#dayflights-engine`) as HTMLInputElement;
    searchForm.addEventListener('submit', (event) => {event.preventDefault(); submitForm()});

    await submitForm();

    let reloadButton = document.querySelector(`#clear-dayflights-values`) as HTMLInputElement;
    reloadButton.addEventListener('click', () => {location.replace(document.URL)});

    let showMore = document.querySelector(`.dayflights-listing__footer .btn`) as HTMLInputElement;
    showMore.style.display = 'none';
    showMore.addEventListener('click', (event) => {event.preventDefault(); nextPage()});

    function triggerDetails() {
        const triggers = document.querySelectorAll('.see-info');
        triggers.forEach((el:Element) => {
            const trigger = el as HTMLElement;
            trigger.addEventListener('click',function(event: MouseEvent) {
                const elem = event.target as HTMLElement;
                const parent = elem.closest('.info-to-see') as HTMLElement;
                if (elem.classList.contains('open')) {
                    parent.classList.remove('info-is-visible')
                    elem.classList.remove('open');
                } else {
                    parent.classList.add('info-is-visible')
                    elem.classList.add('open');
                }

                event.stopPropagation();
                event.preventDefault();
            }, false);
        });
    }

    async function submitForm() {
        let load = parser.parseFromString(loader, 'text/html');
        let loading = load.querySelector('.ajax-progress') as HTMLElement;
        let isLoading = dayflights_block?.querySelector('.ajax-progress') as HTMLElement;
        if (!isLoading) {
            dayflights_block.append(loading);
        }
        // Call ajax.
        let data = {
            flight_type: flight_type.value,
            search: searchInput.value ? searchInput.getAttribute("data-search-value") : '',
            search_type: searchInput.value ? searchInput.getAttribute("data-search-type") : '',
            start_date: dateInput.getAttribute("data-search-value"),
            start_time: timeInput.getAttribute("data-search-value"),
        };

        fetch(url_ajax, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.text();
        }).then(function (result) {
            processFlights(JSON.parse(result));
            lastUpdateDate(JSON.parse(result));
            nextPage();
            const loader = document.querySelector('body>.ajax-progress') as HTMLElement;
            if (loader) {
                loader.remove();
            }
        })
    }

    async function processFlights(response: any) {

        let data = response.data;
        dayflights_block.innerHTML = '';
        let counter_pager = 0;

        if (data !== undefined) {
            for (let day of Object.keys(data)) {
                let flightsByDay = data[day];

                for (let hour of Object.keys(flightsByDay)) {
                    let flightsByHour = flightsByDay[hour]['flights'];

                    Object.values(flightsByHour).forEach((flight: any) => {
                        let hour_section = document.querySelector(`[data-dayflights-section="${day}_${hour}"]`);
                        if (!hour_section) {
                            let hour_section_p = document.createElement('p');
                            hour_section_p.classList.add('dayflights__time-slot', 'time-slot','hidden');
                            hour_section_p.setAttribute('data-dayflights-section', `${day}_${hour}`);
                            hour_section_p.innerHTML = flightsByDay[hour]['time-range'];
                            dayflights_block.append(hour_section_p);
                        }

                        let dayflight_markup = document.createElement('div');
                        dayflight_markup.classList.add('card-flight', 'info-to-see', 'hidden');
                        dayflight_markup.innerHTML = flight;
                        dayflights_block.append(dayflight_markup);
                        counter_pager++;
                    });
                }
            }

            triggerDetails();
            triggerFollowingButtons();
        } else {
            dayflights_block.innerHTML = `<div class="dayflights-listing__empty">${translations.no_results_dayflights}</div>`;
        }
    }

    async function nextPage() {
        let hiddenItems = document.querySelectorAll(`.dayflights-listing__flights .hidden`);
        let counter_flights = 0;

        hiddenItems.forEach((item: any) => {
            if (counter_flights < 15 && item.tagName == 'DIV') {
                item.classList.remove('hidden');
                counter_flights++;
            }

            // < 14 because we won't finish with <p> element.
            if (counter_flights < 14 && item.tagName == 'P') {
                item.classList.remove('hidden');
            }
        });

        let hiddenItemsRemaining = document.querySelectorAll(`.dayflights-listing__flights .hidden`);
        if (hiddenItemsRemaining.length == 0) {
            showMore.style.display = 'none';
        }
        else {
            showMore.style.display = '';
        }
    }

    function triggerFollowingButtons() {
        const followButtons = document.querySelectorAll(`.card-flight__follow:not(.open-login)`);
        followButtons.forEach((el: Element) => {
            const trigger = el as HTMLElement;
            trigger.addEventListener('click', () => {
                followFlight(el);
            },false);
        });
    }

    async function followFlight(elem: any) {

        // Call ajax.
        let data = {
            dayflight_id: elem.getAttribute("data-dayflight-id"),
        };

        fetch(url_follow_flight, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(function() {
                location.replace(document.URL);
            }).catch(function(error) {
                console.log(error);
            });
    }

    async function lastUpdateDate(response: any) {
        let last_update_date = response.last_update;

        let emTag = document.querySelector(`.dayflights-listing__update-infos em`) as HTMLElement;
        emTag.innerText = last_update_date
    }
}
